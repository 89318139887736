// Global error reporting defined here in order to catch errors/crashes of angular on load
window.onerror = (message, source, lineno, colno, error) => {
    _sendErrorToFirebase(error || message);
};

window.addEventListener('unhandledrejection', event => {
    _sendErrorToFirebase(event?.reason);
});

function _sendErrorToFirebase(error) {
    try {
        if (window?.FirebasePlugin) {
            console.debug('Sending error to firebase:', error);

            let errorMessage;
            if (error instanceof Error) {
                errorMessage = error.stack;
            } else if (_.isString(error)) {
                errorMessage = error;
            } else {
                console.warn('Unexpected error type:', typeof error);
                try {
                    errorMessage = JSON.stringify(error);
                } catch (jsonError) {
                    errorMessage = jsonError.stack;
                }
            }

            window.FirebasePlugin.logError(
                errorMessage,
                undefined,
                function () {
                    console.debug('Sent error to firebase');
                },
                function (error) {
                    console.error('Failed to send error to firebase', error);
                }
            );
        } else {
            if (window.cordova) {
                console.error('FirebasePlugin not available');
            } else {
                // no-op, we don't have firebase in the browser
            }
        }
    } catch (err) {
        console.error('Fatal error while submitting error to Firebase:', err);
    }
}

angular
    .module('family_cockpit', ['Controllers.all', 'ngRoute', 'Directives.all', 'ui.select'])
    .config(function ($mdGestureProvider) {
        $mdGestureProvider.skipClickHijack();
    });
;
 
angular.module('family_cockpit').config(function () {
    if (!Array.prototype.findIndex) {
        Array.prototype.findIndex = function (predicate) {
            'use strict';
            if (this === null) {
                throw new TypeError('Array.prototype.findIndex called on null or undefined');
            }
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }
            var list = Object(this);
            var length = list.length >>> 0;
            var thisArg = arguments[1];
            var value;

            for (var i = 0; i < length; i++) {
                value = list[i];
                if (predicate.call(thisArg, value, i, list)) {
                    return i;
                }
            }
            return -1;
        };
    }

    if (!Array.prototype.find) {
        Array.prototype.find = function (predicate) {
            'use strict';
            if (this === null) {
                throw new TypeError('Array.prototype.find called on null or undefined');
            }
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }
            var list = Object(this);
            var length = list.length >>> 0;
            var thisArg = arguments[1];
            var value;

            for (var i = 0; i < length; i++) {
                value = list[i];
                if (predicate.call(thisArg, value, i, list)) {
                    return value;
                }
            }
            return undefined;
        };
    }

    if (!Array.prototype.filter) {
        Array.prototype.filter = function (fun /*, thisArg*/) {
            'use strict';

            if (this === void 0 || this === null) {
                throw new TypeError();
            }

            var t = Object(this);
            var len = t.length >>> 0;
            if (typeof fun !== 'function') {
                throw new TypeError();
            }

            var res = [];
            var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
            for (var i = 0; i < len; i++) {
                if (i in t) {
                    var val = t[i];
                    // NOTE: Technically this should Object.defineProperty at
                    //       the next index, as push can be affected by
                    //       properties on Object.prototype and Array.prototype.
                    //       But that method's new, and collisions should be
                    //       rare, so use the more-compatible alternative.
                    if (fun.call(thisArg, val, i, t)) {
                        res.push(val);
                    }
                }
            }

            return res;
        };
    }
});
;
 
angular.module('family_cockpit').factory('$exceptionHandler', [
    '$log',
    'errorReportingService',
    function ($log, errorReportingService) {
        return function myExceptionHandler(exception, cause) {
            $log.error(exception, cause);

            errorReportingService.report(exception);
        };
    },
]);
;
 
/*******************************************************************************
 * Handling für cordova-plugin-firebase
 *******************************************************************************/
angular.module('family_cockpit').run([
    '$rootScope',
    '$timeout',
    '$location',
    function ($rootScope, $timeout, $location) {
        document.addEventListener('deviceready', onDeviceReady, false);

        function firebaseRouting(url) {
            $timeout(function () {
                $location.path(url);
            }, 1000);
        }

        function onDeviceReady() {
            window.FirebasePlugin.setBadgeNumber(0);

            /****************************************************************************
             * Firebase (Push Notification) Initialisierung
             * iOS:
             *      iOS Permission für Push abfragen
             ****************************************************************************/
            window.FirebasePlugin.hasPermission(
                hasPermission => {
                    console.debug('FirebasePlugin.hasPermission:', hasPermission ? 'granted' : 'denied');
                    if (!hasPermission) {
                        window.FirebasePlugin.grantPermission(
                            success => {
                                console.debug('FirebasePlugin.grantPermission:', success);
                            },
                            err => {
                                console.error('FirebasePlugin.grantPermission error:', err);
                            }
                        );
                    }
                },
                error => {
                    console.error('FirebasePlugin.hasPermission error:', error);
                }
            );

            /****************************************************************************
             * Push Notification wird geöffnet
             ****************************************************************************/
            window.FirebasePlugin.onMessageReceived(
                notification => {
                    console.debug('FirebasePlugin.onMessageReceived', notification);

                    window.FirebasePlugin.setBadgeNumber(0);

                    if (!notification) {
                        return console.warn('Received unexpected notification:', notification);
                    }

                    if (notification.tap) {
                        const notificationId = notification._id;
                        // Note: top is either 'foreground' or 'background'.
                        //  We define the same behaviour for each case.
                        console.debug('User has interacted with notification, handling notification key.');
                        if (notification.key === 'event_reminder') {
                            if (!notificationId) {
                                return console.warn('Missing event id');
                            }
                            console.debug('Routing to event with id:', notificationId);
                            firebaseRouting(`/kalender/${notificationId}`);
                        } else if (notification.key === 'list_reminder') {
                            if (!notificationId) {
                                return console.warn('Missing list id');
                            }
                            console.debug('Routing to list with id:', notificationId);
                            firebaseRouting(`/listen/Aufgaben/${notificationId}`);
                        } else {
                            console.warn('Unexpected notification key:', notification.key);
                        }
                    } else {
                        console.debug(
                            'Notification was delivered by the system, but no user interaction has been made.'
                        );
                    }
                },
                error => {
                    console.error('FirebasePlugin.onMessageReceived error:', error);
                }
            );
        }
    },
]);
;
 
angular.module('family_cockpit').config([
    '$httpProvider',
    function ($httpProvider) {
        // Authorization
        $httpProvider.interceptors.push(function () {
            return {
                request: function (config) {
                    config.headers.Authorization = `Bearer ${localStorage.getItem('fc-jwt-token')}`;
                    return config;
                },
            };
        });

        // App Version
        $httpProvider.interceptors.push(function () {
            return {
                request: function (config) {
                    // TODO: inject/detect app version
                    config.headers['App-Version'] = `5.1.0`;
                    return config;
                },
            };
        });

        // Detect and mark offline network errors
        $httpProvider.interceptors.push(function ($q) {
            return {
                responseError: function (rejection) {
                    // Detect aborted request
                    // Reference: https://code.angularjs.org/1.5.11/docs/api/ng/service/$http#:~:text=%2D1%20usually%20means%20the%20request%20was%20aborted
                    if (rejection?.status === -1) {
                        // Are we currently offline?
                        if (!navigator.onLine) {
                            // Mark error as offline error
                            rejection.isOfflineError = true;
                        }
                    }

                    return $q.reject(rejection);
                },
            };
        });
    },
]);
;
 
angular.module('family_cockpit').config([
    'usSpinnerConfigProvider',
    function (usSpinnerConfigProvider) {
        usSpinnerConfigProvider.setDefaults({ color: '#00c2e2' });
    },
]);
;
 
// DateConstructor.js
angular.module('DateConstructor', []).factory('DateConstructor', [
    '$rootScope',
    function ($rootScope) {
        function selectedDate() {
            this.moment = moment().locale('de');
            this.timestamp = this.moment.format();
            this.utcMonth = this.moment.month();
            this.month = this.moment.month() + 1;
            this.year = this.moment.year();
            this.dayKey = this.moment.clone().format('YYYY-MM-DD');
            this.startOfDay = this.moment.clone().startOf('day').format();
            this.endOfDay = this.moment.clone().endOf('day').format();
            this.firstDayOfWeek = this.moment.clone().startOf('week').format();
            this.lastDayOfWeek = this.moment.clone().endOf('week').format();
            this.firstDayOfMonth = this.moment.clone().startOf('month').format();
            this.lastDayOfMonth = this.moment.clone().endOf('month').format();
            this.weekRangeText = generateWeekRangeText(this.moment);
        }

        function generateWeekRangeText(moment) {
            var now = moment.clone().locale('de');
            var vonDay = now.startOf('week').format('DD');
            var vonMonth = now.startOf('week').format('MMMM');
            var bisDay = now.endOf('week').format('DD');
            var bisMonth = now.endOf('week').format('MMMM');
            var year = now.format('YYYY');

            if (vonMonth != bisMonth) {
                return vonDay + '. ' + vonMonth + ' - ' + bisDay + '. ' + bisMonth + ' ' + year;
            } else {
                return vonDay + '. - ' + bisDay + '. ' + bisMonth + ' ' + year;
            }
        }

        selectedDate.prototype.shiftTime = shiftTime;
        selectedDate.prototype.setTime = setTime;

        /***************************************************************
         * Kalender auf aktuellen Monat (Default) einstellen
         ***************************************************************/
        function shiftTime(number, unit) {
            var oldMonth = angular.copy(this.month);

            if (number && number > 0) {
                switch (unit) {
                    case 'month':
                        this.moment = this.moment.add(Math.abs(number), 'M');
                        break;
                    case 'week':
                        this.moment = this.moment.add(Math.abs(number), 'w');
                        break;
                    case 'day':
                        this.moment = this.moment.add(Math.abs(number), 'd');
                        break;
                }
            } else if (number && number < 0) {
                switch (unit) {
                    case 'month':
                        this.moment = this.moment.subtract(Math.abs(number), 'M');
                        break;
                    case 'week':
                        this.moment = this.moment.subtract(Math.abs(number), 'w');
                        break;
                    case 'day':
                        this.moment = this.moment.subtract(Math.abs(number), 'd');
                        break;
                }
            }

            this.timestamp = this.moment.format();
            this.utcMonth = this.moment.month();
            this.month = this.moment.month() + 1;
            this.year = this.moment.year();
            this.dayKey = this.moment.clone().format('YYYY-MM-DD');
            this.startOfDay = this.moment.clone().startOf('day').format();
            this.endOfDay = this.moment.clone().endOf('day').format();
            this.firstDayOfWeek = this.moment.clone().startOf('week').format();
            this.lastDayOfWeek = this.moment.clone().endOf('week').format();
            this.firstDayOfMonth = this.moment.clone().startOf('month').format();
            this.lastDayOfMonth = this.moment.clone().endOf('month').format();
            this.weekRangeText = generateWeekRangeText(this.moment);
            $rootScope.$broadcast('date-changed', { oldMonth: oldMonth, newMonth: this.month });
        }

        function setTime(timestamp) {
            var oldMonth = angular.copy(this.month);

            this.moment = moment(timestamp).locale('de');
            this.timestamp = this.moment.format();
            this.utcMonth = this.moment.month();
            this.month = this.moment.month() + 1;
            this.year = this.moment.year();
            this.dayKey = this.moment.clone().format('YYYY-MM-DD');
            this.startOfDay = this.moment.clone().startOf('day').format();
            this.endOfDay = this.moment.clone().endOf('day').format();
            this.firstDayOfWeek = this.moment.clone().startOf('week').format();
            this.lastDayOfWeek = this.moment.clone().endOf('week').format();
            this.firstDayOfMonth = this.moment.clone().startOf('month').format();
            this.lastDayOfMonth = this.moment.clone().endOf('month').format();
            this.weekRangeText = generateWeekRangeText(this.moment);
            $rootScope.$broadcast('date-changed', { oldMonth: oldMonth, newMonth: this.month });
        }

        return selectedDate;
    },
]);
;
 
// Controllers.All.js --------------------------------------------------------------------------------
// erstellt am  16.03.2016 von Immanuel Boss----------------------------------------------------------
angular.module('Controllers.all', [
    'MainCtrl',
    'cfListCtrl',
    'cfListDetailCtrl',
    'cfNoteCtrl',
    'cfFamilyCtrl',
    'cfEventsCtrl',
    'cfWishlistCtrl',
    'cfWishlistDetailCtrl',
    'cfAddressBookCtrl',
    'cfProfileCtrl',
    'cfFamilyProfileCtrl',
    'cfNewWishlistCtrl',
    'cfCreateAdressCtrl',
    'cfFaqCtrl',
    'cfEmergencyNrCtrl',
    'cfNewDistributorCtrl',
    'cfHomeCtrl',
    'cfMobileLoginCtrl',
    'cfRegistrationCtrl',
    'cfChecklistCtrl',
    'cfPasswordCtrl',
    'changePasswordSubmitCtrl',
    'cfCalendarCtrl',
    'cfInvitationCtrl',
    'cfSubscriptionCtrl',
    'cfPurchaseCtrl',
    'cfConfirmInvitationCtrl',
    'cfConfirmPasswordResetCtrl',
    'cfDiaryWelcomeCtrl',
    'cfNursingDiaryViewCtrl',
    'cfNursingDiaryEditorCtrl',
    'cfNursingDiaryEntryCtrl',
    'cfNursingDiaryDocumentationCtrl',
    'cfShareCtrl',
    //MODALS
    'cfEventModalCtrl',
    'cfModalCtrl',
    'cfCalendarModalCtrl',
    'cfDeleteConfirmationCtrl',
]);
;
 
angular
    .module('cfAddressBookCtrl', ['cfAddressGroupService', 'cfAddressService', 'NotificationService'])
    .controller('cfAddressBookCtrl', [
        '$scope',
        '$routeParams',
        '$window',
        '$location',
        '$uibModal',
        'cfAddressGroupService',
        'cfAddressService',
        'usSpinnerService',
        'NotificationService',
        function (
            $scope,
            $routeParams,
            $window,
            $location,
            $uibModal,
            cfAddressGroupService,
            cfAddressService,
            usSpinnerService,
            NotificationService
        ) {
            var familyId = $scope.main.user.family;
            $scope.processedAddressGroup = {};
            /** Kontakt detail **/
            $scope.processedAddress = false;
            $scope.editAddress = false;
            /** Suche nach Kontakten **/
            $scope.search_is_active = false;
            /** Adressgruppen können bearbeitet werden **/
            $scope.settingsAreActive = false;
            /** Geladene Gruppen **/
            $scope.processedAddressGroup = {};
            /** Diese Adresse wird gerade bearbeitet **/
            $scope.processedAddress = false;
            $scope.address = true;
            $scope.disableButton = false;

            /**Gruppen können bearbeitet werden **/
            $scope.switchToSettings = function () {
                $scope.settingsAreActive = !$scope.settingsAreActive;
            };

            /** Formular zum Address Bearbeiten wird eingeblendet **/
            $scope.cfEditAddress = function (address) {
                if (!address) address = { owner: [$scope.main.user._id], family: familyId };
                delete $scope.addressImageDataUrl;
                $scope.processedAddress = address;
                $scope.contactData = false;
                $scope.editAddress = true;
                $scope.address = false;
                if ($scope.processedAddress && $scope.processedAddress.birthday)
                    $scope.processedAddress.birthday = new Date($scope.processedAddress.birthday);
            };
            $scope.goBack = function (address) {
                $scope.processedAddress = false;
                if (address && address._id) {
                    $scope.contactData = address;
                } else {
                    $scope.contactData = false;
                    $scope.processedAddress = false;
                    $scope.address = true;
                }
            };

            $scope.getContactDetail = function (contact) {
                $scope.scrollToTop();
                $scope.address = false;
                $scope.contactData = contact;
                if ($scope.contactData && $scope.contactData.birthday)
                    $scope.contactData.birthday = new Date($scope.contactData.birthday);
                $scope.processedAddress = false;
                $scope.testLink =
                    ' https://www.google.com/maps/search/' +
                    '+' +
                    $scope.contactData.address +
                    '+' +
                    $scope.contactData.zip +
                    '+' +
                    $scope.contactData.city;
                var windowWidth = $window.innerWidth;
                //ToDo windowWidth in directive packen
                if (windowWidth < 1000) $scope.toggleAdressbook = false;
                //var options = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
                $scope.birthday = $scope.contactData.birthday
                    ? new Date($scope.contactData.birthday).toLocaleDateString()
                    : '';
            };
            /***************************************************************
             * Erstellt Kontakt und fügt diese einer Gruppe hinzu
             ***************************************************************/
            $scope.cfCreateOrUpdateAddress = function (processedAddress, addressImage) {
                usSpinnerService.spin('famcospinner');
                $scope.disableButton = true;
                if (processedAddress._id) {
                    delete processedAddress.$$hashKey;
                    cfAddressService.updateAddress(processedAddress, addressImage).then(
                        function (data) {
                            $scope.disableButton = false;
                            $scope.cfGetAddressGroup();
                            $scope.getContactDetail(data);
                            usSpinnerService.stop('famcospinner');
                            // location.reload();
                        },
                        function () {
                            usSpinnerService.stop('famcospinner');
                            $scope.disableButton = false;
                        }
                    );
                } else {
                    cfAddressService.createAddress(processedAddress, addressImage).then(
                        function (data) {
                            $scope.disableButton = false;
                            $scope.cfGetAddressGroup();
                            $scope.getContactDetail(data);
                            usSpinnerService.stop('famcospinner');
                            // location.reload();
                        },
                        function () {
                            NotificationService.displayNotification('error', 'Fehler beim erstellen des Kontaktes!');
                            $scope.disableButton = false;
                            usSpinnerService.stop('famcospinner');
                        }
                    );
                }
            };

            /***************************************************************
             * Adressgruppe löschen
             ***************************************************************/
            $scope.deleteAddressGroup = function (groupId) {
                cfAddressGroupService.deleteAddressGroup(groupId).then(
                    function () {
                        $scope.cfGetAddressGroup();
                        $scope.settingsAreActive = true;
                    },
                    function (err) {
                        NotificationService.displayNotification('error', err);
                    }
                );
            };

            $scope.cfSecureDeleteAddressGroup = function (groupId) {
                $scope.deleteConfirmation({
                    resolve: {
                        title: 'Kontaktgruppe löschen',
                        body: 'Möchtest du diese Gruppe mit allen Kontakten endgültig aus dem Adressbuch löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
                    },
                    onSuccess: () => {
                        $scope.deleteAddressGroup(groupId);
                        NotificationService.displayNotification('success', 'Gruppe wurde erfolgreich gelöscht!');
                    },
                });
            };

            /***************************************************************
             * Erstellt eine neu Gruppe Für das Adressbuch
             ***************************************************************/
            $scope.cfCreateAddressGroup = function (processedAddressGroup) {
                if (processedAddressGroup) {
                    cfAddressGroupService.createAddressGroup(processedAddressGroup).then(
                        function () {
                            $scope.settingsAreActive = false;
                            $scope.cfGetAddressGroup();
                        },
                        function (data) {
                            NotificationService.displayNotification('error', data);
                        }
                    );
                }
            };

            /***************************************************************
             * Aktualisiert die Adressgruppe
             ***************************************************************/
            //TODO: nur für Namensänderung???
            $scope.cfUpdateAddressGroup = function (processedAddressGroup) {
                cfAddressGroupService.updateAddressGroup(processedAddressGroup).then(
                    function () {
                        $scope.cfGetAddressGroup();
                    },
                    function (data) {
                        NotificationService.displayNotification('error', data);
                    }
                );
            };

            /***************************************************************
             * Lädt alle Gruppen und die darin enthaltenen Adressen
             ***************************************************************/
            $scope.cfGetAddressGroup = function () {
                cfAddressGroupService.getAddressGroup().then(function (data) {
                    $scope.loadedAddressGroupList = data;
                    $scope.imgageIdent = '?' + new Date().getTime();
                });
            };
            $scope.cfGetAddressGroup();
            $scope.currentFamily = angular.copy($scope.loadedFamilie);

            /***************************************************************
             * Lädt alle Gruppen und die darin enthaltenen Adressen
             ***************************************************************/
            $scope.cfGetAddressById = function (addressId) {
                cfAddressService.getAddressById(addressId).then(function (data) {
                    $scope.getContactDetail(data);
                    $scope.cfEditAddress(data);
                });
            };
            if ($routeParams.id) {
                $scope.cfGetAddressById($routeParams.id);
            }

            /***************************************************************
             * Kontakt löschen
             ***************************************************************/
            $scope.cfDeleteAddress = function (addressId) {
                cfAddressService.deleteAddress(addressId).then(
                    function () {
                        $scope.processedAddress = false;
                        $scope.settingsAreActive = false;
                        $scope.cfGetAddressGroup();
                        location.reload();
                    },
                    function (err) {
                        NotificationService.displayNotification('error', err);
                    }
                );
            };

            $scope.cfSecureDeleteAddress = function (addressId) {
                $scope.deleteConfirmation({
                    resolve: {
                        title: 'Kontakt löschen',
                        body: 'Möchtest du diesen Kontakt endgültig aus dem Adressbuch löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
                    },
                    onSuccess: () => {
                        $scope.cfDeleteAddress(addressId);
                        NotificationService.displayNotification('success', 'Kontakt wurde erfolgreich gelöscht!');
                    },
                });
            };

            $scope.editContact = function (contactData) {
                $scope.processedAddress = contactData;
                $scope.processedAddress.birthday = new Date($scope.processedAddress.birthday);
            };

            /***************************************************************
             * Suchfunktion für Kontakte
             ***************************************************************/
            $scope.searchAddress = function (processedString) {
                if ($scope.loadedAddressGroupList)
                    $scope.loadedAddressGroupList.forEach(function (group) {
                        if (group.address) {
                            if (processedString.length > 1) {
                                group.open = true;
                            }
                            group.address.forEach(function (address) {
                                //Durchsucht den Kompletten Namen case-insensitive und setzt sichtbarkeit
                                if (address.name)
                                    address.hide = address.name.search(new RegExp(processedString, 'i')) <= -1;
                            });
                        }
                    });
            };

            $scope.toggleGroup = function (newGroup) {
                $scope.toggleGroupValue = !(newGroup && newGroup.length > 0);
            };

            /***************************************************************
             * OPTIONS FÜR DATEPICKER
             ***************************************************************/
            $scope.addressdate = {
                opened: false,
            };
            $scope.format = 'dd. MMMM yyyy';
            $scope.dateOptions = {
                formatYear: 'yyyy',
                startingDay: 1,
                showWeeks: false,
            };
            $scope.open1 = function () {
                $scope.addressdate.opened = true;
            };

            $scope.setImage = image => {
                if (FileReader && image) {
                    const fr = new FileReader();
                    fr.onload = () => {
                        $scope.addressImageDataUrl = fr.result;
                        $scope.$digest();
                    };
                    fr.readAsDataURL(image);
                } else {
                    delete $scope.addressImageDataUrl;
                }
            };
        },
    ]);
;
 
angular
    .module('cfCalendarCtrl', ['cfCalendarService', 'cfUserService', 'NotificationService'])
    .controller('cfCalendarCtrl', [
        '$scope',
        '$filter',
        '$location',
        '$uibModal',
        '$routeParams',
        'user',
        'cfCalendarService',
        'cfUserService',
        'NotificationService',
        function (
            $scope,
            $filter,
            $location,
            $uibModal,
            $routeParams,
            user,
            cfCalendarService,
            cfUserService,
            NotificationService
        ) {
            $scope.calendarImportView = false;
            $scope.calendarDetailsView = false;
            $scope.showDatepicker = false;
            $scope.isPhone = false;

            $scope.goBack = function () {
                $scope.calendarImportView = false;
                $scope.calendarDetailsView = false;
            };

            $scope.dateOptions = {
                formatYear: 'yyyy',
                startingDay: 1,
                initDate: Date.now(),
            };
            $scope.format = 'EEEE, dd. MMMM yyyy';

            /***************************************************************
             * Öffnet die Kalendar Import Ansicht
             ***************************************************************/
            $scope.openCalendarImportView = function () {
                $scope.calendarImportView = true;
            };

            /***************************************************************
             * Öffnet die Detailansicht eines augewählten Kalenders
             ***************************************************************/
            $scope.openCalendarDetailsView = function (calendar) {
                $scope.calendarDetailsView = true;
                $scope.processedCalendar = calendar;
            };

            /***************************************************************
             * Holt sich alle Importierten Kalender
             ***************************************************************/
            $scope.getImportedCalendar = function () {
                cfCalendarService.getImportedCalendar().then(function (data) {
                    $scope.importedCalendar = data;
                });
            };
            $scope.getImportedCalendar();

            /***************************************************************
             * Aktualisiert die Daten von einem Kalender
             ***************************************************************/
            $scope.updateImportedCalendar = function (calendar) {
                cfCalendarService.updateImportedCalendar(calendar).then(function (data) {
                    $scope.getImportedCalendar();
                    $scope.calendarDetailsView = false;
                    NotificationService.displayNotification(data.success ? 'success' : 'error', data.message);
                });
            };

            /***************************************************************
             * Löscht einen Importierten Kalender
             ***************************************************************/
            $scope.deleteImportedCalendar = function (calendar) {
                cfCalendarService.deleteImportedCalendar(calendar).then(function (data) {
                    $scope.getImportedCalendar();
                    $scope.calendarDetailsView = false;
                    NotificationService.displayNotification(data.success ? 'success' : 'error', data.message);
                });
            };

            /***************************************************************
             * Importiert die geladenen Kalendar/ speichert sie in die DB
             ***************************************************************/
            $scope.importCalendar = function (calendar, calendarFile) {
                var user = cfUserService.getCurrentUser();
                calendar.type = calendar.type === 'url' ? 'Import URL' : 'Import Datei';
                cfCalendarService.importCalendar(calendar, user, calendarFile).then(function (data) {
                    $scope.getImportedCalendar();
                    $scope.calendarDetailsView = false;
                    $scope.calendarImportView = false;
                    NotificationService.displayNotification(data.success ? 'success' : 'error', data.message);
                });
            };

            /***************************************************************
             * deaktiviert/aktiviert den Kalender
             ***************************************************************/
            $scope.activateCalendar = function (calendar) {
                cfCalendarService.activateCalendar(calendar);
            };

            $scope.checkDuplicates = function (calendar) {
                cfCalendarService.checkDuplicates(calendar).then(function (result) {
                    if (result === null) $scope.openImportDateModal(calendar);
                    else $scope.openDuplicateWarningModal(calendar);
                });
            };

            $scope.openImportInfoModal = function (calendar, type) {
                var modalInstance = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'html/modals/calendar/calendarInfoModal.html',
                    controller: 'cfCalendarModalCtrl',
                    size: 'm',
                    controllerAs: '$ctrl',
                    resolve: {
                        calendar: function () {
                            return calendar;
                        },
                        type: function () {
                            return type;
                        },
                    },
                });
                modalInstance.result.then(function (calendar) {
                    if (calendar.type) $scope.openIcalImportModal(calendar);
                    else if (window.cordova) {
                        calendar.title = calendar.name;
                        $scope.checkDuplicates(calendar);
                    } else $scope.openImportDateModal(calendar);
                });
            };

            $scope.openImportDateModal = function (calendar, type) {
                var modalInstance = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'html/modals/calendar/calendarDateModal.html',
                    controller: 'cfCalendarModalCtrl',
                    size: 'm',
                    resolve: {
                        calendar: function () {
                            return calendar;
                        },
                        type: function () {
                            return type;
                        },
                    },
                });

                modalInstance.result.then(function (calendar, type) {
                    setTimeout(function () {
                        $scope.openImportSettingstModal(calendar, type);
                    }, 250);
                });
            };
            $scope.openImportSettingstModal = function (calendar, type) {
                var modalInstance = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'html/modals/calendar/calendarSettingsModal.html',
                    controller: 'cfCalendarModalCtrl',
                    size: 'm',
                    resolve: {
                        calendar: function () {
                            return calendar;
                        },
                        type: function () {
                            return type;
                        },
                    },
                });

                modalInstance.result.then(function (calendar) {
                    if (window.cordova && calendar.type !== 'url' && calendar.type !== 'file') {
                        calendar.type =
                            window.cordova.platformId.toLowerCase() === 'android'
                                ? 'Android Kalender'
                                : 'iPhone Kalender';
                        $scope.importPhoneCalendar(calendar);
                    } else $scope.importCalendar(calendar);
                });
            };

            $scope.openIcalImportModal = function (calendar, type) {
                var modalInstance = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'html/modals/calendar/calendarIcalModal.html',
                    controller: 'cfCalendarModalCtrl',
                    size: 'm',
                    resolve: {
                        calendar: function () {
                            return calendar;
                        },
                        type: function () {
                            return type;
                        },
                    },
                });
                modalInstance.result.then(function (calendar) {
                    calendar.title = 'Importierter Kalender';
                    $scope.checkDuplicates(calendar);
                });
            };

            $scope.openDuplicateWarningModal = function (calendar, type) {
                var modalInstance = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'html/modals/calendar/calendarDuplicateWarning.html',
                    controller: 'cfCalendarModalCtrl',
                    size: 'm',
                    resolve: {
                        calendar: function () {
                            return calendar;
                        },
                        type: function () {
                            return type;
                        },
                    },
                });
                modalInstance.result.then(
                    function (calendar) {
                        $scope.openImportDateModal(calendar);
                    },
                    function () {}
                );
            };

            /***************************************************************
             * Sicherheitsabfrage, ob ein Kalender gelöscht werden soll
             ***************************************************************/
            $scope.cfSecureDeleteCalendar = function (calendar) {
                $scope.deleteConfirmation({
                    resolve: {
                        title: 'Kalender löschen',
                        body: 'Möchtest du diesen Kalender löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
                    },
                    onSuccess: () => {
                        $scope.deleteImportedCalendar(calendar);
                    },
                });
            };

            /***************************************************************
             * Holt sich alle Kalender vom Handy
             ***************************************************************/
            $scope.getPhoneCalendar = function () {
                window.plugins.calendar.listCalendars(function (data) {
                    $scope.phoneCalendar = data;
                });
            };

            if (window.cordova) {
                $scope.getPhoneCalendar();
                $scope.isPhone = true;

                if (window.cordova.platformId)
                    if (window.cordova.platformId === 'android') {
                        $scope.device = 'Android';
                        $scope.platform = window.cordova.platformId;
                        $scope.platformName = 'Android Phone';
                    } else {
                        $scope.device = $scope.platformName = 'iPhone';
                        $scope.platform = window.cordova.platformId;
                    }
            }
            /***************************************************************
             * Importiert den Handy Kalender mit Terminen
             ***************************************************************/
            $scope.importPhoneCalendar = function (calendar) {
                var user = cfUserService.getCurrentUser();
                if ($scope.platform.toLowerCase() === 'ios') {
                    window.plugins.calendar.findAllEventsInNamedCalendar(calendar.name, function (events) {
                        cfCalendarService.importIosCalendar(calendar, events, user).then(function () {
                            $scope.getImportedCalendar();
                            $scope.calendarDetailsView = false;
                            $scope.calendarImportView = false;
                            NotificationService.displayNotification('success', 'calendar_import');
                        });
                    });
                } else {
                    var startDate, endDate;
                    if (calendar.timespan === 'future') {
                        /***************************************************************
                         * Holt sich nur vom nächsten halben Jahr
                         * da sonst die Anfrage zu groß werden könnte
                         ***************************************************************/
                        startDate = new Date(Date.now());
                        endDate = new Date(Date.now() + 15768000000);
                    } else {
                        startDate = new Date(calendar.start_date);
                        endDate = new Date(calendar.end_date);
                    }

                    var calOptions = window.plugins.calendar.getCalendarOptions();

                    calOptions.calendarName = calendar.name;
                    calOptions.calendarId = calendar.id;

                    window.plugins.calendar.findEventWithOptions(
                        null,
                        null,
                        null,
                        startDate,
                        endDate,
                        calOptions,
                        function (events) {
                            cfCalendarService.importIosCalendar(calendar, events, user).then(function () {
                                $scope.getImportedCalendar();
                                $scope.calendarDetailsView = false;
                                $scope.calendarImportView = false;
                                NotificationService.displayNotification('success', 'calendar_import');
                            });
                        },
                        function (error) {
                            console.error(error);
                        }
                    );
                }
            };
        },
    ]);
;
 
angular.module('cfCalendarModalCtrl', []).controller('cfCalendarModalCtrl', [
    '$scope',
    '$uibModalInstance',
    'calendar',
    'type',
    function ($scope, $uibModalInstance, calendar, type) {
        $scope.calendar = calendar;
        $scope.type = type;
        $scope.toggle = false;
        $scope.format = 'EEE, dd. MMMM yyyy';
        $scope.formatError = false;
        $scope.isPhone = !!window.cordova;

        $scope.closeModal = function (calendar) {
            if (calendar && calendar.type && (calendar.calendarFile || calendar.calendarUrl)) {
                var checkField = calendar.type === 'file' ? calendar.calendarFile.name : calendar.calendarUrl;
                if (calendar.type === 'file') {
                    checkField = checkField.substring(checkField.length - 4);
                    if (checkField && checkField.toLowerCase() === '.ics')
                        $uibModalInstance.close(calendar, calendar.type);
                    else $scope.formatError = true;
                } else if (checkField && checkField.toLowerCase().includes('.ics'))
                    $uibModalInstance.close(calendar, calendar.type);
                else $scope.formatError = true;
            } else $uibModalInstance.close(calendar, calendar.type);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

        $scope.dateOptions = {
            minDate: new Date(),
        };

        $scope.showDatePicker = {
            start: false,
            end: false,
            intEnd: false,
        };

        $scope.openDatepicker = function (kind) {
            if (kind === 'start') $scope.showDatePicker.start = true;
            if (kind === 'end') $scope.showDatePicker.end = true;
            if (kind === 'intEnd') $scope.showDatePicker.intEnd = true;
            if (kind === 'exception') $scope.showDatePicker.exception = true;
        };

        $scope.validateCalendar = calendar => {
            if (calendar && (calendar.calendarUrl || calendar.calendarFile))
                if (calendar.type === 'file')
                    return calendar.calendarFile && !calendar.calendarFile.name.endsWith('.ics');
                else return !calendar.calendarUrl.includes('.ics');
            else return true;
        };
    },
]);
;
 
angular.module('cfChecklistCtrl', ['cfChecklistService', 'NotificationService']).controller('cfChecklistCtrl', [
    '$scope',
    'cfChecklistService',
    '$location',
    '$routeParams',
    '$filter',
    'NotificationService',
    function ($scope, cfChecklistService, $location, $routeParams, $filter, NotificationService) {
        $scope.processedChecklist = false;
        $scope.viewedChecklist = false;
        $scope.checklists = true;
        $scope.urlListType = $routeParams.listType;

        $scope.openForm = function (checklist) {
            $scope.processedChecklist = {};
            $scope.viewedChecklist = false;
            $scope.checklists = false;
            if (checklist) $scope.processedChecklist = angular.copy(checklist);
        };

        $scope.viewChecklist = function (checklist) {
            $scope.processedChecklist = false;
            $scope.viewedChecklist = checklist;
            $scope.checklists = false;
        };
        $scope.goBack = function (checklist) {
            if (checklist && checklist._id) {
                $scope.viewChecklist(checklist);
            } else {
                $scope.processedChecklist = false;
                $scope.viewedChecklist = false;
                $scope.checklists = true;
            }
        };
        /***************************************************************
         * CRUD: Read Listen
         ***************************************************************/
        $scope.cfGetChecklists = function () {
            cfChecklistService.getChecklists().then(function (data) {
                $scope.loadedChecklists = data;
                $scope.filteredChecklists = $filter('listFilter')(data, $scope.main.familyFilter);
            });
        };

        /***************************************************************
         * CRUD:  Update Task
         ***************************************************************/
        $scope.cfUpdateChecklistTask = function (checklist) {
            cfChecklistService.cfUpdateChecklistTask(checklist).then(
                function (data) {
                    $scope.viewedChecklist = data;
                    $scope.cfGetChecklists();
                },
                function (data) {}
            );
        };

        /***************************************************************
         * CRUD: Update Checkliste
         ***************************************************************/
        $scope.cfCreateUpdateChecklist = function (processedChecklist) {
            cfChecklistService.cfCreateUpdateChecklist(processedChecklist).then(
                function (data) {
                    $scope.processedChecklist = false;
                    $scope.viewedChecklist = false;
                    $scope.checklists = true;
                    $scope.cfGetChecklists();
                },
                function (data) {}
            );
        };

        /***************************************************************
         * CRUD: Delete List
         ***************************************************************/
        $scope.cfRemoveChecklist = function (checklist) {
            cfChecklistService.cfRemoveChecklist(checklist).then(
                function (data) {
                    $scope.processedChecklist = false;
                    $scope.viewedChecklist = false;
                    $scope.checklists = true;
                    $scope.cfGetChecklists();
                },
                function (data) {}
            );
        };

        /***************************************************************
         * load checklist by id
         ***************************************************************/
        $scope.cfGetChecklistById = function (listType) {
            cfChecklistService.getChecklistById(listType).then(function (data) {
                $scope.viewChecklist(data);
            });
        };

        cfChecklistService.getChecklistTemplates().then(function (data) {
            $scope.loadedChecklistTemplates = data;
        });

        $scope.$on('FamilyFilter', function (checklist) {
            $scope.filteredChecklists = $filter('listFilter')(checklist, $scope.main.familyFilter);
        });

        /***************************************************************
         * INITIAL
         ***************************************************************/
        if ($routeParams.id) $scope.cfGetChecklistById($routeParams.id);
        $scope.cfGetChecklists();

        $scope.cfSecureDeleteChecklist = function (checklist) {
            $scope.deleteConfirmation({
                onSuccess: () => {
                    $scope.cfRemoveChecklist(checklist);
                    NotificationService.displayNotification('success', 'Checkliste wurde erfolgreich gelöscht!');
                },
            });
        };
    },
]);
;
 
angular
    .module('cfConfirmInvitationCtrl', ['cfRegistrationService', 'NotificationService'])
    .controller('cfConfirmInvitationCtrl', [
        '$scope',
        '$location',
        '$routeParams',
        'cfRegistrationService',
        'NotificationService',
        function ($scope, $location, $routeParams, cfRegistrationService, NotificationService) {
            const defaultErrorMessage =
                'Unerwarteter Fehler beim Bestätigen der E-Mail. Bitte versuche es nochmal oder wende dich an den Support';
            $scope.confirmInvitationFormData = {
                password: '',
                ageConfirmed: false,
                termsAndPolicyConfirmed: false,
                healthDataConfirmed: false,
            };
            $scope.isLoading = true;

            cfRegistrationService
                .validateInvitationHash($routeParams.invitationHash)
                .catch(response => {
                    $scope.errorMessage = response.data?.message ?? defaultErrorMessage;
                })
                .finally(() => {
                    $scope.isLoading = false;
                });

            $scope.submitConfirmInvitationForm = () => {
                cfRegistrationService
                    .completeInvitation({
                        password: $scope.confirmInvitationFormData.password,
                        invitationHash: $routeParams.invitationHash,
                    })
                    .then(() => {
                        $location.path('/');
                    })
                    .catch(err => {
                        NotificationService.displayNotification('error', err.data?.message ?? defaultErrorMessage);
                    });
            };
        },
    ]);
;
 
angular
    .module('cfConfirmPasswordResetCtrl', ['cfPasswordService', 'NotificationService'])
    .controller('cfConfirmPasswordResetCtrl', [
        '$scope',
        '$location',
        '$routeParams',
        'cfPasswordService',
        'NotificationService',
        function ($scope, $location, $routeParams, cfPasswordService, NotificationService) {
            const defaultErrorMessage =
                'Unerwarteter Fehler beim Bestätigen der Passwortzurücksetzung. Bitte versuche es nochmal oder wende dich an den Support';
            $scope.resetPasswordFormData = {
                password: '',
                passwordConfirmation: '',
            };
            $scope.isLoading = true;

            cfPasswordService
                .validateResetPasswordHash($routeParams.passwordResetHash)
                .catch(response => {
                    $scope.errorMessage = response.data?.message ?? defaultErrorMessage;
                })
                .finally(() => {
                    $scope.isLoading = false;
                });

            $scope.submitResetPasswordForm = () => {
                cfPasswordService
                    .completePasswordReset({
                        password: $scope.resetPasswordFormData.password,
                        passwordResetHash: $routeParams.passwordResetHash,
                    })
                    .then(() => {
                        $location.path('/');
                    })
                    .catch(err => {
                        NotificationService.displayNotification('error', err.data?.message ?? defaultErrorMessage);
                    });
            };
        },
    ]);
;
 
angular.module('cfCreateAdressCtrl', ['cfAddressGroupService', 'cfAddressService']).controller('cfCreateAdressCtrl', [
    '$scope',
    '$location',
    'cfAddressGroupService',
    'cfAddressService',
    function ($scope, $location, cfAddressGroupService, cfAddressService) {
        $scope.newGroup = {};
        $scope.toggleGroupValue = true;

        $scope.processedAddress = {};

        $scope.currentFamily = angular.copy($scope.loadedFamilie);

        /***************************************************************
         * Erstellt Kontakt und fügt diese einer Gruppe hinzu
         ***************************************************************/
        $scope.cfCreateAddress = function (processedAddress, addressImage) {
            if ($scope.newGroup) $scope.cfCreateAddressGroup($scope.newGroup);
            $scope.cfGetAddressGroup();
            cfAddressService.createAddress(processedAddress, addressImage).then(
                function (data) {
                    //$location.path('/adressbuch/' + processedAddress._id)
                    $scope.processedAddress = data;
                    $location.path('/adressbuch');
                },
                function () {
                    console.error('Fehler beim erstellen des Kontaktes!');
                }
            );
        };
        //$scope.cfCreateAddress()

        /***************************************************************
         * Lädt alle Gruppen und die darin enthaltenen Adressen
         ***************************************************************/
        $scope.cfGetAddressGroup = function () {
            cfAddressGroupService.getAddressGroup().then(function (data) {
                $scope.loadedAddressGroupList = data;
            });
        };
        $scope.cfGetAddressGroup();

        /***************************************************************
         * Erstellt eine neu Gruppe Für das Adressbuch
         ***************************************************************/
        $scope.cfCreateAddressGroup = function (processedAddressGroup) {
            if (processedAddressGroup) {
                cfAddressGroupService.createAddressGroup(processedAddressGroup).then(
                    function (data, test) {},
                    function (data) {
                        console.error('Fehler beim erstellen', data);
                    }
                );
            }
        };
    },
]);
;
 
angular.module('cfDeleteConfirmationCtrl', []).controller('cfDeleteConfirmationCtrl', [
    '$scope',
    '$uibModalInstance',
    'resolve',
    function ($scope, $uibModalInstance, resolve) {
        $scope.resolve = resolve;
        $scope.ok = $uibModalInstance.close;
        $scope.cancel = $uibModalInstance.dismiss;
    },
]);
;
 
angular.module('cfDiaryWelcomeCtrl', []).controller('cfDiaryWelcomeCtrl', [
    '$scope',
    '$location',
    function ($scope, $location) {
        $scope.active = 0;

        $scope.slides = [
            {
                text: 'Ihr könnt für jedes Familienmitglied, das ein eigenes Profil im Family Cockpit hat, ein Pflegetagebuch anlegen. Hier erklären wir euch wie es geht.',
                id: 0,
            },
            {
                text: 'Legt zuerst fest, für welchen Zeitraum das Pflegetagebuch eingerichtet werden soll und wählt dann die Kategorien, die dokumentiert werden sollen.',
                id: 1,
            },
            {
                text: 'Was macht Oma gerne? Legt typische Aktivitäten selbst fest. Dann könnt ihr später im Tagebuch festhalten, wie aktiv sie den Tag über war.',
                id: 2,
            },
            {
                text: 'Erstellt dann eine Checkliste für die Medikation. So weiß jeder, wann welches Medikament wie eingekommen werden muss und ob die Einnahme erfolgt ist.',
                id: 3,
            },
            {
                text: 'Nun seid ihr bereit und könnt jeden Tag genau festhalten, wie es Oma geht. Ladet dann noch ein Bild des Tages hoch und teilt so schöne Momente mit der ganzen Familie.',
                id: 4,
            },
            {
                text: 'Jedes Familienmitglied kann Einträge vornehmen und alles sehen, was eingetragen wurde. Die Dokumentation könnt ihr über einen externen Link auch mit Dritten teilen.',
                id: 5,
            },
            {
                text: 'Noch Fragen? Im Bereich Fragen & Antworten findet ihr weitere Informationen zur Nutzung eures Pflegetagebuchs.',
                id: 6,
            },
        ];

        $scope.validateAccessLevel = function () {
            if ($scope.main.user.family.access.level === 'full') {
                $location.path('/pflegetagebuch/editor');
            } else {
                $scope.deleteConfirmation({
                    templateUrl: 'html/modals/secure/nursingDiaryAccessInformationModal.html',
                    size: 'md',
                    resolve: {
                        title: "So geht's",
                        body: 'Um das Pflegetagebuch zu nutzen, benötigen ihr ein Family Cockpit - Abonnement.',
                    },
                    onSuccess: () => $location.path('/family/subscriptions'),
                });
            }
        };
    },
]);
;
 
angular.module('cfEmergencyNrCtrl', ['cfEmergencyNrService']).controller('cfEmergencyNrCtrl', [
    '$scope',
    'cfEmergencyNrService',
    '$location',
    function ($scope, cfEmergencyNrService, $location) {
        /***************************************************************
         * Lädt alle Notfallnummern einer Familie
         ***************************************************************/
        $scope.cfGetEmergencyNr = (function () {
            cfEmergencyNrService.getEmergencyNr().then(function (data) {
                $scope.emergencyNr = data;
            });
        })();
        //$scope.cfGetEmergencyNr();

        $scope.routeToAddress = function (id) {
            $location.path('/adressbuch/' + id);
        };
    },
]);
;
 
angular.module('cfEventModalCtrl', []).controller('cfEventModalCtrl', [
    '$scope',
    '$uibModalInstance',
    'event',
    'dynamicLoc',
    'userPermission',
    function ($scope, $uibModalInstance, event, dynamicLoc, userPermission) {
        $scope.event = event;
        $scope.dynamicLoc = dynamicLoc;
        $scope.userPermission = userPermission;

        $scope.routeToEvent = function (event) {
            $uibModalInstance.close(event);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
    },
]);
;
 
angular
    .module('cfEventsCtrl', ['cfEventsService', 'cfSelectedTimeService', 'NotificationService'])
    .controller('cfEventsCtrl', [
        '$scope',
        '$filter',
        '$location',
        '$uibModal',
        '$routeParams',
        'user',
        'cfEventsService',
        'cfSelectedTimeService',
        'NotificationService',
        function ($scope, $filter, $location, $uibModal, $routeParams, user, cfEventsService, sts, ns) {
            $scope.checkForPrivate = (familyMembers, ownerIds) => {
                if (ownerIds && ownerIds.length) {
                    let returnValue = true;

                    const owners = ownerIds.map(id => familyMembers.find(member => member._id === id));
                    owners.forEach(owner => {
                        if (!owner || (owner && !owner.contactData) || (owner.contactData && !owner.contactData.email))
                            returnValue = false;
                    });

                    if (!returnValue) $scope.processedEvent.private = false;
                    return returnValue;
                }
                // Falls kein owner oder Family
                else if (ownerIds && !ownerIds.length) {
                    $scope.processedEvent.private = false;
                    return false;
                } else return false;
            };

            $scope.processedEvent = false;
            $scope.eventsForDay = [];
            $scope.clickday = '';
            $scope.calendarView = 'month';
            $scope.events = cfEventsService.getEvents();
            $scope.clickDayHeader = true;
            $scope.popup1 = false;
            $scope.toggle.exceptions = false;
            $scope.toggle.exceptionDates = false;
            $scope.toggle.showDatePicker = false;
            $scope.calendarImportView = false;
            $scope.calendarDetailsView = false;
            /** Initialwerte für den Kalender **/
            $scope.materielCalendar = {
                startMonth: sts.selectedDate.utcMonth,
                startYear: sts.selectedDate.year,
            };
            $scope.calendar = {};
            $scope.calendar.selectedDate = new Date(sts.selectedDate.timestamp);
            $scope.firstDayOfWeek = 1;
            $scope.options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', showWeeks: true };

            $scope.setToday = function () {
                sts.selectedDate.setTime(new Date());
            };

            $scope.goBack = function (calendarView) {
                $scope.processedEvent = false;
                $scope.calendarView = calendarView;
                $scope.calendarImportView = false;
            };

            $scope.closeCalendarDetails = function () {
                $scope.calendarDetailsView = false;
            };

            $scope.openEventModal = function (event, day) {
                $scope.day = day;

                var modalInstance = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'html/modals/eventModal.html',
                    controller: 'cfEventModalCtrl',
                    size: 'sm',
                    resolve: {
                        event: function () {
                            return event;
                        },
                        userPermission: function () {
                            return $scope.main.user.permission;
                        },
                        dynamicLoc: function () {
                            return $scope.dynamicLoc;
                        },
                    },
                });

                modalInstance.result.then(
                    function (event) {
                        if (event && event.birthday === true && event.kontaktId)
                            $location.path('adressbuch/' + event.kontaktId);
                        else if (event && event.birthday === true && event.profileId)
                            $location.path('profil/' + event.profileId);
                        else $scope.cfGetEventById(event._id);
                    },
                    function () {}
                );
            };

            var family = user.family;

            /*******************************************************e********
             * Ändert sich der Monat, werden Kalendereinträge neu geladen.
             ***********************************º****************************/
            $scope.prevMonth = function () {
                sts.selectedDate.shiftTime(-1, 'month');
                cfEventsService.loadEventsForPeriod(family, $scope.main.familyFilter, {
                    start: sts.selectedDate.firstDayOfMonth,
                    end: sts.selectedDate.lastDayOfMonth,
                });
            };

            $scope.nextMonth = function () {
                sts.selectedDate.shiftTime(1, 'month');
                cfEventsService.loadEventsForPeriod(family, $scope.main.familyFilter, {
                    start: sts.selectedDate.firstDayOfMonth,
                    end: sts.selectedDate.lastDayOfMonth,
                });
            };

            /*******************************************************e********
             * Ändert sich die Woche, werden Kalendereinträge neu geladen.
             ***********************************º****************************/
            $scope.prevWeek = function () {
                sts.selectedDate.shiftTime(-1, 'week');
                cfEventsService.loadEventsForPeriod(
                    family,
                    $scope.main.familyFilter,
                    {
                        start: sts.selectedDate.firstDayOfWeek,
                        end: sts.selectedDate.lastDayOfWeek,
                    },
                    function (newEvents) {
                        $scope.events = newEvents;
                    }
                );
                $scope.weekRangeText = sts.getSelectedDate().weekRangeText;
            };

            $scope.nextWeek = function () {
                sts.selectedDate.shiftTime(1, 'week');
                cfEventsService.loadEventsForPeriod(
                    family,
                    $scope.main.familyFilter,
                    {
                        start: sts.selectedDate.firstDayOfWeek,
                        end: sts.selectedDate.lastDayOfWeek,
                    },
                    function (newEvents) {
                        $scope.events = newEvents;
                    }
                );
                $scope.weekRangeText = sts.getSelectedDate().weekRangeText;
            };

            /*******************************************************e********
             * Ändert sich der Tag, werden Kalendereinträge neu geladen.
             ***********************************º****************************/
            $scope.prevDay = function () {
                sts.selectedDate.shiftTime(-1, 'day');
            };

            $scope.nextDay = function () {
                sts.selectedDate.shiftTime(1, 'day');
            };

            function initViewRange(firstDateOfMonth) {
                var _date = new Date(firstDateOfMonth);
                const moment = window.moment;
                let month = _date.getMonth();
                let year = _date.getFullYear();
                let start = _date;

                var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                var monthLength = daysInMonth[month];

                // Figure out if is a leap year.
                if (month === 1) {
                    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
                        monthLength = 29;
                    }
                }

                var date = angular.copy(start);
                if (date.getDate() === 1) {
                    while (date.getDay() !== $scope.firstDayOfWeek) {
                        date.setDate(date.getDate() - 1);
                        monthLength++;
                    }
                }

                // Last day of calendar month.
                while (monthLength % 7 !== 0) {
                    monthLength++;
                }

                // Last day of calendar month.
                while (monthLength % 7 !== 0) {
                    monthLength++;
                }

                var momentStart = moment(date).endOf('Day').format();
                date.setDate(date.getDate() + monthLength - 1);
                var end = moment(date).endOf('Day').format();
                return { start: momentStart, end: end };
            }

            /***************************************************************
             * Initial werden einmal Kalendereinträge für aktuellen Monat geladen.
             ***************************************************************/
            $scope.loadEventsForMonth = function () {
                cfEventsService.loadEventsForPeriod(
                    family,
                    $scope.main.familyFilter,
                    initViewRange(sts.getSelectedDate().firstDayOfMonth),
                    function (newEvents) {
                        $scope.clickDay = sts.getSelectedDate().timestamp;
                        // FIXME: eventsForDay is *undefined* if paging quickly between months
                        //  This previously crashed filterEventsForDay quite often in production (Crashlytics).
                        const eventsForDay = newEvents[sts.getSelectedDate().dayKey];
                        $scope.eventsForDay = $filter('filterEventsForDay')(eventsForDay);
                    }
                );
            };

            $scope.loadEventsForMonth();

            /***************************************************************
             *
             ***************************************************************/
            $scope.loadEventsForWeek = function () {
                cfEventsService.loadEventsForPeriod(
                    family,
                    $scope.main.familyFilter,
                    {
                        start: sts.getSelectedDate().firstDayOfWeek,
                        end: sts.getSelectedDate().lastDayOfWeek,
                    },
                    function (newEvents) {
                        $scope.events = newEvents;
                    }
                );
                $scope.weekRangeText = sts.getSelectedDate().weekRangeText;
            };

            /***************************************************************
             *
             ***************************************************************/
            $scope.loadEventsForDay = function () {
                cfEventsService.loadEventsForPeriod(
                    family,
                    $scope.main.familyFilter,
                    {
                        start: sts.getSelectedDate().startOfDay,
                        end: sts.getSelectedDate().endOfDay,
                    },
                    function (newEvents) {
                        $scope.sortMachine = [];
                        $scope.allDayEvents = [];
                        $scope.allDayEvents = newEvents[sts.getSelectedDate().dayKey].filter(function (e) {
                            return e.allday || e.birthday;
                        });
                        var eventsWithTime = newEvents[sts.getSelectedDate().dayKey].filter(function (e) {
                            return !e.allday && !e.birthday;
                        });
                        $scope.sortMachine = cfEventsService.createSortMachine(eventsWithTime);

                        const eventsForDay = newEvents[sts.getSelectedDate().dayKey];
                        $scope.eventsForDay = $filter('filterEventsForDay')(eventsForDay);
                    }
                );
                $scope.clickDay = sts.getSelectedDate().timestamp;
            };

            //Setzt das Datum für die Reminder
            $scope.setReminderDate = function (processedEvent) {
                //Fixt den Bug der einen reminder aus dem interval
                //auf false setzt und dann falsch in das array pusht
                if (processedEvent.interval.reminder[0] === false) {
                    processedEvent.interval.reminder.shift();
                }

                //Setzt den ausgewählten reminder auf das richtige Datum und Uhrzeit
                if (processedEvent.interval.reminder) {
                    var reminderDates = [];
                    angular.forEach(
                        processedEvent.interval.reminder,
                        function (value) {
                            var eventDate = moment(processedEvent.start_date);
                            var date = eventDate.subtract(value.amount, value.type).format();
                            this.push(date);
                        },
                        reminderDates
                    );
                    processedEvent.pushReminder = reminderDates;
                }
            };
            //Deselektiert alle reminder und setzt sie auf 'Ohne'
            $scope.uncheckReminder = function (processedEvent) {
                processedEvent.interval.reminder = false;
                processedEvent.pushReminder = [];
            };

            /***************************************************************
             * Speichert / Updated ein Event
             ***************************************************************/
            $scope.cfUpdateEvent = function (processedEvent) {
                if (processedEvent.interval.interval_type === 5) {
                    processedEvent.allday = true;
                    processedEvent.active_during_holiday = true;
                }
                cfEventsService.cfUpdateEvent(processedEvent).then(
                    function (data) {
                        //Zeigt die Meldung an, dass Termin versendet wurde
                        if (data.notify) {
                            ns.displayNotification('success', 'events_notify');
                        }

                        ns.displayNotification('success', 'event_success_update');
                        $scope.processedEvent = false;
                        cfEventsService.loadEventsForPeriod(
                            family,
                            $scope.main.familyFilter,
                            {
                                start: sts.selectedDate.firstDayOfMonth,
                                end: sts.selectedDate.lastDayOfMonth,
                            },
                            function (newEvents) {
                                if ($scope.calendarView === 'day') {
                                    $scope.sortMachine = [];
                                    $scope.allDayEvents = [];
                                    $scope.allDayEvents = newEvents[sts.getSelectedDate().dayKey].filter(function (e) {
                                        return e.allday || e.birthday;
                                    });
                                    var eventsWithTime = newEvents[sts.getSelectedDate().dayKey].filter(function (e) {
                                        return !e.allday && !e.birthday;
                                    });
                                    $scope.sortMachine = cfEventsService.createSortMachine(eventsWithTime);
                                } else if ($scope.calendarView === 'week') {
                                    $scope.loadEventsForWeek();
                                } else {
                                    const eventsForDay = newEvents[sts.getSelectedDate().dayKey];
                                    $scope.eventsForDay = $filter('filterEventsForDay')(eventsForDay);
                                }
                            }
                        );
                    },
                    function () {
                        ns.displayNotification('error', 'event_error_update_server');
                    }
                );
            };

            /***************************************************************
             * Löscht ein Event
             ***************************************************************/
            $scope.cfDeleteEvent = function (eventId) {
                cfEventsService.cfDeleteEvent(eventId).then(
                    function () {
                        ns.displayNotification('success', 'event_success_delete');
                        $scope.processedEvent = false;
                        cfEventsService.loadEventsForPeriod(
                            family,
                            $scope.main.familyFilter,
                            {
                                start: sts.selectedDate.firstDayOfMonth,
                                end: sts.selectedDate.lastDayOfMonth,
                            },
                            function (newEvents) {
                                if ($scope.calendarView === 'day') {
                                    $scope.sortMachine = [];
                                    $scope.allDayEvents = [];
                                    $scope.allDayEvents = newEvents[sts.getSelectedDate().dayKey].filter(function (e) {
                                        return e.allday;
                                    });
                                    var eventsWithTime = newEvents[sts.getSelectedDate().dayKey].filter(function (e) {
                                        return !e.allday;
                                    });
                                    $scope.sortMachine = cfEventsService.createSortMachine(eventsWithTime);
                                } else if ($scope.calendarView === 'week') {
                                    $scope.loadEventsForWeek();
                                }
                            }
                        );
                    },
                    function () {
                        ns.displayNotification('error', 'event_error_delete_server');
                    }
                );
            };

            $scope.cfSecureDeleteEvent = function (eventId) {
                $scope.deleteConfirmation({
                    resolve: {
                        title: 'Termin löschen',
                        body: 'Möchtest du diesen Termin wirklich löschen?',
                    },
                    onSuccess: () => {
                        $scope.cfDeleteEvent(eventId);
                    },
                });
            };

            /***************************************************************
             * Klick auf einen Tag in der Monatsansicht. Lädt die Termine für diesen Tag
             ***************************************************************/
            $scope.dayClick = function (date) {
                if (date) sts.selectedDate.setTime(date);
                else date = new Date(sts.selectedDate.timestamp);
                $scope.clickDay = date;
                const eventsForDay = cfEventsService.getEventsForDay(date);
                $scope.eventsForDay = $filter('filterEventsForDay')(eventsForDay);
            };

            /***************************************************************
             * Öffnet das Erstellen / Bearbeiten Formular
             ***************************************************************/
            $scope.openEditEventForm = function (event) {
                if (!event) {
                    $scope.processedEvent = {};
                    $scope.processedEvent.family = family._id;
                    //ToDo: Intervall wird statisch gesetzt. Muss dann vom Formular gefüllt werden.
                    $scope.processedEvent.interval = {};
                    $scope.processedEvent.interval.interval_type = 0;
                    var now = moment(sts.selectedDate.timestamp).locale('de');
                    $scope.processedEvent.start_date = now.clone().second(0).minute(0).hour(12).toDate();
                    $scope.processedEvent.end_date = now.clone().second(0).minute(0).hour(13).toDate();
                } else {
                    event.start_date = new Date(event.start_date);
                    event.end_date = new Date(event.end_date);
                    if (event && event.intervall && event.interval.exceptions && event.interval.exceptions.length > 0) {
                        $scope.toggle.exceptionDates = true;
                        $scope.toggle.exceptions = true;
                    }
                    $scope.processedEvent = event;
                    $scope.processedEvent.exception = new Date($scope.day);
                }
            };

            /***************************************************************
             * Ändert die Ansicht des Kalenders (Tag,Woche,Monat)
             ***************************************************************/
            $scope.changeCalendarView = function (newView) {
                $scope.calendarView = newView;
                $scope.materielCalendar.startMonth = sts.selectedDate.utcMonth;
                $scope.materielCalendar.startYear = sts.selectedDate.year;
                if ($scope.calendarView === 'month') $scope.loadEventsForMonth();
            };

            $scope.$on('FamilyFilter', function () {
                cfEventsService.refreshCalendar($scope.main.familyFilter, $scope.main.user.family.color_id);
                if ($scope.calendarView === 'week') $scope.loadEventsForWeek();
                if ($scope.calendarView === 'day') $scope.loadEventsForDay();
            });

            $scope.$on('date-changed', function (event, data) {
                $scope.calendar.selectedDate = new Date(sts.selectedDate.timestamp);

                if ($scope.calendarView === 'month' && data.oldMonth !== data.newMonth) $scope.loadEventsForMonth();
                else if ($scope.calendarView === 'week') $scope.loadEventsForWeek();
                else if ($scope.calendarView === 'day') $scope.loadEventsForDay();
                if ($scope.calendarView === 'month') $scope.dayClick();
            });

            $scope.setCalenderClass = function (selected) {
                if ($scope.calendarView === selected) return 'selectedCalender';
                else return '';
            };

            $scope.getIntervalTypes = (function () {
                cfEventsService.getIntervalTypes().then(function (data) {
                    $scope.interval = data;
                });
            })();

            $scope.addDateToExceptions = function (date) {
                var newException = {};
                $scope.toggle.exceptionDates = true;
                if (!$scope.processedEvent.interval.exceptions) {
                    $scope.processedEvent.interval.exceptions = [];
                }
                $scope.dateIsInArray = false;
                for (var i = 0; i < $scope.processedEvent.interval.exceptions.length; i++) {
                    if (
                        new Date(date).toString() ===
                        new Date($scope.processedEvent.interval.exceptions[i].date).toString()
                    ) {
                        $scope.dateIsInArray = true;
                    }
                }
                if (!$scope.processedEvent.interval.exceptions) {
                    $scope.processedEvent.interval.exceptions = [];
                }
                if ($scope.dateIsInArray === true) {
                    ns.displayNotification('warning', 'double_exception');
                } else {
                    newException.date = date;
                    $scope.processedEvent.interval.exceptions.push(newException);
                }
            };

            $scope.removeException = function (date) {
                for (var i = 0; i < $scope.processedEvent.interval.exceptions.length; i++) {
                    if (
                        new Date(date).toString() ===
                        new Date($scope.processedEvent.interval.exceptions[i].date).toString()
                    ) {
                        $scope.processedEvent.interval.exceptions.splice(i, 1);
                    }
                }
            };

            /***************************************************************
             * Validiere Startzeit eines Termins.
             ***************************************************************/
            $scope.$watch('processedEvent.start_date ', function (newStart, oldStart) {
                if (newStart && oldStart) {
                    if (newStart > $scope.processedEvent.end_date) {
                        var newTime = newStart.getTime() + 60 * 60 * 1000;
                        $scope.processedEvent.end_date = new Date(newTime);
                    }
                }
            });

            /***************************************************************
             * Validiere Endzeit eines Termins.
             ***************************************************************/
            $scope.$watch('processedEvent.end_date ', function (newEnd, oldEnd) {
                if (newEnd && oldEnd) {
                    if (newEnd < $scope.processedEvent.start_date) {
                        var newTime = newEnd.getTime() - 60 * 60 * 1000;
                        $scope.processedEvent.start_date = new Date(newTime);
                    }
                }
            });
            /***************************************************************
             * Automatische Selektierung des Ausgewählten Tages in interval.byDay
             ***************************************************************/
            $scope.$watch('processedEvent.interval.interval_type', function (interval) {
                if (interval && interval === 2 && !$scope.processedEvent.interval.byDay) {
                    $scope.processedEvent.interval.byDay = [];
                    var day = $scope.processedEvent.start_date.getDay();
                    if (day === 0) day = 7;
                    $scope.processedEvent.interval.byDay.push(day);
                }
            });

            $scope.clearInterval = function (reset) {
                $scope.birthdaySelect = false;
                switch (reset) {
                    case 0:
                        $scope.processedEvent.interval.eventRepitition = '';
                        $scope.processedEvent.interval.byMonth = '';
                        $scope.processedEvent.interval.byMonthDay = '';
                        $scope.processedEvent.interval.byDay = [];
                        $scope.processedEvent.interval.interval_max_count = '';
                        $scope.processedEvent.interval.interval_end_date = '';
                        $scope.processedEvent.active_during_holiday = false;
                        $scope.eventEnd = 10;
                        return $scope.processedEvent;
                    case 1:
                        $scope.processedEvent.interval.eventRepitition = 1;
                        return $scope.processedEvent;
                    case 2:
                        $scope.processedEvent.interval.eventRepitition = 1;
                        return $scope.processedEvent;
                    case 3:
                        $scope.processedEvent.interval.byMonthDay = 1;
                        $scope.processedEvent.interval.byMonth = 0;
                        $scope.processedEvent.interval.eventRepitition = 0;
                        $scope.processedEvent.interval.byDay = 0;
                        return $scope.processedEvent;
                    case 4:
                        $scope.processedEvent.interval.eventRepitition = 1;
                        return $scope.processedEvent;
                    case 5:
                        $scope.processedEvent.interval.eventRepitition = 0;
                        $scope.birthdaySelect = true;
                        return $scope.processedEvent;

                    case 10:
                        $scope.processedEvent.interval.interval_max_count = '';
                        $scope.processedEvent.interval.interval_end_date = '';
                        return $scope.processedEvent;
                    case 11:
                        $scope.processedEvent.interval.interval_max_count = '';
                        return $scope.processedEvent;
                    case 12:
                        $scope.processedEvent.interval.interval_end_date = '';
                        return $scope.processedEvent;
                }
            };

            /***************************************************************
             * CRUD: Read events By Id
             ***************************************************************/
            $scope.cfGetEventById = function (eventId) {
                cfEventsService.getEventById(eventId).then(function (data) {
                    $scope.openEditEventForm(data);
                });
            };

            /***************************************************************
             * INITIAL
             ***************************************************************/
            if ($routeParams.id) $scope.cfGetEventById($routeParams.id);

            /***************************************************************
             * Options für Date und Timepicker
             ***************************************************************/
            $scope.dateOptions = {
                formatYear: 'yyyy',
                startingDay: 1,
            };
            $scope.formatWithDay = 'EEEE, dd. MMMM yyyy';
            $scope.format = 'EEE, dd. MMMM yyyy';
            /** Format des Datepickers **/
            $scope.showDatepicker = false;
            /** Datepicker ein/aus blenden **/
            $scope.hstep = 1;
            /** Stungen werden um x verschoben **/
            $scope.mstep = 5;
            /** Minuten werden um x verschoben **/
            $scope.ismeridian = false;
            /** 12/24 h Anzeige **/

            $scope.showDatePicker = {
                start: false,
                end: false,
                intEnd: false,
            };

            $scope.openDatepicker = function (kind) {
                if (kind === 'start') $scope.showDatePicker.start = true;
                if (kind === 'end') $scope.showDatePicker.end = true;
                if (kind === 'intEnd') $scope.showDatePicker.intEnd = true;
                if (kind === 'exception') $scope.showDatePicker.exception = true;
            };

            $scope.setDateForMonthlyRythm = () => {
                const day = moment($scope.processedEvent.start_date).day();
                if ($scope.processedEvent && $scope.processedEvent.interval && $scope.processedEvent.start_date)
                    if (day === 0)
                        // Sonntag ist für Moment 0 und im Famco 7
                        $scope.processedEvent.interval.byDay = 7;
                    else $scope.processedEvent.interval.byDay = day;
            };
        },
    ]);
;
 
angular.module('cfFamilyCtrl', ['cfFamilyService']).controller('cfFamilyCtrl', [
    '$scope',
    'cfFamilyService',
    '$location',
    function ($scope, cfFamilyService, $location) {
        //ToDo Funktionen kommentieren + Ctrl aufräumen Burhan

        $scope.editFamilyForm = false;
        $scope.createFamilyForm = false;
        $scope.processedFamily = { familyMembers: [] };

        $scope.openCreateForm = function () {
            $scope.createFamilyForm = true;
            $scope.processedFamily = {};
        };

        $scope.leaveForm = function () {
            $scope.createFamilyForm = false;
            $scope.editFamilyForm = false;
            $scope.processedFamily = { familyMembers: [] };
        };

        $scope.cfCreateFamily = function (processedFamily) {
            if ($scope.createFamilyForm) {
                cfFamilyService.createFamily(processedFamily).then(
                    function () {
                        $scope.leaveForm();
                        $scope.cfGetFamilies();
                    },
                    function (data) {
                        console.error('Fehler beim erstellen', data);
                    }
                );
            }
        };

        $scope.cfUpdateFamily = function (processedFamily) {
            if ($scope.editFamilyForm) {
                cfFamilyService.updateFamily(processedFamily).then(
                    function () {
                        $scope.leaveForm();
                        $scope.cfGetFamilies();
                    },
                    function (data) {
                        console.error('Fehler beim erstellen', data);
                    }
                );
            }
        };

        $scope.cfGetFamilyById = function (family) {
            $scope.editFamilyForm = true;
            cfFamilyService.getFamilyById(family._id).then(function (data) {
                family.tasks = data;
                $scope.processedFamily = family;
                $location.path('/familydetail/' + family._id);
            });
        };

        $scope.cfDeleteFamily = function (familyId) {
            cfFamilyService.deleteFamily(familyId).then(
                function () {
                    $scope.cfGetFamilies();
                },
                function () {}
            );
        };

        $scope.getColor = function (owner) {
            $scope.familyColor = 'fc-icon-' + owner.icon + ' fc-color-' + owner.familyColor;
            return $scope.familyColor;
        };
    },
]);
;
 
angular
    .module('cfFamilyProfileCtrl', [
        'cfUserService',
        'cfFamilyProfileService',
        'cfProfileService',
        'NotificationService',
    ])
    .controller('cfFamilyProfileCtrl', [
        '$scope',
        '$location',
        'cfUserService',
        'cfFamilyProfileService',
        'cfProfileService',
        'NotificationService',
        'usSpinnerService',
        function (
            $scope,
            $location,
            cfUserService,
            cfFamilyProfileService,
            cfProfileService,
            NotificationService,
            usSpinnerService
        ) {
            $scope.roles = cfFamilyProfileService.getRole() || [];
            $scope.permissions = cfFamilyProfileService.getPermission() || [];
            $scope.colors = cfFamilyProfileService.getColors() || [];
            $scope.states = [];

            $scope.familyProfileFormData = {
                familyName: '',
                color_id: '0',
                country: '',
                countryzone: '',
                familyMembers: [],
                familyImage: '',
            };

            cfFamilyProfileService
                .getFamily($scope.main.user.family._id)
                .then(family => ($scope.familyProfileFormData = family))
                .catch(error => console.error('ERROR in getFamilyProfile:', error));

            cfProfileService
                .getStates()
                .then(states => ($scope.states = states))
                .catch(error => console.error('ERROR in getStates:', error));

            $scope.getCurrentColorNameFromColorId = colorId => {
                const { name } = $scope.colors.find(({ value }) => value === colorId);
                return name || 'unknown';
            };

            $scope.getRoleNameById = roleId => {
                const { name } = $scope.roles.find(({ value }) => value === roleId);
                return name || 'unknown';
            };

            $scope.getPermissionNameById = permissionId => {
                const { name } = $scope.permissions.find(({ value }) => value === permissionId);
                return name || 'unknown';
            };

            $scope.getPlaceholderForCountryZoneByCountryCode = countryCode => {
                return countryCode === 'CH' ? 'Kein Kanton ausgewählt' : 'Kein Bundesland ausgewählt';
            };

            $scope.cfUpdateFamilyProfile = function (processedProfile, familyImage) {
                usSpinnerService.spin('spinner');
                cfFamilyProfileService
                    .updateFamilyProfile(processedProfile, familyImage)
                    .then(() => cfUserService.getCurrentUserFromDB())
                    .then(user => {
                        // FIXME: global user mutation/reassignments
                        $scope.main.user = user;
                        NotificationService.displayNotification('success', 'profile_save_success');
                        location.hash = '/';
                        location.reload();
                    })
                    .catch(err => {
                        console.error(err);
                        NotificationService.displayNotification('error', err.message);
                    })
                    .finally(() => {
                        usSpinnerService.stop('spinner');
                    });
            };
        },
    ]);
;
 
angular.module('cfFaqCtrl', ['cfFaqService']).controller('cfFaqCtrl', [
    '$scope',
    '$location',
    '$routeParams',
    'cfFaqService',
    '$http',
    function ($scope, $location, $routeParams, cfFaqService, $http) {
        cfFaqService.getFaqKategorie().then(function (data) {
            $scope.faqKategorieen = data;
            if ($routeParams.id) {
                $scope.currentFaq = data.find(faq => faq._id === $routeParams.id);
            }
        });

        $scope.getFrageAntwort = function (kategorie) {
            $location.path('/faqantworten/' + kategorie._id);
        };

        /** Paul's Timestamp Test */
        $http({
            method: 'GET',
            url: '/getTimestamps',
        }).then(
            function (response) {
                $scope.timestamps = response.data;
            },
            function (response) {
                console.error(response);
            }
        );
    },
]);
;
 
angular.module('cfHomeCtrl', ['cfHomeService', 'cfEventsService', 'cfUserService']).controller('cfHomeCtrl', [
    '$scope',
    '$uibModal',
    '$location',
    'user',
    'cfHomeService',
    'cfEventsService',
    'cfSelectedTimeService',
    'usSpinnerService',
    'cfUserService',
    function (
        $scope,
        $uibModal,
        $location,
        user,
        cfHomeService,
        cfEventsService,
        sts,
        usSpinnerService,
        cfUserService
    ) {
        $scope.gotToEventView = function () {
            sts.selectedDate.setTime(new Date());
            $location.path('/kalender');
        };

        /***************************************************************
         * Holt Alle Listen, Falls aktive Filtervalues, return von Gefilterten Listen
         ***************************************************************/
        $scope.getListsForPinnwand = function () {
            usSpinnerService.spin('famcospinner');
            cfHomeService.getListsForPinnwand().then(function (data) {
                $scope.main.loadedLists = data;
                $scope.main.aufgaben = data.filter(function (d) {
                    return d.listType === 'Aufgaben';
                });
                $scope.main.einkaufslisten = data.filter(function (d) {
                    return d.listType === 'Einkaufsliste';
                });
                $scope.main.wunschlisten = data.filter(function (d) {
                    return d.listType === 'Wunschliste';
                });
                usSpinnerService.stop('famcospinner');
            });
        };

        /***************************************************************
         * Holt Alle Checklisten
         ***************************************************************/
        $scope.getChecklistsForPinnwand = function () {
            cfHomeService.getChecklistsForPinnwand().then(function (data) {
                $scope.main.loadedChecklists = data;
            });
        };

        /***************************************************************
         * Holt Alle Notizen, Falls aktive Filtervalues, return von Gefilterten Notizen
         ***************************************************************/
        $scope.getNotesForPinnwand = function () {
            cfHomeService.getNotesForPinnwand().then(function (data) {
                $scope.main.loadedNotes = data;
            });
        };
        $scope.eventsForDay = [];

        /***************************************************************
         * Falls es weder Termine noch Geburtstage gibt
         ***************************************************************/

        $scope.checkForBirthday = function (events) {
            if (events.length > 0) {
                $scope.birthday = false;
                events.forEach(function (event) {
                    if (event.birthday) {
                        $scope.birthday = true;
                    }
                });
            }
        };

        $scope.getEventsForPinnwand = function () {
            sts.selectedDate.setTime(new Date());
            cfEventsService.loadEventsForPeriod(
                user.family,
                null,
                {
                    start: sts.selectedDate.firstDayOfWeek,
                    end: sts.selectedDate.lastDayOfWeek,
                },
                function () {
                    $scope.eventsForDay = cfEventsService.getEventsForDay(new Date());
                    $scope.checkForBirthday($scope.eventsForDay);
                }
            );
        };

        /***************************************************************
         * INITIAL
         ***************************************************************/
        $scope.getListsForPinnwand();
        $scope.getNotesForPinnwand();
        $scope.getEventsForPinnwand();
        $scope.getChecklistsForPinnwand();

        $scope.openEventModal = function (event) {
            var modalInstance = $uibModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'html/modals/eventModal.html',
                controller: 'cfEventModalCtrl',
                size: 'sm',
                resolve: {
                    dynamicLoc: function () {
                        return $scope.dynamicLoc;
                    },
                    userPermission: function () {
                        return $scope.main.user.permission;
                    },
                    event: function () {
                        return event;
                    },
                },
            });

            modalInstance.result.then(
                function (event) {
                    $location.path('kalender/' + event._id);
                },
                function (reason) {
                    console.error(reason);
                }
            );
        };

        // TODO: inject/detect app version
        if (user.lastSeenChangelogVersion !== '5.1.0') {
            $scope.deleteConfirmation({
                templateUrl: 'html/modals/changeLogModal.html',
                size: 'md',
                resolve: {
                    title: 'Neu im Family Cockpit',
                    body: 'Im Menü findet ihr jetzt eine neue Funktion: das Pflegetagebuch. Damit könnt ihr die Pflege eines Angehörigen dokumentieren oder einfach sicherstellen, dass Medikamente regelmäßig eingenommen werden. Wie immer freuen wir uns über euer Feedback.',
                },
                onSuccess: confirmation => {
                    confirmation && cfUserService.confirmLastSeenChangelogs().then(cfUserService.reloadUser());
                },
            });
        }
    },
]);
;
 
angular
    .module('cfInvitationCtrl', ['cfUserService', 'cfFamilyProfileService', 'NotificationService'])
    .controller('cfInvitationCtrl', [
        '$scope',
        'cfUserService',
        'cfFamilyProfileService',
        'NotificationService',
        function ($scope, cfUserService, cfFamilyProfileService, NotificationService) {
            $scope.invitationForm = {};

            $scope.roles = cfFamilyProfileService.getRole();
            $scope.permissions = cfFamilyProfileService.getPermission();

            $scope.cfCreateInvitationData = function (invitationForm, valid) {
                if (valid) {
                    var familyAddress = $scope.main.user.family;
                    cfFamilyProfileService.CreateInvitationData(familyAddress, invitationForm).then(
                        function () {
                            if (invitationForm.notification_via_email) {
                                NotificationService.displayNotification('success', 'invitation_success');
                            } else {
                                NotificationService.displayNotification('success', 'invitation_success_no_mail');
                            }
                            // todo: what's going on here
                            setTimeout(() => {
                                cfUserService.reloadUser().then(function (data) {
                                    $scope.main.user = data;
                                    location.hash = '/family';
                                    location.reload();
                                });
                            }, 5000);
                        },
                        function (messageKey) {
                            NotificationService.displayNotification('error', messageKey, null, 5000);
                        }
                    );
                } else {
                    $scope.userForm.firstName.$dirty = true;
                    $scope.userForm.lastName.$dirty = true;
                    $scope.userForm.$dirty = true;
                    $scope.userForm.email.$dirty = true;
                    $scope.userForm.emailMatch.$dirty = true;
                }
            };
        },
    ]);
;
 
angular.module('cfListCtrl', ['cfListService', 'NotificationService']).controller('cfListCtrl', [
    '$scope',
    'cfListService',
    '$location',
    '$routeParams',
    '$filter',
    'cfSelectedTimeService',
    'NotificationService',
    function ($scope, cfListService, $location, $routeParams, $filter, cfSelectedTimeService, NotificationService) {
        $scope.urlListType = $routeParams.listType;
        $scope.processedList = false;
        $scope.viewedList = false;
        $scope.newTaskName = '';
        $scope.lists = true;
        $scope.now = moment(cfSelectedTimeService.selectedDate.timestamp).locale('de');
        $scope.currentDate = $scope.now.clone().second(0).minute(0).hour(12).toDate();

        $scope.checkForPrivate = (familyMembers, ownerIds) => {
            if (ownerIds && ownerIds.length) {
                let returnValue = true;

                const owners = ownerIds.map(id => familyMembers.find(member => member._id === id));
                owners.forEach(owner => {
                    if (!owner || (owner && !owner.contactData) || (owner.contactData && !owner.contactData.email))
                        returnValue = false;
                });

                if (!returnValue) $scope.processedList.isPrivate = false;
                return returnValue;
            }
            // Falls kein owner oder Family
            else if (ownerIds && !ownerIds.length) {
                $scope.processedList.isPrivate = false;
                return false;
            } else return false;
        };

        $scope.openForm = function (list) {
            $scope.processedList = {};
            $scope.processedList.tasks = [];
            $scope.processedList.listType = $scope.urlListType;
            $scope.viewedList = false;
            $scope.lists = false;

            /* Setzt den Datepicker für die Aufgabenliste */
            if ($scope.processedList.listType === 'Aufgaben') {
                if (list) {
                    list.reminderDate = new Date(list.reminderDate);
                    list.dueDate = new Date(list.dueDate);
                    $scope.processedList = angular.copy(list);
                    /* Kontrolle ob noch kein Datum gesetzt ist --> Setzt die Datepicker auf aktuelles Datum */
                    if (!list.dueDateSelected || !list.dueDateSelected) {
                        $scope.processedList.dueDate = $scope.currentDate;
                        $scope.processedList.reminderDate = $scope.currentDate;
                        list.dueDateSelected = false;
                    }
                    if (!list.reminderDateSelected || !list.reminderDateSelected) {
                        list.dueDateSelected = false;
                        $scope.processedList.reminderDate = $scope.currentDate;
                    }
                } else {
                    /* Setzt das Datum auf den aktuellen Tag wenn eine neue Liste erstellt wird */
                    $scope.processedList.dueDate = $scope.currentDate;
                    $scope.processedList.reminderDate = $scope.currentDate;
                }
            } else {
                if (list) {
                    $scope.processedList = angular.copy(list);
                }
            }
        };

        $scope.closeForm = function (data) {
            $scope.processedList = false;
            $scope.viewedList = data;
            $scope.lists = false;
        };

        $scope.viewList = function (list) {
            $scope.processedList = false;
            $scope.viewedList = list;
            $scope.viewedList.newTaskName = '';
            $scope.lists = false;
        };

        $scope.goBack = function (list) {
            $scope.processedList = false;
            if (list && list._id) {
                $scope.viewList(list);
            } else {
                $scope.processedList = false;
                $scope.viewedList = false;
                $scope.lists = true;
            }
        };

        var absUrl = $location.absUrl();
        if (absUrl.indexOf('Aufgaben') > -1) {
            $scope.listBackground = true;
        }

        /***************************************************************
         * CRUD: Create / Update Liste
         ***************************************************************/
        $scope.cfUpdateList = function (processedList) {
            /* Setzt das Datum auf Null wenn beim speichern der Liste dich Checkboxen nicht angewählt sind */
            if (!processedList.reminderDateSelected || !processedList.dueDateSelected) {
                processedList.reminderDate = null;
            }
            if (!processedList.dueDateSelected) {
                processedList.dueDate = null;
                processedList.reminderDateSelected = false;
            }

            cfListService.cfUpdateList(processedList).then(
                function (data) {
                    $scope.closeForm(data);
                    $scope.cfGetLists($routeParams.listType);
                },
                function (reason) {
                    console.error('$scope.cfUpdateList Fehler: ', reason);
                }
            );
        };

        /***************************************************************
         * CRUD: Create / Update Task
         ***************************************************************/
        $scope.cfUpdateTask = function (list) {
            cfListService.cfUpdateTask(list).then(
                function (data) {
                    $scope.viewedList = data;
                    $scope.cfGetLists($routeParams.listType);
                    list.newTaskName = '';
                },
                function (reason) {
                    console.error('$scope.cfUpdateTask Fehler: ', reason);
                }
            );
        };

        /***************************************************************
         * CRUD: Delete Task
         ***************************************************************/
        $scope.cfRemoveTask = function (list, id) {
            list.tasks = list.tasks.filter(function (task) {
                return id !== task._id;
            });
            cfListService.cfUpdateTask(list).then(
                function () {
                    list.newTaskName = '';
                },
                function (reason) {
                    console.error('$scope.cfRemoveTask Fehler: ', reason);
                }
            );
        };

        /***************************************************************
         * CRUD: Delete List
         ***************************************************************/
        $scope.cfRemoveList = function (list) {
            cfListService.cfRemoveList(list).then(
                function () {
                    $scope.closeForm();
                    $scope.cfGetLists($routeParams.listType);
                    $scope.lists = true;
                },
                function (reason) {
                    console.error('$scope.cfRemoveList Fehler: ', reason);
                }
            );
        };

        $scope.$on('FamilyFilter', function () {
            $scope.filteredLists = $filter('listFilter')($scope.loadedLists, $scope.main.familyFilter);
        });

        /***************************************************************
         * CRUD: Read Listen
         ***************************************************************/
        $scope.cfGetLists = function (listType) {
            cfListService.getLists(listType).then(function (data) {
                $scope.loadedLists = data;
                $scope.filteredLists = $filter('listFilter')(data, $scope.main.familyFilter);
            });
        };

        /***************************************************************
         * CRUD: Read lists By Id
         ***************************************************************/
        $scope.cfGetListById = function (listId) {
            cfListService.getListById(listId).then(function (data) {
                $scope.viewList(data);
            });
        };

        /***************************************************************
         * INITIAL
         ***************************************************************/
        $scope.cfGetLists($routeParams.listType);
        if ($routeParams.id) $scope.cfGetListById($routeParams.id);

        /***************************************************************
         * Options für Date und Timepicker
         ***************************************************************/
        $scope.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1,
        };
        $scope.formatWithDay = 'EEEE, dd. MMMM yyyy';
        $scope.format = 'EEE, dd. MMMM yyyy';
        /** Format des Datepickers **/
        $scope.showDatepicker = false;
        /** Datepicker ein/aus blenden **/
        $scope.hstep = 1;
        /** Stungen werden um x verschoben **/
        $scope.mstep = 5;
        /** Minuten werden um x verschoben **/
        $scope.ismeridian = false;
        /** 12/24 h Anzeige **/

        $scope.showDatePicker = {
            dueDate: false,
            reminderDate: false,
        };

        $scope.openDatepicker = function (kind) {
            if (kind === 'dueDate') $scope.showDatePicker.dueDate = true;
            if (kind === 'reminderDate') $scope.showDatePicker.reminderDate = true;
        };

        $scope.cfSecureDeleteList = function (list) {
            $scope.deleteConfirmation({
                onSuccess: () => {
                    $scope.cfRemoveList(list);
                    NotificationService.displayNotification('success', 'Liste wurde erfolgreich gelöscht!');
                },
            });
        };
    },
]);
;
 
angular.module('cfListDetailCtrl', ['cfListService']).controller('cfListDetailCtrl', [
    '$scope',
    'cfListService',
    '$location',
    '$routeParams',
    '$timeout',
    function ($scope, cfListService, $location, $routeParams, $timeout) {
        //ToDo Funktionen kommentieren + Ctrl aufräumen Burhan
        $scope.editListForm = false;
        $scope.createListForm = false;

        $scope.openCreateForm = function () {
            $scope.createListForm = true;
            $scope.processedList = {};
        };
        $scope.listBackground = false;
        $scope.taskValue = '';

        $scope.toggleEdit = false;

        $scope.leaveForm = function () {
            $scope.createListForm = false;
            $scope.editListForm = false;
            $scope.processedList = { tasks: [] };
        };

        /***************************************************************
         * Updated Liste wenn Checkbox gedrückt
         ***************************************************************/
        $scope.cfUpdateList = function (processedList) {
            if ($scope.editListForm) {
                cfListService.updateList(processedList).then(
                    function () {
                        $scope.cfGetListById(processedList._id);
                    },
                    function (reason) {
                        console.error('Fehler beim erstellen', reason);
                    }
                );
            }
        };

        /***************************************************************
         * Sucht Liste anhand ID
         ***************************************************************/
        $scope.cfGetListById = function (listId) {
            $scope.editListForm = true;
            cfListService.getListById(listId).then(function (data) {
                $scope.processedList = data;
            });
        };
        $scope.cfGetListById($routeParams.id);

        /***************************************************************
         * Erstellt einen Task auf einer Liste
         ***************************************************************/
        $scope.cfCreateTask = function (listId, taskName) {
            cfListService.createTask(listId, taskName).then(
                function () {
                    $scope.cfGetListById(listId);
                    $scope.taskValue = '';
                },
                function () {}
            );
        };

        /***************************************************************
         * Löscht Liste
         ***************************************************************/
        $scope.cfDeleteList = function (listId) {
            cfListService.deleteList(listId).then(
                function () {
                    $location.path('/listen/' + $routeParams.listType);
                },
                function () {}
            );
        };

        /***************************************************************
         * Löscht Task von Liste
         ***************************************************************/
        $scope.cfDeleteTask = function (listId, taskName) {
            cfListService.deleteTask(listId, taskName).then(
                function () {
                    $scope.cfGetListById(listId);
                },
                function (reason) {
                    console.error('Task Löschen fehler ', reason);
                }
            );
        };

        /***************************************************************
         * Toggelt Editfeld für Tasks
         ***************************************************************/
        $scope.changeEdit = function (task) {
            $timeout(function () {
                $scope.processedList.tasks.forEach(function (task) {
                    task.edit = false;
                });
                if (task) task.edit = !task.edit;
            }, 100);
        };
    },
]);
;
 
angular.module('cfMobileLoginCtrl', ['cfUserService']).controller('cfMobileLoginCtrl', [
    '$scope',
    'cfUserService',
    'mobileAppService',
    'usSpinnerService',
    '$location',
    'errorReportingService',
    function ($scope, cfUserService, mobileAppService, usSpinnerService, $location, errorReportingService) {
        $scope.loginFormData = {
            username: localStorage.getItem('fc-username'),
            password: localStorage.getItem('fc-password'),
            remember: localStorage.getItem('fc-remember') === 'true',
        };

        $scope.authorizeMobileUser = function () {
            usSpinnerService.spin('famcospinner');
            cfUserService.authorizeMobileUser($scope.loginFormData).then(
                function () {
                    usSpinnerService.stop('famcospinner');
                    $location.path('/home');

                    window.FirebasePlugin?.logEvent('login', { method: 'email' });
                },
                function (error) {
                    console.error(error);
                    errorReportingService.report(error);

                    if (_.isString(error)) {
                        $scope.errorMessage = error;
                    } else {
                        $scope.errorMessage =
                            'Unerwarteter Fehler beim Login. Bitte versuche es nochmal oder wende dich an den Support';
                    }
                    usSpinnerService.stop('famcospinner');
                }
            );
        };
    },
]);
;
 
angular.module('cfModalCtrl', []).controller('cfModalCtrl', [
    '$scope',
    '$uibModalInstance',
    function ($scope, $uibModalInstance) {
        $scope.modalData = {};

        $scope.closeModal = function (modalData) {
            $uibModalInstance.close(modalData);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
    },
]);
;
 
angular
    .module('cfNewDistributorCtrl', ['cfNewDistributorService', 'cfFamilyService'])
    .controller('cfNewDistributorCtrl', [
        '$scope',
        'cfNewDistributorService',
        'cfFamilyService',
        function ($scope, cfNewDistributorService, cfFamilyService) {
            cfFamilyService.getFamilyById($scope.main.user.family._id).then(function (data) {
                $scope.family = data;
            });

            $scope.toggleCreate = true;

            /***************************************************************
             * Erstellt eine neue Verteilergruppe
             ***************************************************************/
            $scope.cfCreateDistributor = function (processedDistributor) {
                cfNewDistributorService.createDistributor(processedDistributor).then(function (data) {});
            };
        },
    ]);
;
 
angular.module('cfNewWishlistCtrl', ['cfNewWishlistService', 'cfFamilyService']).controller('cfNewWishlistCtrl', [
    '$scope',
    'cfNewWishlistService',
    'cfFamilyService',
    function ($scope, cfNewWishlistService, cfFamilyService) {
        $scope.family = cfFamilyService.getFamilyById();
        $scope.selectedReason = '';
        $scope.cfgetReasons = function () {
            $scope.reasons = cfNewWishlistService.getReasons();
        };
        $scope.cfgetReasons();

        $scope.cfCreateNewWishlist = function (processedWishlist, wishlistImage) {
            if (processedWishlist) {
                cfNewWishlistService.createNewWishlist(processedWishlist, wishlistImage).then(
                    function (data) {},
                    function (data) {
                        console.error('Fehler beim erstellen', data);
                    }
                );
            }
        };

        $scope.setReason = function (reason, reasons) {
            $scope.selectedReason = reason.name;
            reasons.forEach(function (r) {
                if (r.name !== reason.name) r.checked = false;
            });
        };
    },
]);
;
 
angular.module('cfNoteCtrl', ['cfNoteService', 'NotificationService']).controller('cfNoteCtrl', [
    '$scope',
    '$location',
    '$routeParams',
    '$filter',
    '$uibModal',
    'cfNoteService',
    'user',
    'NotificationService',
    function ($scope, $location, $routeParams, $filter, $uibModal, cfNoteService, user, NotificationService) {
        var familyId = user.family._id;

        /** Geladene Notes **/
        $scope.loadedNotes = [];
        /** Geladene & gefilterte Notes **/
        $scope.filteredNotes = [];
        /** Dieser Note wird gerade bearbeitet **/
        $scope.editNote = false;
        /** Bei dieser Note werden sie Settings geändert **/
        $scope.editNoteSettings = false;
        $scope.noteList = true;

        $scope.checkForPrivate = (familyMembers, ownerIds) => {
            if (ownerIds && ownerIds.length) {
                let returnValue = true;

                const owners = ownerIds.map(id => familyMembers.find(member => member._id === id));
                owners.forEach(owner => {
                    if (!owner || (owner && !owner.contactData) || (owner.contactData && !owner.contactData.email))
                        returnValue = false;
                });

                if (!returnValue) $scope.editNoteSettings.isPrivate = false;
                return returnValue;
            }
            // Falls kein owner oder Family
            else if (ownerIds && !ownerIds.length) {
                $scope.editNoteSettings.isPrivate = false;
                return false;
            } else return false;
        };

        /** Formular zum Note Bearbeiten wird eingeblendet **/
        $scope.cfEditNote = function (note) {
            if (!note) note = { owner: [user], family: familyId };
            $scope.editNote = angular.copy(note);
            $scope.editNoteSettings = false;
            $scope.noteList = false;
        };

        /** Formular zum Bearbeiten der Note Settings wird eingeblendet **/
        $scope.cfEditNoteSettings = function (note) {
            if (!note) note = { owner: [user], family: familyId };
            $scope.editNote = false;
            $scope.editNoteSettings = angular.copy(note);
        };

        /** Alle Formulare werden geschlossen **/
        $scope.closeForms = function () {
            $scope.editNote = false;
            $scope.editNoteSettings = false;
            $scope.noteList = true;
            if ($routeParams.id) $location.path('/notizen');
        };

        $scope.goBack = function (note) {
            if (note && note._id) {
                $scope.cfEditNote(note);
            } else {
                $scope.editNote = false;
                $scope.editNoteSettings = false;
                $scope.noteList = true;
            }
        };

        /***************************************************************
         * Bei Event werden die Notes gefiltert
         ***************************************************************/
        $scope.$on('FamilyFilter', function () {
            $scope.filteredNotes = $filter('notesFilter')($scope.loadedNotes, $scope.main.familyFilter);
        });

        /***************************************************************
         * CRUD: Read Notes
         ***************************************************************/
        $scope.cfGetNotes = function (familyId) {
            cfNoteService.getNotes(familyId).then(function (data) {
                $scope.loadedNotes = data;
                $scope.filteredNotes = $filter('notesFilter')(data, $scope.familyFilter);
            });
        };

        /***************************************************************
         * CRUD: Read Note By Id
         ***************************************************************/
        $scope.cfGetNoteById = function (noteId) {
            cfNoteService.getNoteById(noteId).then(function (data) {
                $scope.cfEditNote(data);
            });
        };

        /***************************************************************
         * CRUD: Delete Note
         ***************************************************************/
        $scope.cfDeleteNote = function (noteId) {
            cfNoteService.deleteNote(noteId).then(
                function () {
                    $scope.cfGetNotes(familyId);
                    $scope.closeForms();
                },
                function (data) {
                    NotificationService.displayNotification('error', data);
                }
            );
        };

        /***************************************************************
         * CRUD: Update Note
         ***************************************************************/
        $scope.cfUpdateNote = function (processedNote) {
            if (processedNote.title === '') {
                $scope.cfDeleteNote(processedNote._id);
            } else {
                cfNoteService.updateNote(processedNote).then(
                    function () {
                        $scope.cfGetNotes(familyId);
                    },
                    function (data) {
                        NotificationService.displayNotification('error', data);
                    }
                );
            }
            $scope.editNoteSettings = false;
            $scope.editNote = false;
            $scope.noteList = true;
        };

        /***************************************************************
         * INITIAL
         ***************************************************************/
        $scope.cfGetNotes(familyId);
        if ($routeParams.id) $scope.cfGetNoteById($routeParams.id);

        $scope.cfSecureDeleteNote = function (noteId) {
            $scope.deleteConfirmation({
                resolve: {
                    title: 'Notiz löschen',
                    body: 'Möchtest du diese Notiz löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
                },
                onSuccess: () => {
                    $scope.cfDeleteNote(noteId);
                    NotificationService.displayNotification('success', 'Notiz wurde erfolgreich gelöscht!');
                },
            });
        };
    },
]);
;
 
angular
    .module('cfNursingDiaryDocumentationCtrl', ['cfNursingDiaryDocumentationService', 'cfUserService'])
    .controller('cfNursingDiaryDocumentationCtrl', [
        '$scope',
        '$window',
        'cfNursingDiaryDocumentationService',
        'cfUserService',
        function ($scope, $window, cfNursingDiaryDocumentationService, cfUserService) {
            $scope.familyMembers = cfUserService.getFamilyMembers();

            $scope.options = [
                { value: 'sleep', label: 'Schlaf', icon: 'images/icons/svg/sleep.svg' },
                { value: 'food', label: 'Essen', icon: 'images/icons/svg/food.svg' },
                { value: 'hydration', label: 'Trinken', icon: 'images/icons/svg/hydration.svg' },
                { value: 'mood', label: 'Stimmung', icon: 'images/icons/svg/mood.svg' },
                { value: 'activities', label: 'Aktivitäten', icon: 'images/icons/svg/activity.svg' },
                { value: 'medication', label: 'Medikation', icon: 'images/icons/svg/medication.svg' },
            ];

            $scope.format = 'EEE, d. MMMM yyyy';
            $scope.dateOptions = { formatYear: 'yyyy', startingDay: 1 };
            $scope.showDatePicker = { start: false, end: false };

            $scope.toggleUserDropdown = false;

            $scope.nursingDiaryHistoryFormData = {
                user: '',
                family: $scope.main.user.family._id,
                startDate: '',
                endDate: '',
            };

            $scope.nursingDiaryDocumentationList = [];
            $scope.formSubmittedAndNoResults = false;

            $scope.setSelectedUserFirstname = function (familyMember) {
                $scope.selectedUserFirstName = familyMember?.contactData?.firstName;
                $scope.clearNursingDiaryDocumentationList();
            };

            $scope.clearNursingDiaryDocumentationList = function () {
                $scope.nursingDiaryDocumentationList = [];
            };

            $scope.submitForm = function () {
                $scope.nursingDiaryDocumentationList = [];
                cfNursingDiaryDocumentationService
                    .getNursingDiaryDocumentationEntries($scope.nursingDiaryHistoryFormData)
                    .then(nursingDiaryEntries => {
                        if (nursingDiaryEntries.length) {
                            const category = $scope.nursingDiaryHistoryFormData.category;

                            if (['sleep', 'food', 'hydration', 'mood'].includes(category)) {
                                const filteredNursingDiaryEntries = nursingDiaryEntries.filter(nursingDiaryEntry => {
                                    return !!nursingDiaryEntry.categories[category].sentiment;
                                });
                                $scope.nursingDiaryDocumentationList = filteredNursingDiaryEntries.map(
                                    nursingDiaryEntry => {
                                        return {
                                            nursingDiaryEntryDate: nursingDiaryEntry.date,
                                            data: [
                                                {
                                                    label: getSentimentLabel(
                                                        nursingDiaryEntry.categories[category].sentiment
                                                    ),
                                                    description: nursingDiaryEntry.categories[category].note,
                                                    options: [],
                                                },
                                            ],
                                        };
                                    }
                                );
                            } else if ('activities' === category) {
                                const filteredNursingDiaryEntries = nursingDiaryEntries
                                    .filter(nursingDiaryEntry => !!nursingDiaryEntry[category]?.completed)
                                    .filter(({ nursingDiary }) => !_.isEmpty(nursingDiary.configuration[category]));

                                $scope.nursingDiaryDocumentationList = filteredNursingDiaryEntries.map(
                                    nursingDiaryEntry => {
                                        return {
                                            nursingDiaryEntryDate: nursingDiaryEntry.date,
                                            data: [
                                                {
                                                    label: '',
                                                    description: '',
                                                    options: _.zip(
                                                        nursingDiaryEntry.nursingDiary.configuration[category],
                                                        nursingDiaryEntry[category].completed
                                                    ),
                                                },
                                            ],
                                        };
                                    }
                                );
                            } else if ('medication' === category) {
                                const filteredNursingDiaryEntries = nursingDiaryEntries.filter(nursingDiaryEntry => {
                                    return !!nursingDiaryEntry[category].length;
                                });

                                $scope.nursingDiaryDocumentationList = filteredNursingDiaryEntries.map(
                                    nursingDiaryEntry => {
                                        return {
                                            nursingDiaryEntryDate: nursingDiaryEntry.date,
                                            data: nursingDiaryEntry[category].map((medicationObject, index) => {
                                                return {
                                                    label: nursingDiaryEntry.nursingDiary.configuration[category][index]
                                                        .name,
                                                    description:
                                                        nursingDiaryEntry.nursingDiary.configuration[category][index]
                                                            .description,
                                                    options: Object.entries(medicationObject),
                                                };
                                            }),
                                        };
                                    }
                                );
                            } else {
                                $scope.nursingDiaryDocumentationList = [];
                            }
                        } else {
                            $scope.nursingDiaryDocumentationList = [];
                        }
                        $scope.formSubmittedAndNoResults = !$scope.nursingDiaryDocumentationList.length;
                    })
                    .catch(console.error);
            };

            function getSentimentLabel(sentiment) {
                switch (sentiment) {
                    case 'BAD':
                        return 'zu wenig';
                    case 'MEDIUM':
                        return 'ausreichend';
                    case 'GOOD':
                        return 'gut';
                    default:
                        return '';
                }
            }

            $scope.shareDocumentation = () => {
                $scope.nursingDiaryHistoryFormData.shareUser = $scope.main.user._id;
                cfNursingDiaryDocumentationService
                    .createDocumentationShare($scope.nursingDiaryHistoryFormData)
                    .then(({ shareId }) => {
                        // TODO: replace with build-time variable
                        const WEB_APPLICATION_HOST = 'https://app.familycockpit.de';

                        const shareData = {
                            url: `${WEB_APPLICATION_HOST}/#/pflegetagebuch/share/${shareId}`,
                            title: `Geteilte Pflegetagebuch Ansicht`,
                            text: `${$scope.main.user.contactData.firstName} möchte mit Ihnen die Pflegetagebuchdokumentation teilen.`,
                        };

                        if (navigator.canShare && navigator.canShare(shareData)) {
                            return navigator.share(shareData);
                        } else {
                            $window.open(shareData.url);
                        }
                    });
            };
        },
    ]);
;
 
angular
    .module('cfNursingDiaryEditorCtrl', ['cfNursingDiaryEditorService', 'cfUserService', 'NotificationService'])
    .controller('cfNursingDiaryEditorCtrl', [
        '$scope',
        '$location',
        '$routeParams',
        'cfNursingDiaryEditorService',
        'cfUserService',
        'NotificationService',
        function ($scope, $location, $routeParams, cfNursingDiaryEditorService, cfUserService, NotificationService) {
            $scope.familyMembers = cfUserService.getFamilyMembers();

            $scope.options = [
                { value: 'sleep', label: 'Schlaf', icon: 'images/icons/svg/sleep.svg' },
                { value: 'food', label: 'Essen', icon: 'images/icons/svg/food.svg' },
                { value: 'hydration', label: 'Trinken', icon: 'images/icons/svg/hydration.svg' },
                { value: 'mood', label: 'Stimmung', icon: 'images/icons/svg/mood.svg' },
            ];

            $scope.format = 'EEE, d. MMMM yyyy';
            $scope.dateOptions = { formatYear: 'yyyy', startingDay: 1 };
            $scope.showDatePicker = { start: false, end: false };

            $scope.newActivity = '';
            $scope.newMedication = '';
            $scope.toggleUserDropdown = false;

            $scope.setSelectedUserFirstname = function (familyMember) {
                if (!familyMember) return;
                $scope.selectedUserFirstName = familyMember?.contactData?.firstName;
            };

            if ($routeParams.nursingDiaryId) {
                cfNursingDiaryEditorService
                    .getNursingDiary($routeParams.nursingDiaryId)
                    .then(nursingDiary => {
                        $scope.setSelectedUserFirstname(nursingDiary.user);
                        $scope.nursingDiaryFormData = nursingDiary;
                        $scope.nursingDiaryFormData.user = nursingDiary.user._id;
                        $scope.nursingDiaryFormData.startDate = moment(nursingDiary.startDate).toDate();
                        $scope.nursingDiaryFormData.endDate = moment(nursingDiary.endDate).toDate();
                    })
                    .catch(console.error);
            } else {
                $scope.setSelectedUserFirstname($scope.familyMembers.find(({ _id }) => _id === $routeParams.userId));
                $scope.nursingDiaryFormData = getEmptyNursingDiaryForm();
            }

            function getEmptyNursingDiaryForm() {
                return {
                    user: $routeParams.userId ?? '',
                    family: $scope.main.user.family._id,
                    startDate: moment(Number($routeParams.startDate)).toDate() ?? '',
                    endDate: moment(Number($routeParams.endDate)).toDate() ?? '',
                    configuration: {
                        categories: { sleep: false, food: false, hydration: false, mood: false },
                        activities: [],
                        medication: [],
                    },
                };
            }

            $scope.onInputEnter = function (evt) {
                if (evt.keyCode === 13) {
                    evt.preventDefault();
                    evt.stopPropagation();
                    if (evt.target.name === 'activities') {
                        $scope.addActivity($scope.newActivity);
                    } else if (evt.target.name === 'medication') {
                        $scope.addMedication($scope.newMedication);
                    }
                }
            };

            $scope.addActivity = function (newActivity) {
                if (!newActivity) return;
                $scope.nursingDiaryFormData.configuration.activities = [
                    ...$scope.nursingDiaryFormData.configuration.activities,
                    newActivity,
                ];
                $scope.newActivity = '';
            };

            $scope.removeActivity = function (index) {
                $scope.nursingDiaryFormData.configuration.activities.splice(index, 1);
            };

            $scope.addMedication = function (name) {
                if (!name) return;
                $scope.nursingDiaryFormData.configuration.medication = [
                    ...$scope.nursingDiaryFormData.configuration.medication,
                    {
                        name,
                        description: '',
                        schedule: { morgens: false, mittags: false, nachmittags: false, abends: false, nachts: false },
                    },
                ];
                $scope.newMedication = '';
            };

            $scope.removeMedication = function (index) {
                $scope.nursingDiaryFormData.configuration.medication.splice(index, 1);
            };

            $scope.getTotalScheduleList = function (scheduleObject) {
                return Object.entries(scheduleObject).map(entry => entry[0]);
            };

            $scope.getScheduleList = function (scheduleObject) {
                return Object.entries(scheduleObject)
                    .filter(entry => entry[1])
                    .map(entry => entry[0]);
            };

            $scope.submitForm = function () {
                const { _id, createdAt, updatedAt, ...nursingDiaryFormData } = $scope.nursingDiaryFormData;
                cfNursingDiaryEditorService.createNursingDiary(nursingDiaryFormData).then(() => {
                    NotificationService.displayNotification(
                        'success',
                        _id ? 'Pflegetagebuch wurde aktualisiert.' : 'Pflegetagebuch wurde erstellt.'
                    );
                    $location.path('/pflegetagebuch');
                });
            };

            $scope.resetForm = function () {
                $scope.nursingDiaryFormData.configuration = getEmptyNursingDiaryForm();
                $location.path('/pflegetagebuch');
            };

            $scope.removeNursingDiary = function (nursingDiaryId) {
                $scope.deleteConfirmation({
                    size: 'md',
                    resolve: {
                        title: 'Pflegetagebuch löschen',
                        body: 'Möchtest du dieses Pflegetagebuch wirklich löschen? Damit werden alle Einträge für den gewählten Zeitraum unwiderruflich gelöscht.',
                    },
                    onSuccess: () => {
                        cfNursingDiaryEditorService.removeNursingDiary(nursingDiaryId).then(
                            function () {
                                $scope.nursingDiaryFormData.configuration = getEmptyNursingDiaryForm();
                                NotificationService.displayNotification(
                                    'success',
                                    'Pflegetagebuch wurde erfolgreich gelöscht!'
                                );
                                $location.path('/pflegetagebuch');
                            },
                            function (err) {
                                NotificationService.displayNotification('error', err);
                            }
                        );
                    },
                });
            };
        },
    ]);
;
 
angular
    .module('cfNursingDiaryEntryCtrl', ['cfNursingDiaryEntryService', 'NotificationService'])
    .controller('cfNursingDiaryEntryCtrl', [
        '$scope',
        '$routeParams',
        '$location',
        'cfNursingDiaryEntryService',
        'NotificationService',
        function ($scope, $routeParams, $location, cfNursingDiaryEntryService, NotificationService) {
            $scope.smileyOptions = [
                { value: 'BAD', label: 'weniger gut', icon: 'images/icons/svg/smiley-bad.svg' },
                { value: 'MEDIUM', label: 'mittel', icon: 'images/icons/svg/smiley-medium.svg' },
                { value: 'GOOD', label: 'gut', icon: 'images/icons/svg/smiley-good.svg' },
            ];

            $scope.cupOptions = [
                { value: 'BAD', label: 'weniger gut', icon: 'images/icons/svg/cup-bad.svg' },
                { value: 'MEDIUM', label: 'mittel', icon: 'images/icons/svg/cup-medium.svg' },
                { value: 'GOOD', label: 'gut', icon: 'images/icons/svg/cup-good.svg' },
            ];

            $scope.getScheduleList = function (scheduleObject) {
                return Object.entries(scheduleObject)
                    .filter(entry => entry[1])
                    .map(entry => entry[0]);
            };

            $scope.toggleListView(false);

            $scope.unsetUserId = function () {
                $location.search('userId', null);
                $scope.toggleListView(true);
            };

            $scope.nursingDiaryEntry = {};
            $scope.nursingDiary = {};

            $scope.isDateIncrementDisabled = function () {
                if ($scope.nursingDiaryEntry.date) {
                    return moment($scope.nursingDiaryEntry.date).isSameOrAfter(moment().toDate(), 'days');
                }
            };

            $scope.setDate = function (skip) {
                if (skip === 'after') {
                    $scope.nursingDiaryEntry.date = moment($scope.nursingDiaryEntry.date).add(1, 'days').toDate();
                } else if (skip === 'before') {
                    $scope.nursingDiaryEntry.date = moment($scope.nursingDiaryEntry.date).subtract(1, 'days').toDate();
                } else if (skip) {
                    $scope.nursingDiaryEntry.date = moment(skip).toDate();
                }
                $scope.getDiaryEntryByDate($scope.nursingDiaryEntry.date);
            };

            $scope.getDiaryEntryByDate = function (date) {
                // FIXME: race condition between rending of this controller and `$location.search` call to set $routeParams.userId
                if (!$routeParams.userId) return;
                cfNursingDiaryEntryService.getDiaryEntryByDate(date, $routeParams.userId).then(diaryEntryByDate => {
                    if (diaryEntryByDate) {
                        $scope.nursingDiaryEntry = diaryEntryByDate;
                        $scope.nursingDiaryEntry.date = moment(diaryEntryByDate.date).toDate();
                        $scope.image = diaryEntryByDate.image;

                        if (Array.isArray(diaryEntryByDate.nursingDiary?.configuration?.activities)) {
                            $scope.activities = diaryEntryByDate.nursingDiary.configuration.activities;
                            $scope.medications = diaryEntryByDate.nursingDiary.configuration.medication;
                        } else {
                            $scope.image = undefined;
                            $scope.activities = [];
                            $scope.medications = [];
                        }
                    } else {
                        NotificationService.displayNotification('warning', 'Bitte kontaktieren Sie den Support.');
                    }
                });
            };

            $scope.getDiaryEntryByDate(moment().toDate());

            $scope.setImageOfDay = function (file) {
                $scope.image = !!file ? file : $scope.nursingDiaryEntry.image;
            };

            $scope.submitDiaryEntryForm = function () {
                if ($scope.nursingDiaryEntry._id) {
                    cfNursingDiaryEntryService.updateDiaryEntry($scope.nursingDiaryEntry, $scope.image).then(
                        () => {
                            NotificationService.displayNotification('success', 'Deine Einträge wurden gespeichert');
                        },
                        err => NotificationService.displayNotification('error', err.message)
                    );
                } else {
                    cfNursingDiaryEntryService.createDiaryEntry($scope.nursingDiaryEntry, $scope.image).then(
                        ({ data: createdDiaryEntry }) => {
                            // Ensure the next click on save results in an update.
                            $scope.nursingDiaryEntry._id = createdDiaryEntry._id;

                            NotificationService.displayNotification('success', 'Pflegetagebucheintrag wurde erstellt.');
                        },
                        err => NotificationService.displayNotification('error', err.message)
                    );
                }
            };

            $scope.getNursingDiaryEditorUrl = function () {
                if ($scope.nursingDiaryEntry?.nursingDiary?._id) {
                    return `#/pflegetagebuch/editor/${$scope.nursingDiaryEntry.nursingDiary._id}${
                        $scope.nursingDiaryEntry.nursingDiary.user
                            ? `?${new URLSearchParams({ userId: $routeParams.userId })}`
                            : ''
                    }`;
                } else {
                    return `#/pflegetagebuch/editor${
                        $scope.nursingDiaryEntry?.nursingDiary?.user
                            ? `?${new URLSearchParams({
                                  userId: $routeParams.userId,
                                  startDate: moment($scope.nursingDiaryEntry.date).toDate().getTime(),
                                  endDate: moment($scope.nursingDiaryEntry.date).toDate().getTime(),
                              })}`
                            : ''
                    }`;
                }
            };
        },
    ]);
;
 
angular.module('cfNursingDiaryViewCtrl', ['cfNursingDiaryService']).controller('cfNursingDiaryViewCtrl', [
    '$scope',
    '$location',
    '$routeParams',
    'cfNursingDiaryService',
    function ($scope, $location, $routeParams, cfNursingDiaryService) {
        $scope.nursingDiaries = [];
        $scope.user = undefined;

        cfNursingDiaryService.getNursingDiaries().then(nursingDiaries => {
            $scope.nursingDiaries = nursingDiaries;
            const nursingDiaryForUser = nursingDiaries.find(
                nursingDiary => nursingDiary.user._id === $routeParams.userId
            );
            $scope.user = nursingDiaryForUser?.user;
        });

        $scope.setNursingDiaryParams = function (nursingDiary) {
            $scope.user = nursingDiary.user;
            $location.search({ userId: nursingDiary.user._id });
        };
    },
]);
;
 
angular
    .module('cfPasswordCtrl', ['cfPasswordService', 'cfProfileService', 'NotificationService'])
    .controller('cfPasswordCtrl', [
        '$scope',
        '$location',
        'cfPasswordService',
        'cfProfileService',
        'NotificationService',
        function ($scope, $location, cfPasswordService, cfProfileService, NotificationService) {
            $scope.changePasswordFormData = {
                password: '',
                user: '',
            };
            $scope.initPasswordReset = function (email, valid) {
                if (valid) {
                    cfPasswordService.initPasswordReset(email).then(
                        () => {
                            NotificationService.displayNotification('success', 'password_success');
                            $location.path('/');
                        },
                        errorMessage => {
                            NotificationService.displayNotification('error', errorMessage);
                        }
                    );
                } else {
                    $scope.passwordForm.email.$dirty = true;
                }
            };
        },
    ]);
;
 
angular
    .module('cfProfileCtrl', ['cfProfileService', 'cfFamilyProfileService', 'cfUserService', 'NotificationService'])
    .controller('cfProfileCtrl', [
        '$scope',
        '$routeParams',
        '$location',
        'cfProfileService',
        'cfFamilyProfileService',
        'usSpinnerService',
        'cfUserService',
        'NotificationService',
        function (
            $scope,
            $routeParams,
            $location,
            cfProfileService,
            cfFamilyProfileService,
            usSpinnerService,
            cfUserService,
            ns
        ) {
            $scope.colors = cfProfileService.getColors();
            $scope.icons = cfProfileService.getIcons();
            $scope.roles = cfFamilyProfileService.getRole();
            $scope.permissions = cfFamilyProfileService.getPermission();
            $scope.countryzone = cfProfileService.getCountryzone();

            $scope.countryzoneDE = cfFamilyProfileService.getCountryzoneDE();
            $scope.countryzoneAUT = cfFamilyProfileService.getCountryzoneAUT();
            $scope.countryzoneCH = cfFamilyProfileService.getCountryzoneCH();
            $scope.countries = cfFamilyProfileService.getCountry();

            $scope.showSymbols = false;
            $scope.hideSymbols = () => {
                $scope.showSymbols = false;
            };
            $scope.showColors = false;
            $scope.hideColors = () => {
                $scope.showColors = false;
            };

            cfProfileService
                .getStates()
                .then(states => ($scope.states = states))
                .catch(error => console.error('ERROR in getStates:', error));

            /***************************************************************
             * Options für Datepicker
             ***************************************************************/
            $scope.dateOptions = {
                formatYear: 'yyyy',
                startingDay: 1,
                showWeeks: false,
            };
            $scope.format = 'dd. MMMM yyyy';
            /** Format des Datepickers **/
            $scope.showDatepicker = false;
            /** Datepicker ein/aus blenden **/
            $scope.hstep = 1;
            /** Stungen werden um x verschoben **/
            $scope.mstep = 5;
            /** Minuten werden um x verschoben **/
            $scope.ismeridian = false;
            /** 12/24 h Anzeige **/

            $scope.openDatepicker = function () {
                $scope.showDatepicker = true;
            };

            $scope.cfGetProfile = function (profileId) {
                cfProfileService
                    .getProfile(profileId)
                    .then(function (data) {
                        $scope.loadedProfile = data;
                        if (data.contactData?.birthday) {
                            $scope.loadedProfile.contactData.birthday = new Date(data.contactData.birthday);
                        }
                        if ($scope.loadedProfile) {
                            if ($scope.loadedProfile.contactData?.email?.length > 0) {
                                $scope.pwVergessen = true;
                                $scope.loadedProfile.oldMail = $scope.loadedProfile.contactData.email;
                            }

                            const { color_id, icon, permission, contactData } = $scope.loadedProfile;

                            const foundColorObject = $scope.colors.find(({ value }) => value === color_id);
                            if (foundColorObject) $scope.loadedProfile.profileColor = foundColorObject.name;

                            const foundIconObject = $scope.colors.find(({ value }) => value === icon);
                            if (foundIconObject) $scope.loadedProfile.profileIconName = foundIconObject.name;

                            const foundPermissionObject = $scope.permissions.find(({ value }) => value === permission);
                            if (foundPermissionObject) $scope.profilePersmissionName = foundPermissionObject.name;

                            const foundCountryZoneObject = $scope.countryzone.find(
                                ({ value }) => value === contactData?.countryzone
                            );
                            $scope.profileCountryzone = foundCountryZoneObject?.name ?? '';
                        }
                    })
                    .catch(({ message, status }) => {
                        console.log({ message });
                        ns.displayNotification('error', message);
                        if ([401, 404].includes(status)) $location.path('/home');
                    });
            };

            $scope.cfGetProfile($routeParams._id);

            /***************************************************************
             * Erstellen eines neuen Profils
             ***************************************************************/
            $scope.cfCreateProfile = function (processedProfile) {
                usSpinnerService.spin('famcospinner');
                if (processedProfile) {
                    cfProfileService.createProfile(processedProfile).then(
                        function () {
                            usSpinnerService.stop('famcospinner');
                        },
                        function () {
                            usSpinnerService.stop('famcospinner');
                        }
                    );
                }
            };
            /***************************************************************
             * Profil bearbeiten
             ***************************************************************/
            $scope.cfUpdateProfile = function (processedProfile, validEmail) {
                if (!validEmail) ns.displayNotification('error', 'invalid_mail');
                else {
                    usSpinnerService.spin('famcospinner');
                    cfProfileService.updateProfile(processedProfile).then(
                        function (data) {
                            if (data.err) {
                                ns.displayNotification('error', 'existing_mail');
                                usSpinnerService.stop('famcospinner');
                            } else {
                                if (processedProfile.contactData.email !== processedProfile.oldMail)
                                    ns.displayNotification('success', 'profile_mail_change');
                                $scope.cfGetProfile($routeParams._id);
                                usSpinnerService.stop('famcospinner');
                                ns.displayNotification('success', 'profile_save_success');
                                cfUserService.reloadUser().then(function (user) {
                                    $scope.main.user = user;
                                    $scope.loadedProfile = $scope.main.user.family;
                                    location.reload();
                                    $location.path('/family');
                                });
                            }
                        },
                        function (data) {
                            console.error('Fehler beim erstellen', data);
                        }
                    );
                }
            };

            /***************************************************************
             * Profil bearbeiten
             ***************************************************************/
            $scope.cfDeleteProfile = function (processedProfile) {
                usSpinnerService.spin('famcospinner');
                cfProfileService.cfDeleteProfile(processedProfile._id).then(
                    function (message) {
                        usSpinnerService.stop('famcospinner');
                        ns.displayNotification('success', message);
                        if (processedProfile._id === $scope.main.user._id) {
                            cfUserService.logoutUser();
                            location.hash = '/';
                            location.reload();
                        } else {
                            cfUserService.reloadUser().then(function (user) {
                                $scope.main.user = user;
                                $scope.loadedProfile = $scope.main.user.family;
                                location.hash = '/family';
                                location.reload();
                            });
                        }
                    },
                    function (errorMessage) {
                        ns.displayNotification('error', errorMessage);
                        usSpinnerService.stop('famcospinner');
                    }
                );
            };

            $scope.cfSecureDeleteProfile = function (processedProfile) {
                $scope.deleteConfirmation({
                    resolve: {
                        title: 'Profil löschen',
                        body: 'Mit dem Löschen dieses Profils wird die dazugehörige E-Mailadresse endgültig gelöscht. Alle Einträge für dieses Familienmitglied bleiben erhalten und können von den anderen Familienmitgliedern weiter verwaltet werden. Weitere Informationen zum Löschen von Profile findest du im Bereich Fragen & Antworten. Möchtest du dieses Profil wirklich löschen?',
                    },
                    onSuccess: () => {
                        $scope.deleteConfirmation({
                            resolve: {
                                title: 'Löschen bestätigen',
                                body: 'Bitte bestätige die unwiderrufliche Löschung aller Daten dieses Familienmitglieds durch Eingabe deines Passworts. <input type="password" class="form-control" ng-model="password"  placeholder="Passwort eingeben" required current-user-password-validation />',
                            },
                            onSuccess: () => {
                                $scope.cfDeleteProfile(processedProfile);
                            },
                        });
                    },
                });
            };
        },
    ]);
;
 
angular.module('cfPurchaseCtrl', ['cfUserService']).controller('cfPurchaseCtrl', [
    '$scope',
    '$rootScope',
    'cfUserService',
    'NotificationService',
    function ($scope, $rootScope, cfUserService, NotificationService) {
        if (!$rootScope.isStoreInitilized) {
            $rootScope.isStoreInitilized = true;

            $rootScope.subscriptionProducts = [];
            document.addEventListener('deviceready', onDeviceReady, false);

            function onDeviceReady() {
                // API Reference: https://github.com/j3k0/cordova-plugin-purchase/blob/v10.6.1/doc/api.md
                const store = window.store;

                if (!store) {
                    console.error('window.store from cordova-plugin-purchase unavailable, IAP disabled');
                    return;
                }

                const unlockFeature = () => {
                    console.log('unlockFeature');
                };

                store.verbosity = store.WARNING;

                store.applicationUsername = () => {
                    const user = cfUserService.getCurrentUser();

                    // FIXME: _ is *underscore v1.8.3*, not lodash
                    if (_.isEmpty(user)) throw new Error('User not available');
                    if (!_.isString(user._id)) throw new Error(`Unexpected user ID type: ${typeof user._id}`);
                    if (_.isEmpty(user._id)) throw new Error(`Empty user ID: ${user._id}`);

                    return user._id;
                };

                store.register([
                    {
                        id: 'subscription_family_monthly',
                        type: store.PAID_SUBSCRIPTION,
                    },
                    {
                        id: 'subscription_family_yearly',
                        type: store.PAID_SUBSCRIPTION,
                    },
                ]);

                store.error(error => {
                    console.error('Store error:', error);
                });

                const loaded = product => {
                    //Called when [product](#product) data is loaded from the store.
                    console.info('loaded', { product });
                };
                const updated = product => {
                    //Called when any change occurred to a product.
                    console.info('updated', { product });

                    const subscriptionFamilyMonthly = store.get('subscription_family_monthly');
                    const subscriptionFamilyYearly = store.get('subscription_family_yearly');

                    $rootScope.subscriptionProducts = [subscriptionFamilyMonthly, subscriptionFamilyYearly];
                    $rootScope.$digest();
                };
                const approved = product => {
                    //Called when a product [order](#order) is approved.
                    console.info('approved', { product });

                    product.verify();
                };
                const owned = product => {
                    //Called when a non-consumable product or subscription is owned.
                    console.info('owned', { product });

                    cfUserService.getCurrentUserFromDB().catch(console.error);
                };
                const cancelled = product => {
                    //Called when a product [order](#order) is cancelled by the user.
                    console.info('cancelled', { product });
                };
                const refunded = product => {
                    //Called when an order is refunded by the user.
                    console.info('refunded', { product });
                };
                const verified = product => {
                    //Called when receipt validation successful
                    console.info('verified', { product });

                    unlockFeature();
                    product.finish();
                };
                const unverified = product => {
                    //Called when receipt verification failed
                    console.info('unverified', { product });
                };
                const expired = product => {
                    //Called when validation find a subscription to be expired
                    console.info('expired', { product });
                };
                const downloading = (product, progress, time_remaining) => {
                    //Called when content download is started
                    console.info('downloading', { product, progress, time_remaining });
                };
                const downloaded = product => {
                    //Called when content download has successfully completed
                    console.info('downloaded', { product });
                };
                store
                    .when('subscription')
                    .loaded(loaded)
                    .updated(updated)
                    .approved(approved)
                    .owned(owned)
                    .cancelled(cancelled)
                    .refunded(refunded)
                    .verified(verified)
                    .unverified(unverified)
                    .expired(expired)
                    .downloading(downloading)
                    .downloaded(downloaded);

                store.validator =
                    'https://validator.fovea.cc/v1/validate?appName=de.familycockpit.mobile&apiKey=8e008158-c5b3-47c7-8eff-8829ab66c85f';

                store.refresh();
            }
        } else {
            window.store.update();
        }

        $scope.handleProductClick = product => {
            const canPurchaseNewSubscription = $scope.main.user.family.access.canPurchaseNewSubscription;

            const currentUserOwnsAnySubscription = $scope.subscriptionProducts.some(product => product.owned);

            if (canPurchaseNewSubscription) {
                if (product.canPurchase) {
                    $scope.orderProduct(product);
                } else {
                    $scope.manageSubscriptions();
                }
            } else {
                if (currentUserOwnsAnySubscription) {
                    if (product.owned) {
                        $scope.manageSubscriptions();
                    } else {
                        NotificationService.displayNotification(
                            'warning',
                            'Ein Wechsel zwischen den Abonnements ist nur durch vollständige Kündigung des bestehenden Abonnements möglich.'
                        );
                    }
                } else {
                    NotificationService.displayNotification(
                        'warning',
                        'Du kannst aktuell kein neues Abonnement abschließen, da bereits ein aktives Abonnement existiert.'
                    );
                }
            }
        };

        $scope.manageSubscriptions = () => {
            window.store.manageSubscriptions();
        };

        $scope.manageBilling = () => {
            window.store.manageBilling();
        };

        $scope.orderProduct = product => {
            console.log('orderProduct', product);
            window.store.order(product.id);
        };

        $scope.displayProductState = state => {
            switch (state) {
                case window.store.REGISTERED:
                    return 'registriert';
                case window.store.INVALID:
                    return 'ungültig';
                case window.store.VALID:
                    return 'nicht aktiv';
                case window.store.REQUESTED:
                    return 'angefragt';
                case window.store.INITIATED:
                    return 'initiiert';
                case window.store.APPROVED:
                    return 'bestätigt';
                case window.store.FINISHED:
                    return 'abgeschlossen';
                case window.store.OWNED:
                    return 'aktiv';
                case window.store.DOWNLOADING:
                    return 'wird geladen';
                case window.store.DOWNLOADED:
                    return 'Ladevorgang abgeschlossen';
                default:
                    return 'unbekannter Status';
            }
        };

        function translateBillingPeriodUnit(billingPeriodUnit) {
            switch (billingPeriodUnit) {
                case 'Minute':
                    return 'Minute';
                case 'Hour':
                    return 'Stunde';
                case 'Day':
                    return 'Tag';
                case 'Week':
                    return 'Woche';
                case 'Month':
                    return 'Monat';
                case 'Year':
                    return 'Jahr';
                default:
                    console.warn('Unexpected billingPeriodUnit', billingPeriodUnit);
                    return 'unbekannter Rhythmus';
            }
        }

        $scope.displayPricePerPeriod = product => {
            return `für ${(product.priceMicros / 10 ** 6)
                .toFixed(2)
                .replace('.', ',')} € / ${translateBillingPeriodUnit(product.billingPeriodUnit)}`;
        };
    },
]);
;
 
angular
    .module('cfRegistrationCtrl', ['cfRegistrationService', 'NotificationService'])
    .controller('cfRegistrationCtrl', [
        '$scope',
        '$location',
        '$uibModal',
        'cfRegistrationService',
        'NotificationService',
        function ($scope, $location, $uibModal, cfRegistrationService, NotificationService) {
            $scope.registrationFormData = {
                gender: 'female',
                firstName: '',
                lastName: '',
                emailConfirmation: '',
                password: '',
                ageConfirmed: false,
                termsAndPolicyConfirmed: false,
                healthDataConfirmed: false,
            };

            $scope.validateEmailConfirmation = (email, emailConfirmation) => {
                $scope.registrationForm.emailConfirmation.$setValidity('emailConfirmation', true);

                if (email !== emailConfirmation) {
                    $scope.registrationForm.emailConfirmation.$setValidity('emailConfirmation', false);
                }
            };

            $scope.submitRegistrationForm = () => {
                const registrationFormData = {
                    gender: $scope.registrationFormData.gender,
                    firstName: $scope.registrationFormData.firstName,
                    lastName: $scope.registrationFormData.lastName,
                    email: $scope.registrationFormData.email,
                    password: $scope.registrationFormData.password,
                    code: $scope.registrationFormData.code,
                };
                cfRegistrationService
                    .submitRegistrationFormData(registrationFormData)
                    .then(() => {
                        if ($scope.registrationFormData.code) {
                            $location.path('/registrieren/zdg/information');
                        } else {
                            const modalInstance = $uibModal.open({
                                templateUrl: 'html/modals/emailVerificationModal.html',
                                controller: 'cfModalCtrl',
                                size: 'sm',
                            });

                            modalInstance.result.then(() => $location.path('/'));
                        }
                    })
                    .catch(err => {
                        console.error('submitRegistrationForm err', err);
                        if (err.data?.message === 'invitation_error_duplicate_mail') {
                            $scope.openInvitationInformationModal();
                        } else {
                            NotificationService.displayNotification(
                                'error',
                                err.data?.message || 'registration_error_server'
                            );
                        }
                    });
            };

            $scope.openInvitationInformationModal = () => {
                const modalInstance = $uibModal.open({
                    templateUrl: 'html/modals/invitationInformationModal.html',
                    controller: 'cfModalCtrl',
                    size: 'sm',
                });

                modalInstance.result.then(() => $location.path('/'));
            };

            $scope.openInformationModal = () => {
                const modalInstance = $uibModal.open({
                    templateUrl: 'html/modals/emailVerificationModal.html',
                    controller: 'cfModalCtrl',
                    size: 'sm',
                });

                modalInstance.result.then(() => $location.path('/'));
            };
        },
    ]);
;
 
angular.module('cfShareCtrl', ['cfShareService', 'cfNursingDiaryDocumentationService']).controller('cfShareCtrl', [
    '$scope',
    '$routeParams',
    'cfShareService',
    'cfNursingDiaryDocumentationService',

    function ($scope, $routeParams, cfShareService, cfNursingDiaryDocumentationService) {
        $scope.share = {};

        $scope.nursingDiaryDocumentationList = [];

        $scope.options = [
            { value: 'sleep', label: 'Schlaf', icon: 'images/icons/svg/sleep.svg' },
            { value: 'food', label: 'Essen', icon: 'images/icons/svg/food.svg' },
            { value: 'hydration', label: 'Trinken', icon: 'images/icons/svg/hydration.svg' },
            { value: 'mood', label: 'Stimmung', icon: 'images/icons/svg/mood.svg' },
            { value: 'activities', label: 'Aktivitäten', icon: 'images/icons/svg/activity.svg' },
            { value: 'medication', label: 'Medikation', icon: 'images/icons/svg/medication.svg' },
        ];
        $scope.filteredOptions = [];

        cfShareService.getShare($routeParams.shareId).then(share => {
            $scope.filteredOptions = $scope.options.filter(option => option.value === share.category);

            $scope.share = share;
            cfNursingDiaryDocumentationService
                .getNursingDiaryDocumentationEntries({ ...share, user: share.user._id })
                .then(nursingDiaryEntries => {
                    if (nursingDiaryEntries.length) {
                        const category = $scope.share.category;

                        if (['sleep', 'food', 'hydration', 'mood'].includes(category)) {
                            const filteredNursingDiaryEntries = nursingDiaryEntries.filter(nursingDiaryEntry => {
                                return !!nursingDiaryEntry.categories[category].sentiment;
                            });
                            $scope.nursingDiaryDocumentationList = filteredNursingDiaryEntries.map(
                                nursingDiaryEntry => {
                                    return {
                                        nursingDiaryEntryDate: nursingDiaryEntry.date,
                                        data: [
                                            {
                                                label: getSentimentLabel(
                                                    nursingDiaryEntry.categories[category].sentiment
                                                ),
                                                description: nursingDiaryEntry.categories[category].note,
                                                options: [],
                                            },
                                        ],
                                    };
                                }
                            );
                        } else if ('activities' === category) {
                            const filteredNursingDiaryEntries = nursingDiaryEntries.filter(nursingDiaryEntry => {
                                return !!nursingDiaryEntry[category]?.completed;
                            });
                            $scope.nursingDiaryDocumentationList = filteredNursingDiaryEntries.map(
                                nursingDiaryEntry => {
                                    return {
                                        nursingDiaryEntryDate: nursingDiaryEntry.date,
                                        data: [
                                            {
                                                label: '',
                                                description: '',
                                                options: _.zip(
                                                    nursingDiaryEntry.nursingDiary.configuration[category],
                                                    nursingDiaryEntry[category].completed
                                                ),
                                            },
                                        ],
                                    };
                                }
                            );
                        } else if ('medication' === category) {
                            const filteredNursingDiaryEntries = nursingDiaryEntries.filter(nursingDiaryEntry => {
                                return !!nursingDiaryEntry[category].length;
                            });

                            $scope.nursingDiaryDocumentationList = filteredNursingDiaryEntries.map(
                                nursingDiaryEntry => {
                                    return {
                                        nursingDiaryEntryDate: nursingDiaryEntry.date,
                                        data: nursingDiaryEntry[category].map((medicationObject, index) => {
                                            return {
                                                label: nursingDiaryEntry.nursingDiary.configuration[category][index]
                                                    .name,
                                                description:
                                                    nursingDiaryEntry.nursingDiary.configuration[category][index]
                                                        .description,
                                                options: Object.entries(medicationObject),
                                            };
                                        }),
                                    };
                                }
                            );
                        } else {
                            $scope.nursingDiaryDocumentationList = [];
                        }
                    } else {
                        $scope.nursingDiaryDocumentationList = [];
                    }
                })
                .catch(console.error);
        });

        function getSentimentLabel(sentiment) {
            switch (sentiment) {
                case 'BAD':
                    return 'zu wenig';
                case 'MEDIUM':
                    return 'ausreichend';
                case 'GOOD':
                    return 'gut';
                default:
                    return '';
            }
        }
    },
]);
;
 
angular.module('cfSubscriptionCtrl', []).controller('cfSubscriptionCtrl', ['$scope', function ($scope) {}]);
;
 
angular.module('cfWishlistCtrl', ['cfWishlistService']).controller('cfWishlistCtrl', [
    '$scope',
    'cfWishlistService',
    '$location',
    function ($scope, cfWishlistService, $location) {
        $scope.editWishlistForm = false;
        $scope.createWishlistForm = false;
        $scope.wishName = '';

        $scope.openCreateForm = function () {
            $scope.createWishlistForm = true;
            $scope.processedWishlist = {};
        };
        $scope.openEditForm = function () {
            $scope.editWishlistForm = true;
        };

        $scope.leaveForm = function () {
            $scope.createWishlistForm = false;
            $scope.editWishlistForm = false;
            $scope.processedWishlist = { wishes: [] };
        };

        $scope.cfCreateWishlist = function (processedWishlist) {
            if (processedWishlist) {
                cfWishlistService.createWishlist(processedWishlist).then(
                    function (data) {},
                    function (data) {
                        console.error('Fehler beim erstellen', data);
                    }
                );
            }
        };

        $scope.cfUpdateWishlist = function (processedWishlist) {
            if ($scope.editWishlistForm) {
                cfWishlistService.updateWishlist(processedWishlist).then(
                    function () {
                        $scope.leaveForm();
                        $scope.cfGetWishlists();
                    },
                    function (data) {
                        console.error('Fehler beim erstellen', data);
                    }
                );
            }
        };

        $scope.cfGetWishlistDetails = function (wishlist) {
            $location.path('/wunschlistendetail/' + wishlist._id);
        };

        $scope.cfGetWishlists = function () {
            cfWishlistService.getWishlists().then(function (data) {
                $scope.loadedWishlists = data;
            });
        };
        $scope.cfGetWishlists();

        $scope.cfGetDistributors = function () {
            cfWishlistService.getDistributors().then(
                function (data) {
                    $scope.loadedDistributors = data;
                },
                function (reason) {
                    console.error(reason);
                }
            );
            $location.path('/wunschlistendetail/' + wishlist._id + '/verteiler');
        };

        $scope.cfDeleteWishlist = function (wishlistId) {
            cfWishlistService.deleteWishlist(wishlistId).then(
                function () {
                    $scope.cfGetWishlists();
                },
                function () {}
            );
        };

        $scope.cfCreateWish = function (listId, wishName) {
            cfWishlistService.cfCreateWish(listId, wishName).then(
                function () {
                    $scope.cfGetListById(listId);
                    $scope.wishName = '';
                },
                function () {}
            );
        };
    },
]);
;
 
angular
    .module('cfWishlistDetailCtrl', ['cfWishlistService', 'cfNewDistributorService'])
    .controller('cfWishlistDetailCtrl', [
        '$scope',
        'cfWishlistService',
        'cfNewDistributorService',
        '$location',
        '$routeParams',
        function ($scope, cfWishlistService, cfNewDistributorService, $location, $routeParams) {
            //ToDo Ctrl aufräumen + Kommentieren Burhan
            $scope.editWishlistForm = false;
            $scope.createWishlistForm = false;
            //$scope.processedWishlist = {wishes: []};

            //$scope.filterOwner = "";
            $scope.wishName = '';
            $scope.addmail = '';

            $scope.toggleDistributor = false;
            $scope.toggleMail = false;
            $scope.toggleDetail = true;

            $scope.distributorIds = [];

            $scope.openCreateForm = function () {
                $scope.createWishlistForm = true;
                $scope.processedWishlist = {};
            };
            $scope.openEditForm = function () {
                $scope.editWishlistForm = true;
                //$scope.processedWishlist = {};
            };

            $scope.leaveForm = function () {
                $scope.createWishlistForm = false;
                $scope.editWishlistForm = false;
                $scope.processedWishlist = { wishes: [] };
            };

            $scope.cfUpdateWishlist = function (processedWishlist) {
                if ($scope.editWishlistForm) {
                    cfWishlistService.updateWishlist(processedWishlist).then(
                        function () {
                            $scope.leaveForm();
                            $scope.cfGetWishlistById(processedWishlist._id);
                        },
                        function (data) {
                            console.error('Fehler beim erstellen', data);
                        }
                    );
                }
            };

            $scope.cfGetWishlistById = function (wishlist) {
                $scope.editWishlistForm = true;
                cfWishlistService.getWishlistById(wishlist).then(function (data) {
                    $scope.processedWishlist = data;
                });
            };
            $scope.cfGetWishlistById($routeParams._id);

            $scope.cfGetDistributors = function () {
                cfWishlistService.getDistributors().then(
                    function (data) {
                        $scope.loadedDistributors = data;
                    },
                    function () {}
                );
            };

            $scope.cfDeleteWishlist = function (wishlistId) {
                cfWishlistService.deleteWishlist(wishlistId).then(
                    function () {
                        $scope.cfGetWishlists();
                    },
                    function () {}
                );
            };

            $scope.cfCreateWish = function (listId, wishName) {
                cfWishlistService.createWish(listId, wishName).then(
                    function () {
                        $scope.cfGetWishlistById(listId);
                        $scope.wishName = '';
                    },
                    function () {}
                );
            };

            /***************************************************************
             * Funktionen um die Sichtbarkeit der 3 Seiten durch zu Schalten
             ***************************************************************/
            $scope.showDistributor = function () {
                $scope.toggleDistributor = true;
                $scope.toggleMail = false;
                $scope.toggleDetail = false;
                $scope.toggleCreateMail = false;
            };
            $scope.showMail = function () {
                $scope.toggleMail = true;
                $scope.toggleDetail = false;
                $scope.toggleDistributor = false;
                $scope.toggleCreateMail = false;
            };
            $scope.showDetail = function () {
                $scope.toggleDetail = true;
                $scope.toggleDistributor = false;
                $scope.toggleMail = false;
                $scope.toggleCreateMail = false;
            };
            $scope.showCreateMail = function () {
                $scope.toggleCreateMail = true;
                $scope.toggleDetail = false;
                $scope.toggleDistributor = false;
                $scope.toggleMail = false;
            };

            /***************************************************************
             * Läd Emails des Distributors
             ***************************************************************/
            $scope.cfgetEmailList = function (distributorIds) {
                cfWishlistService.getEmailList(distributorIds).then(
                    function (data) {
                        $scope.emailList = data;
                    },
                    function (err) {
                        console.error(err);
                    }
                );
            };

            $scope.setprocessedDistributor = function (processedDistributor) {
                $scope.processedDistributor = processedDistributor;
            };

            /***************************************************************
             * Erstellt eine neue Mailadress im Verteiler
             ***************************************************************/
            $scope.cfCreateDistributorMail = function (processedId, processedMail) {
                $scope.addmail = '';
                cfNewDistributorService.createDistributorMail(processedId, processedMail).then(function () {
                    $scope.cfgetEmailList(processedId);
                });
            };

            $scope.cfGetDistributorById = function (processedId) {
                cfNewDistributorService.getDistributorById(processedId).then(function (data) {
                    $scope.distributor = data;
                });
            };

            /***************************************************************
             * Überprüft ob Verteilergruppe ausgewählt
             ***************************************************************/
            $scope.toggleChecked = function (distributor) {
                if (distributor.class === 'checked') {
                    distributor.class = '';
                    $scope.distributorIds = $scope.distributorIds.filter(function (id) {
                        return !(id === distributor._id);
                    });
                } else {
                    distributor.class = 'checked';
                    $scope.distributorIds.push(distributor._id);
                }
            };

            /***************************************************************
             * Toggelt Editfeld für Emailadressen
             ***************************************************************/
            $scope.changeEdit = function (task) {
                $scope.processedList.tasks.forEach(function (task) {
                    task.edit = false;
                });
                if (task) task.edit = !task.edit;
            };
        },
    ]);
;
 
angular
    .module('changePasswordSubmitCtrl', ['cfPasswordService', 'cfProfileService', 'NotificationService'])
    .controller('changePasswordSubmitCtrl', [
        '$scope',
        '$location',
        'cfPasswordService',
        'cfProfileService',
        'NotificationService',
        function ($scope, $location, cfPasswordService, cfProfileService, NotificationService) {
            $scope.changePasswordFormData = {
                password: '',
                user: '',
            };

            $scope.submitChangePasswordForm = () => {
                cfProfileService
                    .cfChangePassword({
                        currentPassword: $scope.changePasswordFormData.currentPassword,
                        newPassword: $scope.changePasswordFormData.password,
                        userId: $scope.main.user._id,
                    })
                    .then(
                        () => {
                            $location.path('profil/' + $scope.main.user._id);
                            NotificationService.displayNotification('success', 'profile_save_success');
                        },
                        err => {
                            console.error(err);
                            NotificationService.displayNotification(
                                'warning',
                                err.data?.message || 'profile_password_false'
                            );
                        }
                    );
            };
        },
    ]);
;
 
//***********************************************************
//######################  MainCtrl.js #######################
//######### erstellt am  16.03.2016 von Immanuel Boss #######
//***********************************************************
angular
    .module('MainCtrl', ['cfUserService', 'cfFamilyService', 'mobileAppService', 'cfSelectedTimeService'])
    .controller('MainCtrl', [
        '$scope',
        '$window',
        '$routeParams',
        '$location',
        'cfUserService',
        '$rootScope',
        'mobileAppService',
        'cfSelectedTimeService',
        '$uibModal',
        function (
            $scope,
            $window,
            $routeParams,
            $location,
            cfUserService,
            $rootScope,
            mobileAppService,
            cfSelectedTimeService,
            $uibModal
        ) {
            $scope.currentPlatform = window.cordova?.platformId?.toLowerCase() || 'browser';
            $scope.isNativeApp = !!window.cordova;

            if ($scope.isNativeApp) {
                document.addEventListener('resume', onResume, false);

                function onResume() {
                    // Reload the subscription status/access level whenever the app is re-opened.
                    cfUserService.getCurrentUserFromDB().catch(console.error);
                }
            }

            /******************************************
             * Setze baseRoute für hrefs abhängig davon,
             * ob cordova verfügbar ist.
             ******************************************/
            $scope.nutzung = false;
            $scope.datenschutz = false;
            $scope.impressum = false;

            $scope.$on('$locationChangeStart', function () {
                $scope.faq =
                    $location.path() === '/faq' ||
                    $location.path().includes('/faqantworten') ||
                    $location.path() === '/hilfe' ||
                    $location.path() === '/version' ||
                    $location.path() === '/rechtliches';
            });

            /******************************************
             * Wird benutzt um einen css ID Selektor zu erstellen.
             * Diese selektiert den aktuellen Tag in der Monatsansicht des Kalenders
             ******************************************/
            var dayKey = moment().format('DD-MM-YY');
            $scope.calenderDayKey = '#\\3' + dayKey.substring(0, 1) + ' ' + dayKey.substring(1);

            $scope.toggleNutzung = function () {
                $scope.nutzung = true;
                $scope.datenschutz = false;
                $scope.impressum = false;
            };
            $scope.toggleDatenschutz = function () {
                $scope.nutzung = false;
                $scope.datenschutz = true;
                $scope.impressum = false;
            };

            $scope.toggleImpressum = function () {
                $scope.nutzung = false;
                $scope.datenschutz = false;
                $scope.impressum = true;
            };

            $scope.baseRoute = '';

            if (!window.cordova) $scope.baseRoute = mobileAppService.htmlLoc;
            else $scope.baseRoute = mobileAppService.hashLoc;
            $scope.dynamicLoc = mobileAppService.dynamicLoc;

            $scope.activeFilter = false;
            $scope.activeMember = [];
            $scope.main = {
                user: cfUserService.getCurrentUser(),
                loadedLists: [],
                filteredLists: [],
                loadedNotes: [],
                filteredNotes: [],
                familyFilter: [],
                loginSuccess: false,
                currentUser: {},
            };

            var options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                showWeeks: false,
                formatYear: 'yyyy',
            };

            $scope.date = new Date().toLocaleDateString('de-DE', options);

            $scope.$on('$routeChangeStart', function () {
                $scope.date = new Date().toLocaleDateString('de-DE', options);
                $scope.scrollToTop();
            });

            $scope.scrollToTop = function () {
                window.scrollTo(0, 0);
            };

            $scope.initializeWindowSize = function () {
                var windowWidth = $window.innerWidth;
                if (windowWidth < 500) {
                    $scope.$on('$routeChangeStart', function () {
                        $scope.toggle.sidebar_wrapper = false;
                    });
                    $scope.toggle = {
                        sidebar_wrapper: false,
                        wrapper: false,
                        menu_list_was: true,
                        menu_list_wer: false,
                        menu_list_wie: false,
                        new_member: false,
                        home_list_einkaufslisten: true,
                        home_list_notes: true,
                        home_list_aufgaben: true,
                        home_list_checklisten: true,
                    };
                } else {
                    $scope.toggle = {
                        sidebar_wrapper: true,
                        wrapper: true,
                        menu_list_was: true,
                        menu_list_wer: false,
                        menu_list_wie: false,
                        new_member: false,
                        home_list_einkaufslisten: true,
                        home_list_notes: true,
                        home_list_aufgaben: true,
                        home_list_checklisten: true,
                    };
                }
            };
            $scope.initializeWindowSize();
            window.onresize = $scope.initializeWindowSize;

            $scope.getColor = function (owner) {
                $scope.familyColor = 'fc-icon-' + owner.icon + ' fc-color-' + owner.color_id;
                return $scope.familyColor;
            };
            $scope.getFamilyColor = function (family) {
                $scope.familyColor = 'fc-familie fc-color-' + family;
                return $scope.familyColor;
            };

            /**************************************************************************************************
             * Beendet die Session und routet zum login Screen
             **************************************************************************************************/
            $scope.logoutUser = function () {
                cfUserService.logoutUser().then(
                    function () {
                        $location.path('/');
                    },
                    function (data) {
                        //ToDo: Hier müsste noch eine Fehlermeldung ausgegeben werden.
                    }
                );
            };

            $scope.setSelectedTab = function (urlPath) {
                if ($location.path().indexOf(urlPath) > -1) {
                    return 'selectedTab';
                } else {
                    return '';
                }
            };

            $scope.changeSelectedTime = function (time) {
                cfSelectedTimeService.selectedDate.setTime(time);
            };

            $scope.toggleMobileMenu = function () {
                var windowWidth = $window.innerWidth;
                if (windowWidth <= 570) {
                    $scope.toggle.sidebar_wrapper = $scope.toggle.sidebar_wrapper ? false : false;
                    $scope.toggle.wrapper = $scope.toggle.wrapper ? false : false;
                }
            };

            $scope.deleteConfirmation = ({
                templateUrl = 'html/modals/secure/deleteConfirmationModal.html',
                controller = 'cfDeleteConfirmationCtrl',
                size = 'sm',
                resolve = {
                    title: 'Liste löschen',
                    body: 'Möchtest du diese Liste löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
                },
                onSuccess = () => {},
                onCancel = () => {},
            }) => {
                const modalInstance = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl,
                    controller,
                    size,
                    scope: $scope,
                    resolve: { resolve },
                });
                modalInstance.result.then(onSuccess, onCancel);
            };

            $scope.goBack = function () {
                window.history.back();
            };

            $scope.toggleSidebar = () => {
                $scope.toggle.sidebar_wrapper = !$scope.toggle.sidebar_wrapper;
                $scope.toggle.wrapper = !$scope.toggle.wrapper;
            };
            $scope.listView = true;
            $scope.toggleListView = state => {
                if (typeof state == 'boolean') {
                    $scope.listView = state;
                } else {
                    $scope.listView = !$scope.listView;
                }
            };
        },
    ]);
;
 
// Directives.All.js ----------------------------------------------------------
angular.module('Directives.all', [
    'compile',
    'currentUserPasswordValidation',
    'multiSelectWeek',
    'multiSelectUser',
    'multiSelectDistributor',
    'focusOnShow',
    'ngFileUpload',
    'errSrc',
    'memberSelector',
    'familyRow',
    'materialCalendar',
    'ngRoute',
    'ui.bootstrap',
    'ui-notification',
    'userIcons',
    'angularSpinner',
    'dailyEvent',
    'luegg.directives',
    'compareInputs',
    'checklist-model',
    'navbar',
    'nursingEntryDatePicker',
    'header',
    'validateCoupon',
    'passwordInputWithConfirmation',
    'nursingDiaryCategorySelector',
    'imageOfDaySelector',
    'nursingDiaryRadioPicker',
    'nursingDiaryCheckboxPicker',
    'nursingDiaryEditorCategory',
    'nursingDocumentationNavBar',
]);
;
 
angular.module('compareInputs', []).directive('compareInputs', [
    '$http',
    function ($http) {
        return {
            require: 'ngModel',
            scope: {
                otherModelValue: '=compareInputs',
            },
            link: function (scope, element, attributes, ngModel) {
                ngModel.$validators.compareInputs = function (modelValue) {
                    var formName = element.closest('form').name;

                    if (modelValue == scope.otherModelValue) {
                        ngModel.$setValidity(formName + '.$valid', true);
                        ngModel.$setValidity(formName + '.$valid', true);
                        ngModel.$setValidity('match', true);
                        return true;
                    } else {
                        ngModel.$setValidity(formName + '.$valid', false);
                        ngModel.$setValidity(formName + '.$valid', false);
                        ngModel.$setValidity('match', false);
                        return false;
                    }
                };

                scope.$watch('otherModelValue', function () {
                    ngModel.$validate();
                });
            },
        };
    },
]);
;
 
angular.module('compile', []).directive('compile', [
    '$compile',
    function ($compile) {
        return function (scope, element, attrs) {
            scope.$watch(
                function (scope) {
                    // watch the 'compile' expression for changes
                    return scope.$eval(attrs.compile);
                },
                function (value) {
                    // when the 'compile' expression changes
                    // assign it into the current DOM
                    element.html(value);

                    // compile the new DOM and link it to the current
                    // scope.
                    // NOTE: we only compile .childNodes so that
                    // we don't get into infinite loop compiling ourselves
                    $compile(element.contents())(scope);
                }
            );
        };
    },
]);
;
 
angular
    .module('currentUserPasswordValidation', ['cfUserService', 'mobileAppService'])
    .directive('currentUserPasswordValidation', [
        '$http',
        '$q',
        'cfUserService',
        'mobileAppService',
        function ($http, $q, cfUserService, mobileAppService) {
            return {
                require: 'ngModel',
                link: (scope, element, attr, ngModel) => {
                    ngModel.$asyncValidators.currentUserPasswordValidation = modelValue => {
                        if (modelValue.length < 7) return new Promise((resolve, reject) => reject());
                        return $http
                            .get(`${mobileAppService.staticLoc}/api/frontend/checkPassword`, {
                                params: {
                                    userId: scope.main.user._id,
                                    pwToCheck: modelValue,
                                },
                            })
                            .then(({ data }) => {
                                if (!data) return $q.reject();
                                return data;
                            });
                    };
                },
            };
        },
    ]);
;
 
angular.module('dailyEvent', ['mobileAppService']).directive('dailyEvent', [
    'mobileAppService',
    function (mobileAppService) {
        return {
            restrict: 'EA',
            replace: false,
            scope: {
                dailyEvent: '=',
                slots: '=',
                slot: '=',
            },
            templateUrl: 'html/Direktives/dailyEvent.html',
            link: function (scope, element, attr, ngModel) {
                scope.dynamicLoc = mobileAppService.dynamicLoc;

                /***************************************************************
                 * Liefert Zeit zwischen Startzeit Termin und 00:00 in Sekunden
                 ***************************************************************/
                var getStartTimeInt = function (element) {
                    var start = moment(element.start_date);
                    return start.hour() * 60 * 60 + start.minute() * 60;
                };

                /***************************************************************
                 * Liefert Zeit zwischen Endzeit Termin und 00:00 in Sekunden
                 ***************************************************************/
                var getEndTimeInt = function (element) {
                    var end = moment(element.end_date);
                    return end.hour() * 60 * 60 + end.minute() * 60;
                    // return moment(element.end_date).getTime();
                };

                /***************************************************************
                 * Ermittelt Top Abstand eines Events
                 ***************************************************************/
                scope.getTopPosition = function (event) {
                    return Math.round((32 * getStartTimeInt(event)) / 3600);
                };

                /***************************************************************
                 * Ermittelt Höhe eines Events
                 ***************************************************************/
                scope.getHeight = function (event) {
                    var dauer = getEndTimeInt(event) - getStartTimeInt(event);
                    return (32 * dauer) / 3600;
                };

                /***************************************************************
                 * Ermittelt Left Abstand eines Events
                 ***************************************************************/
                scope.getLeftPosition = function (event) {
                    return Math.floor((scope.slot * 1000) / scope.slots) / 10;
                };

                /***************************************************************
                 * Ermittelt Breite eines Events in %
                 ***************************************************************/
                scope.getWidth = function () {
                    return Math.floor(1000 / scope.slots) / 10;
                };

                /***************************************************************
                 * Ermittelt farbklasse eines Events
                 ***************************************************************/

                scope.getColorCode = function (event) {
                    var prefix = 'fc-color-';
                    if (event.owner && event.owner.length == 1) {
                        return prefix + event.owner[0].color_id;
                    } else if (event.owner && event.owner.length > 1) {
                        return prefix + 1;
                    } else {
                        var familycolor = event.family.color_id;
                        return prefix + familycolor;
                    }
                };

                scope.refreshView = function () {
                    scope.event = angular.copy(scope.dailyEvent);
                    scope.eventStyle = {};
                    scope.eventStyle.height = scope.getHeight(scope.event) + 'px';
                    scope.eventStyle.top = scope.getTopPosition(scope.event) + 'px';
                    scope.eventStyle.left = scope.getLeftPosition() + '%';
                    scope.eventStyle.width = scope.getWidth() + '%';
                    scope.colorClass = scope.getColorCode(scope.event);
                };
                scope.refreshView();

                scope.$watch('slot', scope.refreshView);
                scope.$watch('slots', scope.refreshView);
                scope.$watch('dailyEvent', scope.refreshView);
            },
        };
    },
]);
;
 
/**
 * Nimmt ein Ersatzbild falls Normales nicht gefunden wurde
 */
angular.module('errSrc', []).directive('errSrc', function () {
    return {
        link: function (scope, element, attrs) {
            element.bind('error', function () {
                if (attrs.src != attrs.errSrc) {
                    attrs.$set('src', attrs.errSrc);
                }
            });
            attrs.$observe('ngSrc', function (value) {
                if (!value && attrs.errSrc) {
                    attrs.$set('src', attrs.errSrc);
                }
            });
        },
    };
});
;
 
angular.module('familyRow', ['cfUserService']).directive('familyRow', [
    'cfUserService',
    '$rootScope',
    function (cfUserService, $rootScope) {
        return {
            restrict: 'EA',
            replace: false,
            scope: {
                user: '<',
            },
            require: 'ngModel',
            templateUrl: 'html/Direktives/familyRow.html',
            link: function (scope, element, attr, ngModel) {
                scope.$watch('user', user => {
                    scope.family = angular.copy(user.family);
                    scope.family.selected = true;
                });

                scope.toggle = member => {
                    if (!member.familyName) {
                        scope.family.selected = false;
                        member.selected = !member.selected;
                    } else
                        scope.family.familyMembers.forEach(member => {
                            member.selected = false;
                            scope.family.selected = true;
                        });

                    let newModel = [];
                    if (!scope.family.selected) {
                        newModel = scope.family.familyMembers.filter(m => m.selected).map(m => m._id);
                    }

                    if (scope.family.selected || newModel.length < 1) {
                        newModel = [];
                        scope.family.selected = true;
                    }

                    ngModel.$setViewValue(newModel);
                    $rootScope.$broadcast('FamilyFilter');
                };
            },
        };
    },
]);
;
 
angular.module('focusOnShow', []).directive('focusOnShow', function ($timeout) {
    return {
        restrict: 'EA',
        link: function ($scope, $element, $attr) {
            if ($attr.ngShow) {
                $scope.$watch($attr.ngShow, function (newValue) {
                    if (newValue) {
                        $timeout(function () {
                            $element[0].focus();
                        }, 0);
                    }
                });
            }
            if ($attr.ngHide) {
                $scope.$watch($attr.ngHide, function (newValue) {
                    if (!newValue) {
                        $timeout(function () {
                            $element[0].focus();
                        }, 0);
                    }
                });
            }
        },
    };
});
;
 
angular.module('header', []).directive('header', function () {
    return {
        restrict: 'E',
        templateUrl: 'html/Direktives/header.html',
        replace: true,
    };
});
;
 
angular.module('imageOfDaySelector', []).directive('imageOfDaySelector', function () {
    return {
        restrict: 'E',
        scope: { file: '=ngModel', onSelect: '&ngChange', disabled: '<' },
        link: function (scope) {
            scope.hasFile = input => !!input;
            scope.imageSrc = input => {
                if (typeof input === 'object') {
                    return input;
                } else {
                    return `https://familycockpit.blob.core.windows.net/diaries/${input}`;
                }
            };
        },
        templateUrl: 'html/Direktives/imageOfDaySelector.html',
        replace: true,
    };
});
;
 
angular.module('memberSelector', ['cfUserService']).directive('memberSelector', [
    'cfUserService',
    function (cfUserService) {
        return {
            restrict: 'EA',
            replace: false,
            scope: {
                selectedNameList: '=',
                selectedIdList: '=',
                show: '=',
                withEmailOnly: '=',
                identifier: '@',
                withoutFamily: '=',
                singleSelectionMode: '=',
            },
            require: 'ngModel',
            templateUrl: 'html/Direktives/memberSelector.html',
            link: function (scope, element, attr, ngModel) {
                /***************************************************************
                 * Lade und Sortiere alle Familienmitglieder der Familie
                 ***************************************************************/
                scope.familyMembers = angular.copy(cfUserService.getFamilyMembers());
                scope.familyMembers.sort(function (a, b) {
                    if (a.created > b.created) {
                        return 1;
                    }
                    if (a.created < b.created) {
                        return -1;
                    }
                    return 0;
                });

                if (scope.withEmailOnly) {
                    scope.familyMembers = scope.familyMembers.filter(function (element) {
                        return (
                            element &&
                            element.contactData &&
                            element.contactData.email &&
                            element.contactData.email.indexOf('@') > -1
                        );
                    });
                }

                /***************************************************************
                 * Lade Familie
                 ***************************************************************/
                scope.family = cfUserService.getCurrentFamily();
                /***************************************************************
                 * Lade User
                 ***************************************************************/
                scope.user = cfUserService.getCurrentUser();

                /***************************************************************
                 * Erstelle Classen für Member und Familie
                 ***************************************************************/
                scope.getColor = function (owner) {
                    return 'fc-icon-' + owner.icon + ' fc-color-' + owner.color_id;
                };
                scope.getFamilyColor = function (family) {
                    return 'fc-familie fc-color-' + family.color_id;
                };

                /***************************************************************
                 * Speichert Member ins Model
                 ***************************************************************/
                scope.setMemberToList = function (members, currentMemberId) {
                    /** Family wird deselektiert **/
                    scope.family.selected = false;
                    if (scope.singleSelectionMode) {
                        members.forEach(member => {
                            member._id === currentMemberId ? (member.selected = true) : (member.selected = false);
                        });
                    }

                    /** Speichert selektierte User _ids ins Model **/
                    var _ids = members
                        .filter(function (i) {
                            return i.selected;
                        })
                        .map(function (i) {
                            return i._id;
                        });
                    ngModel.$setViewValue(_ids);
                    /** Speichert selektierte User namen ins Attribute 'selectedNameList' **/
                    scope.selectedNameList = members
                        .filter(function (i) {
                            return i.selected;
                        })
                        .map(function (i) {
                            return i.contactData.firstName;
                        })
                        .join(', ');
                };

                /***************************************************************
                 * Speichert Familie ins Model
                 ***************************************************************/
                scope.setAllToList = function (family, members) {
                    deselectAll(members);

                    if (family.selected === true) {
                        /** Speichert alle User _ids in Model **/

                        ngModel.$setViewValue([]);

                        /** Speichert Family in Attribute 'selectedNameList' **/
                        scope.selectedNameList = 'Familie';
                    } else {
                        ngModel.$setViewValue([]);
                        scope.selectedNameList = '';
                    }
                };

                /** Deselektiert alle User **/
                function deselectAll(members) {
                    members.forEach(function (i) {
                        i.selected = false;
                    });
                }

                /***************************************************************
                 * Selektiere Familienmitglieder, die bereits in selectedIdList vorhanden sind
                 ***************************************************************/
                if (scope.selectedIdList) {
                    if (scope.selectedIdList.length === 0 && !scope.withEmailOnly) {
                        //selectedIdList ist ein leeres Array. FAmilie wird selektiert
                        deselectAll(scope.familyMembers);
                        scope.family.selected = true;
                        scope.setAllToList(scope.family, scope.familyMembers);
                    } else {
                        //selectedIdList ist ein gefülltes Array. User werden selektiert
                        deselectAll(scope.familyMembers);
                        scope.selectedIdList.forEach(function (list) {
                            var member = scope.familyMembers.find(function (m) {
                                return m._id === list._id || m._id === list;
                            });
                            if (member) member.selected = true;
                        });

                        scope.setMemberToList(scope.familyMembers);
                    }
                } else {
                    //selectedIdList ist undefined. Angemeldeter User wird selektiert.
                    /***************************************************************
                     * Selektiert den aktuellen Benutzer
                     ***************************************************************/
                    if (!scope.withEmailOnly) {
                        scope.familyMembers.forEach(function (member) {
                            if (member._id === scope.user._id) member.selected = true;
                        });
                    }
                    scope.setMemberToList(scope.familyMembers);
                }
            },
        };
    },
]);
;
 
angular.module('multiSelectDistributor', []).directive('multiSelectDistributor', function () {
    return {
        restrict: 'EA',
        replace: false,
        scope: {
            object: '=',
        },
        require: 'ngModel',
        templateUrl: 'html/Direktives/multiSelectDistributor.html',
        link: function (scope, element, attr, ngModel) {
            scope.checkBoxObj = {};

            scope.addToModel = function (object) {
                var returnObject = [];
                if (object) {
                    var count = 0;
                    object.forEach(function (a) {
                        if (a.checked) {
                            count++;
                            returnObject.push(a._id);
                        }
                        if (count == object.length) {
                            scope.all = true;
                        } else {
                            scope.all = false;
                        }
                    });
                }

                ngModel.$setViewValue(returnObject);
            };

            scope.setAll = function () {
                var returnObject = [];
                if (scope.all) {
                    angular.forEach(scope.object, function (mem) {
                        mem.checked = true;
                        returnObject.push(mem._id);
                    });
                } else {
                    angular.forEach(scope.object, function (mem) {
                        mem.checked = false;
                    });
                    returnObject = [];
                }
                ngModel.$setViewValue(returnObject);
            };
        },
    };
});
;
 
angular.module('multiSelectUser', []).directive('multiSelectUser', function () {
    return {
        restrict: 'EA',
        replace: false,
        scope: {
            object: '=',
        },
        require: 'ngModel',
        templateUrl: 'html/Direktives/multiSelectUser.html',
        link: function (scope, element, attr, ngModel) {
            scope.checkBoxObj = {};

            scope.addToModel = function (object) {
                var returnObject = [];
                if (object) {
                    var count = 0;
                    object.forEach(function (a) {
                        if (a.checked) {
                            count++;
                            returnObject.push(a._id);
                        }
                        if (count == object.length) {
                            scope.all = true;
                        } else {
                            scope.all = false;
                        }
                    });
                }

                ngModel.$setViewValue(returnObject);
            };

            scope.setAll = function () {
                var returnObject = [];
                if (scope.all) {
                    angular.forEach(scope.object, function (mem) {
                        mem.checked = true;
                        returnObject.push(mem._id);
                    });
                } else {
                    angular.forEach(scope.object, function (mem) {
                        mem.checked = false;
                    });
                    returnObject = [];
                }
                ngModel.$setViewValue(returnObject);
            };
        },
    };
});
;
 
angular.module('multiSelectWeek', ['cfEventsService']).directive('multiSelectWeek', [
    'cfEventsService',
    function (cfEventsService) {
        return {
            restrict: 'EA',
            replace: false,
            scope: {
                ngModel: '=',
            },
            require: 'ngModel',
            templateUrl: 'html/Direktives/multiSelectWeek.html',
            link: function (scope, element, attr, Model) {
                scope.getWeekDays = cfEventsService.getIntervalTypes().then(function (data) {
                    scope.daysForWeek = data.weekdays;
                    //scope.daysForWeek.selected = false;
                    scope.refreshView(scope.ngModel);
                });

                scope.$watch('ngModel', function (newArray) {
                    scope.refreshView(newArray);
                });

                scope.refreshView = function (newArray) {
                    if (newArray && newArray.length > 0 && scope.daysForWeek && scope.daysForWeek.length > 0) {
                        newArray.forEach(function (element) {
                            var foundElement = scope.daysForWeek.find(function (day) {
                                return day.value == element;
                            });

                            if (foundElement) {
                                foundElement.selected = true;
                            }
                        });
                    }
                };

                scope.setDaysToList = function (day) {
                    /** Speichert selektierte Ttage values ins Model **/
                    var values = day
                        .filter(function (i) {
                            return i.selected;
                        })
                        .map(function (i) {
                            return i.value;
                        });
                    Model.$setViewValue(values);
                };
            },
        };
    },
]);
;
 
angular.module('navbar', []).directive('navbar', function () {
    return {
        restrict: 'E',
        scope: {
            title: '@',
            backUrl: '@',
            backMethod: '&?',
            sidebarToggle: '&?',
            sidebarOpen: '<?',
            navbarClass: '@?',
        },
        link: function (scope, element, attrs, ctrl, transclude) {
            element.find('ng-transclude').replaceWith(transclude());
        },
        transclude: true,
        templateUrl: 'html/Direktives/navbar.html',
        replace: true,
    };
});
;
 
angular.module('nursingDiaryCategorySelector', []).directive('nursingDiaryCategorySelector', function () {
    return {
        restrict: 'E',
        scope: {
            selectSleep: '&?',
            selectFood: '&?',
            selectHydration: '&?',
            selectMood: '&?',
            filterSleep: '&?',
            filterFood: '&?',
            filterHydration: '&?',
            filterMood: '&?',
            filterActivities: '&?',
            filterMedication: '&?',
            title: '@?',
            documentation: '=',
        },
        templateUrl: 'html/Direktives/nursingDiaryCategorySelector.html',
        replace: true,
    };
});
;
 
angular.module('nursingDiaryCheckboxPicker', []).directive('nursingDiaryCheckboxPicker', function () {
    return {
        restrict: 'E',
        scope: {
            options: '<',
            ngModel: '=',
        },
        templateUrl: 'html/Direktives/nursingDiaryCheckboxPicker.html',
        replace: true,
        link: function (scope) {
            scope.ngChange = function (key) {
                scope.ngModel[key] = !scope.ngModel[key];
            };
        },
    };
});
;
 
angular.module('nursingDiaryEditorCategory', []).directive('nursingDiaryEditorCategory', function () {
    return {
        restrict: 'E',
        scope: {
            title: '<?',
            label: '<?',
            icon: '<?',
            backgroundClass: '<?',
        },
        templateUrl: 'html/Direktives/nursingDiaryEditorCategory.html',
        replace: true,
        transclude: true,
    };
});
;
 
angular.module('nursingDiaryRadioPicker', []).directive('nursingDiaryRadioPicker', function () {
    return {
        restrict: 'E',
        require: 'ngModel',
        scope: {
            options: '<',
            name: '<',
            ngModel: '=',
        },
        templateUrl: 'html/Direktives/nursingDiaryRadioPicker.html',
        replace: true,
        link: function (scope, element, attrs, ctrl) {
            scope.ngChange = function (value) {
                scope.ngModel = value;
                ctrl.$setViewValue(value);
            };
        },
    };
});
;
 
angular.module('nursingDocumentationNavBar', []).directive('nursingDocumentationNavBar', function () {
    return {
        restrict: 'E',
        scope: {
            title: '@',
            navbarClass: '@?',
        },
        templateUrl: 'html/Direktives/nursingDocumentationNavBar.html',
    };
});
;
 
angular.module('nursingEntryDatePicker', []).directive('nursingEntryDatePicker', function () {
    return {
        restrict: 'E',
        scope: {
            title: '@',
            backMethod: '&',
            frontMethod: '&',
            navbarClass: '@?',
            disabled: '<',
            format: '@',
            ngModel: '=',
            setDate: '&',
        },
        link: function (scope) {
            scope.ngChange = function (value) {
                scope.ngModel = value;
                scope.setDate({ skip: value });
            };
            scope.isOpen = false;
            scope.setOpen = function () {
                scope.isOpen = true;
            };
            scope.dateOptions = {
                maxDate: moment().toDate(),
            };
        },
        templateUrl: 'html/Direktives/nursingEntryDatePicker.html',
        replace: true,
    };
});
;
 
angular.module('passwordInputWithConfirmation', []).directive('passwordInputWithConfirmation', function () {
    return {
        restrict: 'E',
        scope: { form: '=', formData: '=', passwordLabel: '@?', passwordConfirmationLabel: '@?' },
        templateUrl: 'html/Direktives/passwordInputWithConfirmation.html',
        replace: true,
        link: function (scope) {
            scope.validatePassword = (password = '') => {
                scope.form.password.$setValidity('uppercase', true);
                scope.form.password.$setValidity('lowercase', true);

                if (password.length > 7 && !/.*[A-Z].*/.test(password)) {
                    scope.form.password.$setValidity('uppercase', false);
                } else if (password.length > 7 && !/.*[a-z].*/.test(password)) {
                    scope.form.password.$setValidity('lowercase', false);
                }
            };

            scope.validatePasswordConfirmation = (password, passwordConfirmation) => {
                scope.form.passwordConfirmation.$setValidity('passwordConfirmation', password === passwordConfirmation);
            };
        },
    };
});
;
 
angular.module('userIcons', ['cfUserService']).directive('userIcons', [
    'cfUserService',
    function (cfUserService) {
        return {
            restrict: 'EA',
            replace: false,
            scope: {
                owner: '=',
            },
            templateUrl: 'html/Direktives/userIcons.html',
            link: function (scope, element, attr, ngModel) {
                cfUserService.loadCurrentUser().then(function (user) {
                    scope.familyColor = user.family.color_id;
                });
            },
        };
    },
]);
;
 
angular.module('validateCoupon', ['mobileAppService']).directive('validateCoupon', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        return {
            require: 'ngModel',
            link: function (scope, element, attributes, ngModel) {
                const baseUrl = mobileAppService.staticLoc;
                ngModel.$asyncValidators.validateCoupon = modelValue => {
                    if (modelValue.length === 6) {
                        return $http
                            .post(`${baseUrl}/api/frontend/coupons/validate`, { code: modelValue })
                            .then(({ data }) => (data.isValid !== true ? $q.reject() : true));
                    } else {
                        return $q.when();
                    }
                };
            },
        };
    },
]);
;
 
angular.module('family_cockpit').filter('eventFilter', function () {
    return function (_obj, filterValues) {
        var obj = angular.copy(_obj);

        if (!filterValues || filterValues.length < 1) {
            return obj;
        } else if (!obj || Object.keys(obj).length < 0) {
            return {};
        } else {
            Object.keys(obj).forEach(function (key) {
                obj[key] = obj[key].filter(function (event) {
                    var valid = false;
                    if (!event.owner || event.owner.length === 0) {
                        valid = true;
                    } else {
                        filterValues.forEach(function (value) {
                            var index = event.owner.findIndex(function (member) {
                                return member._id === value;
                            });

                            if (index > -1) valid = true;
                        });
                    }
                    return valid;
                });

                if (obj[key].length < 1) delete obj[key];
            });
            return obj;
        }
    };
});
;
 
angular.module('family_cockpit').filter('filterEventsForDay', function () {
    return function (eventsForDay) {
        /***************************************************************
         * Sortiert nach Timestamp
         ***************************************************************/
        (eventsForDay || []).sort(function (a, b) {
            var _aKey = new Date(a.start_date).toTimeString();
            var _bKey = new Date(b.start_date).toTimeString();
            if (_aKey > _bKey) {
                return 1;
            }
            if (_aKey < _bKey) {
                return -1;
            }
            return 0;
        });
        return eventsForDay;
    };
});
;
 
angular.module('family_cockpit').filter('filterEventsForWeek', function () {
    return function (eventsForWeek) {
        return sortObj(eventsForWeek);
    };

    /**
     * Sort JavaScript Object
     * CF Webtools : Chris Tierney
     * obj = object to sort
     * order = 'asc' or 'desc'
     */
    function sortObj(obj, order) {
        'use strict';

        var key,
            tempArry = [],
            i,
            tempObj = {};

        for (key in obj) {
            tempArry.push(key);
        }

        tempArry.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        if (order === 'desc') {
            for (i = tempArry.length - 1; i >= 0; i--) {
                tempObj[tempArry[i]] = obj[tempArry[i]];
            }
        } else {
            for (i = 0; i < tempArry.length; i++) {
                tempObj[tempArry[i]] = obj[tempArry[i]];
            }
        }

        return tempObj;
    }
});
;
 
angular.module('family_cockpit').filter('listFilter', function () {
    return function (array, filterValues) {
        if (!filterValues || filterValues.length < 1) {
            return array;
        } else if (!array || array.length < 0) {
            return [];
        } else {
            var filteredArray = array.filter(function (element) {
                var valid = false;
                if (element.owner && element.owner.length > 0) {
                    element.owner.forEach(function (o) {
                        if (filterValues.indexOf(o._id) > -1) {
                            valid = true;
                        }
                    });
                } else {
                    valid = true;
                }
                return valid;
            });
            return filteredArray;
        }
    };
});
;
 
angular.module('family_cockpit').filter('notesFilter', function () {
    return function (array, filterValues) {
        if (!filterValues || filterValues.length < 1) {
            return array;
        } else if (!array || array.length < 0) {
            return [];
        } else {
            var filteredArray = array.filter(function (element) {
                var valid = false;
                if (element.owner && element.owner.length > 0) {
                    element.owner.forEach(function (o) {
                        if (filterValues.indexOf(o._id) > -1) {
                            valid = true;
                        }
                    });
                } else {
                    valid = true;
                }
                return valid;
            });
            return filteredArray;
        }
    };
});
;
 
angular.module('family_cockpit').filter('pinnwandFilter', function () {
    return function (array, filterValues) {
        if (!filterValues || filterValues.length < 1) {
            return array;
        } else if (!array || array.length < 0) {
            return [];
        } else {
            return array.filter(function (element) {
                let valid = false;
                if (element.owner) {
                    element.owner.forEach(function (o) {
                        if (filterValues.indexOf(o._id) !== -1 || filterValues.indexOf(o) !== -1) {
                            valid = true;
                        }
                    });
                } else {
                    /***************************************************************
                     * Events haben keinen owner
                     ***************************************************************/
                    element.familyMembers.forEach(function (o) {
                        if (filterValues.indexOf(o._id) > -1) {
                            valid = true;
                        }
                    });
                }

                return valid;
            });
        }
    };
});
;
 
angular.module('family_cockpit').filter('sortEventIcons', function () {
    return function (array) {
        if (!array || array.length < 1) {
            return [];
        } else {
            array.reverse();
            return array;
        }
    };
});
;
 
angular.module('family_cockpit').filter('statesFilter', function () {
    return function (states, filterValue) {
        if (states) {
            if (filterValue === 'unique') {
                var unique = [];
                states.forEach(function (state) {
                    if (
                        unique
                            .map(function (obj) {
                                return obj.countryname;
                            })
                            .indexOf(state.countryname) < 0
                    ) {
                        unique.push(state);
                    }
                });
                return unique;
            } else {
                return states.filter(function (state) {
                    return state.countrytoken === filterValue;
                });
            }
        }
    };
});
;
 
/***************************************************************
 * User wird vor dem ausführen des jeweiligen Controllers geladen
 ***************************************************************/
function checkSessionForUser(cfUserService) {
    return cfUserService.loadCurrentUser();
}

function skipLogin(cfUserService) {
    return cfUserService.skipLogin();
}

function protect(cfUserService) {
    return cfUserService.protect();
}

function checkForNursingDiaries($q, $location, cfNursingDiaryService) {
    const deferred = $q.defer();
    cfNursingDiaryService.getNursingDiaries().then(function (nursingDiaries) {
        if (!nursingDiaries.length) {
            $location.path('/pflegetagebuch/welcome');
            deferred.reject();
        } else {
            deferred.resolve();
        }
    });
    return deferred.promise;
}

function protectHomepage($q, $location, cfUserService) {
    const deferred = $q.defer();
    cfUserService.loadCurrentUser().then(function (user) {
        if (user.family?.access?.level === 'no-create') {
            $location.path('/welcome/abonnement');
            deferred.reject();
        } else {
            deferred.resolve();
        }
    });
    return deferred.promise;
}

function guardSubscriptionPurchase($q, $location, cfUserService) {
    const deferred = $q.defer();

    // Only available in the native app
    if (window.cordova) {
        cfUserService.loadCurrentUser().then(function (user) {
            // Only available if it is enabled for this family
            if (user.family?.access?.enableSubscriptionPurchasePage) {
                deferred.resolve();
            } else {
                $location.path('/');
                deferred.reject();
            }
        });
    } else {
        $location.path('/');
        deferred.reject();
    }
    return deferred.promise;
}

function limitNoCreateUsers($q, $location, cfUserService) {
    const deferred = $q.defer();
    cfUserService.loadCurrentUser().then(function (user) {
        if (user.family?.access?.level === 'no-create') {
            $location.path('/welcome/abonnement');
            deferred.reject();
        } else {
            deferred.resolve();
        }
    });
    return deferred.promise;
}

angular.module('family_cockpit').config([
    '$routeProvider',
    function ($routeProvider) {
        $routeProvider
            .when('/', {
                templateUrl: 'html/login.html',
                resolve: {
                    skipLogin: skipLogin,
                },
            })
            .when('/registrieren', {
                templateUrl: 'html/registrationSelect.html',
            })
            .when('/registration', {
                templateUrl: 'html/abonnement/registration.html',
            })
            .when('/registrieren/abonnement', {
                templateUrl: 'html/abonnement/index.html',
            })
            .when('/registrieren/abonnement/information', {
                templateUrl: 'html/abonnement/information.html',
            })
            .when('/welcome/abonnement', {
                templateUrl: 'html/abonnement/welcome.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/registrieren/zdg', {
                templateUrl: 'html/zdg/registration.html',
            })
            .when('/registrieren/zdg/information', {
                templateUrl: 'html/zdg/information.html',
            })
            .when('/welcome/zdg', {
                templateUrl: 'html/zdg/welcome.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/hilfe', {
                templateUrl: 'html/faq.html',
            })
            .when('/passwortVergessen', {
                templateUrl: 'html/passwortVergessen.html',
            })
            .when('/home', {
                templateUrl: 'html/home.html',
                controller: 'cfHomeCtrl',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                    protectHomepage: protectHomepage,
                },
            })
            .when('/listen/', {
                templateUrl: 'html/list.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/checklisten/:id?', {
                templateUrl: 'html/checklist.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/listen/:listType/:id?', {
                templateUrl: 'html/list.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/wunschlisten', {
                templateUrl: 'html/wunschlisten.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/wunschlistendetail/:_id', {
                templateUrl: 'html/wunschlistenDetail.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/wunschlistendetail/:_id/verteiler', {
                templateUrl: 'html/wishListDistributor.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/neueWunschliste', {
                templateUrl: 'html/newWishlist.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })

            .when('/notizen/:id?', {
                templateUrl: 'html/notes.html',
                controller: 'cfNoteCtrl',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/profil/:_id', {
                templateUrl: 'html/profile.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/family', {
                templateUrl: 'html/family/family.html',
                controller: 'cfFamilyProfileCtrl',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/family/subscriptions', {
                templateUrl: 'html/family/subscriptions.html',
                controller: 'cfSubscriptionCtrl',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/family/subscriptions/purchase', {
                templateUrl: 'html/family/purchase.html',
                controller: 'cfPurchaseCtrl',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    guardSubscriptionPurchase: guardSubscriptionPurchase,
                },
            })
            .when('/kalender/:id?', {
                templateUrl: 'html/events.html',
                controller: 'cfEventsCtrl',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })

            .when('/kaeinstellungen', {
                templateUrl: 'html/kalender.html',
                controller: 'cfCalendarCtrl',
                resolve: {
                    user: checkSessionForUser,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })

            .when('/adressbuch/:id?', {
                templateUrl: 'html/addressBook.html',
                resolve: {
                    user: checkSessionForUser,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })

            .when('/notizbearbeiten', {
                templateUrl: 'html/editNote.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/passwortaendern', {
                templateUrl: 'html/passwortaendern.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/neuerTermin', {
                templateUrl: 'html/newEvent.html',
                controller: 'cfEventsCtrl',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/faq', {
                templateUrl: 'html/faq.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/faqantworten/:id', {
                templateUrl: 'html/faqAntworten.html',
            })
            .when('/notfallnummer', {
                templateUrl: 'html/emergencyNr.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/inviteMember', {
                templateUrl: 'html/inviteMember.html',
                controller: 'cfInvitationCtrl',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/createDistributor', {
                templateUrl: 'html/createDistributor.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/rechtliches', {
                templateUrl: 'html/rechtliches.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/rechtliches', {
                templateUrl: 'html/rechtliches.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/pflegetagebuch/welcome', {
                templateUrl: 'html/diary/welcome.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/pflegetagebuch/documentation', {
                templateUrl: 'html/diary/documentation.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/pflegetagebuch/editor/:nursingDiaryId?', {
                templateUrl: 'html/diary/editor.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/pflegetagebuch/share/:shareId', {
                templateUrl: 'html/diary/share.html',
            })
            .when('/pflegetagebuch', {
                templateUrl: 'html/diary/view.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    checkForNursingDiaries: checkForNursingDiaries,
                },
            })
            .when('/version', {
                templateUrl: 'html/version.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                },
            })
            .when('/wishlistLogin', {
                templateUrl: 'html/wishlistLogin.html',
                resolve: {
                    user: checkSessionForUser,
                    protect: protect,
                    limitNoCreateUsers: limitNoCreateUsers,
                },
            })
            .when('/nutzungsbedingungen', {
                templateUrl: 'html/nutzungsbedingungen.html',
            })
            .when('/datenschutz', {
                templateUrl: 'html/datenschutz.html',
            })
            .when('/secret', {
                templateUrl: 'html/timeStamp.html',
            })
            .when('/confirmInvitation/:invitationHash', {
                templateUrl: 'html/confirmInvitation.html',
            })
            .when('/confirmPasswordReset/:passwordResetHash', {
                templateUrl: 'html/confirmPasswordReset.html',
            });
    },
]);
;
 
angular.module('cfAddressGroupService', ['mobileAppService']).factory('cfAddressGroupService', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getAddressGroup: getAddressGroup,
            createAddressGroup: createAddressGroup,
            updateAddressGroup: updateAddressGroup,
            deleteAddressGroup: deleteAddressGroup,
        };

        function getAddressGroup(addressGroup) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getAddressGroup',
                params: { addressGroup: addressGroup },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function createAddressGroup(processedAddressGroup) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createAddressGroup',
                data: {
                    processedAddressGroup: processedAddressGroup,
                },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function updateAddressGroup(processedAddressGroup) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/updateAddressGroup',
                data: { processedAddressGroup: processedAddressGroup },
            }).then(
                function (response) {
                    if (response.data.success) deferred.resolve();
                    else deferred.reject(response.data.message);
                },
                function (response) {
                    deferred.reject(response.statusText);
                }
            );
            return deferred.promise;
        }

        function deleteAddressGroup(addressGroupId) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/deleteAddressGroup',
                data: { addressGroupId: addressGroupId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfAddressService', ['mobileAppService']).factory('cfAddressService', [
    '$q',
    '$http',
    'Upload',
    'mobileAppService',
    function ($q, $http, Upload, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            createAddress: createAddress,
            updateAddress: updateAddress,
            deleteAddress: deleteAddress,
            getAddressById: getAddressById,
        };

        function createAddress(processedAddress, addressImage) {
            var deferred = $q.defer();
            Upload.upload({
                method: 'POST',
                url: baseUrl + '/api/frontend/createAddress',
                file: addressImage,
                data: { processedAddress: processedAddress },
            })
                .progress(function (evt) {})
                .success(function (_data) {
                    if (!_data.success) {
                        deferred.reject(_data);
                    } else {
                        deferred.resolve(_data.data);
                    }
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function updateAddress(processedAddress, addressImage) {
            var deferred = $q.defer();
            Upload.upload({
                method: 'POST',
                url: baseUrl + '/api/frontend/updateAddress',
                file: addressImage,
                data: { processedAddress: processedAddress },
            })
                .progress(function (evt) {})
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function deleteAddress(addressId) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/deleteAddress',
                data: { addressId: addressId },
            }).then(
                function (response) {
                    if (response.data.success) deferred.resolve();
                    else deferred.reject(response.data.message);
                },
                function (response) {
                    deferred.reject(response.statusText);
                }
            );
            return deferred.promise;
        }

        function getAddressById(addressId) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getAddressById',
                params: { addressId: addressId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfCalendarService', ['mobileAppService']).factory('cfCalendarService', [
    '$q',
    '$http',
    'Upload',
    'mobileAppService',
    'cfUserService',
    function ($q, $http, Upload, mobileAppService, cfUserService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getImportedCalendar: getImportedCalendar,
            updateImportedCalendar: updateImportedCalendar,
            deleteImportedCalendar: deleteImportedCalendar,
            getPhoneCalendar: getPhoneCalendar,
            importCalendar: importCalendar,
            checkDuplicates: checkDuplicates,
            activateCalendar: activateCalendar,
            importIosCalendar: importIosCalendar,
        };

        function getImportedCalendar() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getImportedCalendar',
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function updateImportedCalendar(calendar) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/updateImportedCalendar',
                data: { calendar: calendar, family: cfUserService.getCurrentFamilyId() },
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function deleteImportedCalendar(calendar) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/deleteImportedCalendar',
                data: { calendarId: calendar._id },
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function getPhoneCalendar() {
            var deferred = $q.defer();
            var calendar = [
                {
                    type: 'iPhone',
                    calendar: [
                        {
                            privat: false,
                            title: 'Kalender Mock',
                            typ: 'iPhone',
                        },
                    ],
                },
            ];
            deferred.resolve(calendar);
            return deferred.promise;
        }

        function importCalendar(calendar, user) {
            var deferred = $q.defer();
            Upload.upload({
                method: 'POST',
                url: baseUrl + '/api/frontend/importCalendar',
                file: calendar.calendarFile,
                data: { calendar: calendar, user: user, family: cfUserService.getCurrentFamilyId() },
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function checkDuplicates(calendar) {
            var deferred = $q.defer();
            Upload.upload({
                method: 'POST',
                url: baseUrl + '/api/frontend/checkDuplicates',
                file: calendar.calendarFile,
                data: { calendar: calendar, family: cfUserService.getCurrentFamilyId() },
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function activateCalendar(calendar) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/activateCalendar',
                data: { calendar: calendar, family: cfUserService.getCurrentFamilyId() },
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function importIosCalendar(calendar, events, user) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/importIosCalendar',
                data: { calendar: calendar, events: events, user: user, family: cfUserService.getCurrentFamilyId() },
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfChecklistService', ['mobileAppService']).factory('cfChecklistService', [
    '$q',
    '$http',
    'cfUserService',
    'mobileAppService',
    function ($q, $http, cfUserService, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getChecklists: getChecklists,
            cfUpdateChecklistTask: cfUpdateChecklistTask,
            cfCreateUpdateChecklist: cfCreateUpdateChecklist,
            getChecklistTemplates: getChecklistTemplates,
            cfRemoveChecklist: cfRemoveChecklist,
            getChecklistById: getChecklistById,
        };

        /***************************************************************
         *  lade checklisten
         ***************************************************************/
        function getChecklists() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getChecklists',
                params: { family: cfUserService.getCurrentFamilyId() },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        /***************************************************************
         *  lade checkliste nach id
         ***************************************************************/
        function getChecklistById(listId) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getChecklistById',
                params: { listId: listId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        /***************************************************************
         *  lade templates für checklisten
         ***************************************************************/

        function getChecklistTemplates() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getChecklistTemplates',
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        /***************************************************************
         *  Bearbeite Task in Checkliste
         ***************************************************************/
        function cfUpdateChecklistTask(checklist) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createUpdateChecklist',
                data: { processedChecklist: checklist, family: cfUserService.getCurrentFamilyId() },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status + data);
                });
            return deferred.promise;
        }

        /***************************************************************
         * Erstelle / Bearbeiten Checkliste
         ***************************************************************/
        function cfCreateUpdateChecklist(processedChecklist) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createUpdateChecklist',
                data: { processedChecklist: processedChecklist, family: cfUserService.getCurrentFamilyId() },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        /***************************************************************
         * Lösche Checkliste
         ***************************************************************/
        function cfRemoveChecklist(checklist) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/removeChecklist',
                data: { listId: checklist._id },
            }).then(
                function (response) {
                    if (response.data.success) {
                        deferred.resolve();
                    } else {
                        deferred.reject();
                    }
                },
                function (response) {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfEmergencyNrService', ['mobileAppService']).factory('cfEmergencyNrService', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getEmergencyNr: getEmergencyNr,
        };

        function getEmergencyNr() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getEmergencyNr',
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfEventsHelperService', ['cfListService']).factory('cfEventsHelperService', [
    function () {
        return {
            createStringForCalendarDay: createStringForCalendarDay,
        };

        /***************************************************************
         * Erstellt einen String, für jeden Kalendereintrag
         ***************************************************************/
        function createStringForCalendarDay(entries, familyColorId) {
            if (entries && entries.length > 0) {
                var memberCount = createMemberCount(entries, familyColorId);

                var memberString = createStringFromMemberCount(memberCount);
                var iconString = createStringForIcons(entries);
                var eventString = createEventString(entries, familyColorId);
                return iconString + eventString + memberString;
            } else return '<p></p>';
        }

        /***************************************************************
         * Prüftfür jeden Termin ob dieser für einen oder mehrere Member gilt.
         * Erstellt daraus ein Count Object.
         * Key ist die ColorId. Value ist der Count.
         * {
         *      1: 2,
         *      8: 2,
         *      10: 5,
         * }
         ***************************************************************/
        function createMemberCount(entries, familyColorId) {
            var _memberCount = {};
            var multiColorId = 1;

            entries.forEach(function (entry) {
                if (!entry.showAsHeader) {
                    /** Wenn ein Member im Termin vorhanden sind **/
                    if (entry.owner && entry.owner.length === 1) addToCountObject(entry.owner[0].color_id);
                    /** Wenn mehrere Member im Termin vorhanden sind oder keine Member vorhanden sind **/ else if (
                        !entry.owner ||
                        entry.owner.length === 0
                    )
                        addToCountObject(familyColorId);
                    else addToCountObject(multiColorId);
                }
            });

            function addToCountObject(colorId) {
                if (!_memberCount[colorId]) {
                    _memberCount[colorId] = 1;
                } else {
                    _memberCount[colorId]++;
                }
            }

            return _memberCount;
        }

        /***************************************************************
         * Erstellt aus dem memberCount einen String,
         * der in der Monatsansicht angezeigt werden kann
         ***************************************************************/
        function createStringFromMemberCount(memberCount) {
            var string = "<div class='calendar_inner_mobile'>";

            Object.keys(memberCount).forEach(function (colorId) {
                string =
                    string +
                    "<div class='calender_color_box fc-monats-balken fc-color-" +
                    colorId +
                    "'>" +
                    memberCount[colorId] +
                    '</div>';
            });

            string = string + '</div>';
            return string;
        }

        function createStringForIcons(entries) {
            return entries
                .map(function (event) {
                    if (event.showAsHeader)
                        return (
                            '<img class="calendar_icons" src= "' +
                            'images/icons/kalender/' +
                            event.headerIcon +
                            '" alt="' +
                            event.title +
                            '" title= "' +
                            event.title +
                            '" />'
                        );
                })
                .filter(function (e) {
                    return e || false;
                })
                .join('');
        }

        function createEventString(entries, familyColorId) {
            var string = "<div class='calendar_inner_desktop'>";

            //Jedes Event bekommt seinen String
            var stringArray = entries
                .map(function (event) {
                    if (!event.showAsHeader) {
                        var eventText = event.title;
                        var colorId;
                        if (event.owner && event.owner.length > 1) colorId = 1;
                        else if (event.owner && event.owner.length === 1) colorId = event.owner[0].color_id;
                        /** Wenn mehrere Member im Termin vorhanden sind oder keine Member vorhanden sind **/ else if (
                            !event.owner ||
                            event.owner.length === 0
                        )
                            colorId = familyColorId;
                        else colorId = familyColorId;
                        return (
                            "<div class='event_row fc-color-" +
                            colorId +
                            " fc-monats-balken full_width' >" +
                            eventText +
                            '</div>'
                        );
                    }
                })
                .filter(function (e) {
                    return e;
                });

            //Wenn mehr als 3 Strings wird noch ein "x weitere Termine" hinzugefügt
            if (stringArray.length <= 5) string = string + stringArray.join('');
            else {
                var shortedArray = stringArray.slice(0, 4);
                string = string + shortedArray.join('');
                if (stringArray.length - shortedArray.length > 1) {
                    string =
                        string +
                        "<div class='event_row fc-color-" +
                        familyColorId +
                        " fc-monats-balken full_width' >" +
                        (stringArray.length - shortedArray.length) +
                        ' weitere </div>';
                }
            }

            string = string + '</div>';
            return string;
        }
    },
]);
;
 
angular
    .module('cfEventsService', ['cfEventsHelperService', 'cfSelectedTimeService', 'mobileAppService'])
    .factory('cfEventsService', [
        '$q',
        '$http',
        'cfEventsHelperService',
        'MaterialCalendarData',
        'DateConstructor',
        '$filter',
        'cfSelectedTimeService',
        'mobileAppService',
        function (
            $q,
            $http,
            cfEventsHelperService,
            MaterialCalendarData,
            DateConstructor,
            $filter,
            sts,
            mobileAppService
        ) {
            var baseUrl = mobileAppService.staticLoc;

            /***************************************************************
             * Hier werden geladene Kalendereinträge gespeichert.
             ***************************************************************/
            var events = {};
            var filteredEvents = {};

            return {
                loadEventsForPeriod: loadEventsForPeriod,
                getEventsForDay: getEventsForDay,
                cfUpdateEvent: cfUpdateEvent,
                getEvents: getEvents,
                refreshCalendar: refreshCalendar,
                getIntervalTypes: getIntervalTypes,
                cfDeleteEvent: cfDeleteEvent,
                getEventById: getEventById,
                createSortMachine: createSortMachine,
            };

            function getEvents() {
                return events;
            }

            /***************************************************************
             * (CRUD: GET)
             * Lädt alle Termine innerhalb eines festgelegten Monats
             ***************************************************************/
            function loadEventsForPeriod(family, familyFilter, period, callback) {
                var deferred = $q.defer();

                $http({
                    method: 'GET',
                    url: baseUrl + '/api/frontend/loadEventsForPeriod',
                    params: {
                        familyId: family._id,
                        period: period,
                    },
                }).then(
                    function (response) {
                        if (response.data.success) {
                            clearEvents();
                            addEvents(response.data.foundEvents);
                            refreshCalendar(familyFilter, family.color_id);
                            deferred.resolve(events);

                            if (callback && filteredEvents) {
                                callback(filteredEvents);
                            } else if (callback) {
                                callback(false);
                            }
                        } else {
                            console.error('loadEventforMonth Fehler: ', response.data.message);
                            deferred.reject();
                        }
                    },
                    function (data, status) {
                        console.error('loadEventforMonth Fehler: ', data, status);
                        deferred.reject();
                    }
                );
                return deferred.promise;
            }

            function clearEvents() {
                Object.keys(events).forEach(function (e) {
                    delete events[e];
                });
            }

            function addEvents(newEvents) {
                Object.keys(newEvents).forEach(function (key) {
                    events[key] = newEvents[key];
                });
            }

            /***************************************************************
             * Lädt alle Termine innerhalb eines festgelegten Tages
             ***************************************************************/
            function getEventsForDay(tsDate) {
                var key = createDateKey(tsDate);
                if (filteredEvents[key]) return filteredEvents[key];
                else return [];
            }

            function createDateKey(date) {
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate();
                return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
            }

            /***************************************************************
             * Für jeden übergeben Termin, wird der Kalender aktualisiert
             ***************************************************************/
            function refreshCalendar(familyFilter, familyColorId) {
                clearCalendar();

                filteredEvents = $filter('eventFilter')(events, familyFilter);

                Object.keys(filteredEvents).forEach(function (key) {
                    var timestamp = convertToTimestamp(key);
                    var calenderStrings = cfEventsHelperService.createStringForCalendarDay(
                        filteredEvents[key],
                        familyColorId
                    );

                    MaterialCalendarData.setDayContent(timestamp, calenderStrings);
                });
            }

            function clearCalendar() {
                var selectedDate = sts.selectedDate;
                var moment = selectedDate.moment;
                var firstDayOfWeek = moment.clone().startOf('month');
                var lastDayOfWeek = moment.clone().endOf('month');
                var diff = lastDayOfWeek.diff(firstDayOfWeek, 'days');

                for (var offset = 0; offset <= diff; offset++) {
                    var timestamp = firstDayOfWeek.clone().add(offset, 'd').toDate();
                    MaterialCalendarData.setDayContent(timestamp, '<span></span>');
                }
            }

            /***************************************************************
             * (CRUD: CREATE / UPDATE)
             * Speichert / Bearbeitet einen Termin
             * ***************************************************************/
            function cfUpdateEvent(processedEvent) {
                var deferred = $q.defer();
                $http({
                    method: 'POST',
                    url: baseUrl + '/api/frontend/cfUpdateEvent',
                    data: { processedEvent: processedEvent },
                }).then(
                    function (response) {
                        deferred.resolve(response.data.savedEvent);
                    },
                    function () {
                        deferred.reject();
                    }
                );
                return deferred.promise;
            }

            /***************************************************************
             * (CRUD: Delete)
             * Löscht einen Termin
             * ***************************************************************/
            function cfDeleteEvent(eventId) {
                var deferred = $q.defer();
                $http({
                    method: 'POST',
                    url: baseUrl + '/api/frontend/cfDeleteEvent',
                    data: { eventId: eventId },
                }).then(
                    function (response) {
                        deferred.resolve(response.data.savedEvent);
                    },
                    function () {
                        deferred.reject();
                    }
                );
                return deferred.promise;
            }

            function createSortMachine(events) {
                var sortMachine = [];
                var currentSorterIndex = -1;
                var maxEndTime = 0;
                var minStartTime = 8 * 3600;

                var getCurrentSorter = function () {
                    return sortMachine[currentSorterIndex];
                };

                var addSorter = function () {
                    sortMachine.push([[]]);
                    currentSorterIndex = currentSorterIndex + 1;
                };

                var getStartTimeInt = function (element) {
                    var start = moment(element.start_date);
                    return start.hour() * 60 * 60 + start.minute() * 60;
                };

                var getEndTimeInt = function (element) {
                    var end = moment(element.end_date);
                    return end.hour() * 60 * 60 + end.minute() * 60;
                };

                var insertToSorter = function (element, i) {
                    if (i >= getCurrentSorter().length) {
                        var newArray = [];
                        newArray.push(element);
                        getCurrentSorter().push(newArray);
                        return true;
                    } else {
                        var slot = getCurrentSorter()[i];
                        if (slot.length === 0) {
                            slot.push(element);
                            return true;
                        } else {
                            if (getEndTimeInt(slot[slot.length - 1]) <= getStartTimeInt(element)) {
                                slot.push(element);
                                return true;
                            } else return false;
                        }
                    }
                };

                var sortEvent = function (element) {
                    if (getStartTimeInt(element) < minStartTime) {
                        minStartTime = getStartTimeInt(element);
                    }
                    if (getStartTimeInt(element) >= maxEndTime) {
                        addSorter();
                        maxEndTime = getEndTimeInt(element);
                    }
                    if (getEndTimeInt(element) > maxEndTime) {
                        maxEndTime = getEndTimeInt(element);
                    }
                    var i = 0;
                    while (i < 10) {
                        if (insertToSorter(element, i)) {
                            i = 10;
                        } else {
                            i = i + 1;
                        }
                    }
                };

                events.sort(function (a, b) {
                    return getStartTimeInt(a) - getStartTimeInt(b);
                });
                events.forEach(sortEvent);
                return sortMachine;
            }

            function convertToTimestamp(string) {
                var day = parseInt(string.split('-')[2]);
                var month = parseInt(string.split('-')[1]);
                var year = parseInt(string.split('-')[0]);
                var date = undefined;

                if (day && month && year) date = new Date(year, month - 1, day);

                return date;
            }

            function getEventById(eventId) {
                var deferred = $q.defer();
                $http({
                    method: 'GET',
                    url: baseUrl + '/api/frontend/getEventById',
                    params: { eventId: eventId },
                })
                    .success(function (_data) {
                        if (_data && Array.isArray(_data) && _data[0]) _data = _data[0];
                        if (_data && _data.interval && _data.interval.interval_end_date)
                            _data.interval.interval_end_date = new Date(_data.interval.interval_end_date);
                        deferred.resolve(_data);
                    })
                    .error(function (data, status) {
                        deferred.reject(status);
                    });
                return deferred.promise;
            }

            function getIntervalTypes() {
                var interval = {
                    intervalType: [
                        { value: 0, name: 'Nie' },
                        { value: 1, name: 'Täglich' },
                        { value: 2, name: 'Wöchentlich' },
                        { value: 3, name: 'Monatlich' },
                        { value: 4, name: 'Jährlich' },
                        { value: 5, name: 'Geburtstag' },
                    ],
                    intervalTypeDaily: [
                        { value: 1, name: 'Jeden Tag' },
                        { value: 2, name: 'Alle 2 Tage' },
                        { value: 3, name: 'Alle 3 Tage' },
                        { value: 4, name: 'Alle 4 Tage' },
                        { value: 5, name: 'Alle 5 Tage' },
                        { value: 6, name: 'Alle 6 Tage' },
                        { value: 7, name: 'Alle 7 Tage' },
                        { value: 8, name: 'Alle 8 Tage' },
                        { value: 9, name: 'Alle 9 Tage' },
                        { value: 10, name: 'Alle 10 Tage' },
                    ],
                    IntervalTypeWeekly: [
                        { value: 1, name: 'Jede Woche' },
                        { value: 2, name: 'Alle 2 Wochen' },
                        { value: 3, name: 'Alle 3 Wochen' },
                        { value: 4, name: 'Alle 4 Wochen' },
                        { value: 5, name: 'Alle 5 Wochen' },
                        { value: 6, name: 'Alle 6 Wochen' },
                        { value: 7, name: 'Alle 7 Wochen' },
                        { value: 8, name: 'Alle 8 Wochen' },
                        { value: 9, name: 'Alle 9 Wochen' },
                        { value: 10, name: 'Alle 10 Wochen' },
                        { value: 11, name: 'Alle 11 Wochen' },
                        { value: 12, name: 'Alle 12 Wochen' },
                        { value: 13, name: 'Alle 13 Wochen' },
                        { value: 14, name: 'Alle 14 Wochen' },
                        { value: 15, name: 'Alle 15 Wochen' },
                    ],
                    IntervalTypeMonthly: [
                        { value: 1, name: 'Jeden Monat' },
                        { value: 2, name: 'Alle 2 Monate' },
                        { value: 3, name: 'Alle 3 Monate' },
                        { value: 4, name: 'Alle 4 Monate' },
                        { value: 5, name: 'Alle 5 Monate' },
                        { value: 6, name: 'Alle 6 Monate' },
                    ],
                    IntervalTypeYearly: [
                        { value: 1, name: 'Jedes Jahr' },
                        { value: 2, name: 'Alle 2 Jahre' },
                        { value: 3, name: 'Alle 3 Jahre' },
                        { value: 4, name: 'Alle 4 Jahre' },
                    ],
                    IntervalTypeMonthlyType: [
                        { value: -3, name: 'drittletzen' },
                        { value: -2, name: 'vorletzten' },
                        { value: -1, name: 'letzten' },
                        { value: 1, name: '1.' },
                        { value: 2, name: '2.' },
                        { value: 3, name: '3.' },
                        { value: 4, name: '4.' },
                        { value: 5, name: '5.' },
                        { value: 6, name: '6.' },
                        { value: 7, name: '7.' },
                        { value: 8, name: '8.' },
                        { value: 9, name: '9.' },
                        { value: 10, name: '10.' },
                        { value: 11, name: '11.' },
                        { value: 12, name: '12.' },
                        { value: 13, name: '13.' },
                        { value: 14, name: '14.' },
                        { value: 15, name: '15.' },
                        { value: 16, name: '16.' },
                        { value: 17, name: '17.' },
                        { value: 18, name: '18.' },
                        { value: 19, name: '19.' },
                        { value: 20, name: '20.' },
                        { value: 21, name: '21.' },
                        { value: 22, name: '22.' },
                        { value: 23, name: '23.' },
                        { value: 24, name: '24.' },
                        { value: 25, name: '25.' },
                        { value: 26, name: '26.' },
                        { value: 27, name: '27.' },
                        { value: 28, name: '28.' },
                        { value: 29, name: '29.' },
                        { value: 30, name: '30.' },
                        { value: 31, name: '31.' },
                    ],
                    intervalTypeMonthlyDate: [
                        { value: 0, name: 'ersten' },
                        { value: 1, name: 'zweiten' },
                        { value: 2, name: 'dritten' },
                        { value: 3, name: 'vierten' },
                        { value: -2, name: 'vorletzten' },
                        { value: -1, name: 'letzten' },
                    ],
                    weekdays: [
                        { value: 1, name: 'MO', fullName: 'Montag' },
                        { value: 2, name: 'DI', fullName: 'Dienstag' },
                        { value: 3, name: 'MI', fullName: 'Mittwoch' },
                        { value: 4, name: 'DO', fullName: 'Donnerstag' },
                        { value: 5, name: 'FR', fullName: 'Freitag' },
                        { value: 6, name: 'SA', fullName: 'Samstag' },
                        { value: 7, name: 'SO', fullName: 'Sonntag' },
                    ],
                    intervalEnd: [
                        { value: 10, name: 'Nie' },
                        { value: 11, name: 'Datum' },
                        { value: 12, name: 'Wiederholung' },
                    ],
                    reminder: [
                        { type: '', name: 'Zum Termin' },
                        { type: 'minute', amount: 5, name: '5 Minuten' },
                        { type: 'minute', amount: 15, name: '15 Minuten' },
                        { type: 'hour', amount: 1, name: '1 Stunde' },
                        { type: 'day', amount: 1, name: '1 Tag' },
                        { type: 'week', amount: 1, name: '1 Woche' },
                    ],
                };

                var deferred = $q.defer();
                deferred.resolve(interval);
                return deferred.promise;
            }
        },
    ]);
;
 
angular.module('cfFamilyProfileService', ['mobileAppService']).factory('cfFamilyProfileService', [
    '$q',
    '$http',
    'Upload',
    'mobileAppService',
    function ($q, $http, Upload, mobileAppService) {
        const baseUrl = mobileAppService.staticLoc;

        return {
            getFamily: getFamily,
            createFamilyProfile: createFamilyProfile,
            updateFamilyProfile: updateFamilyProfile,
            deleteFamilyProfile: deleteFamilyProfile,
            updateFamilyUserProfile: updateFamilyUserProfile,
            getColors: getColors,
            getCountryzoneDE: getCountryzoneDE,
            getCountryzoneAUT: getCountryzoneAUT,
            getCountryzoneCH: getCountryzoneCH,
            getRole: getRole,
            getPermission: getPermission,
            getCountry: getCountry,
            CreateInvitationData: CreateInvitationData,
        };

        function getColors() {
            return [
                { value: '0', name: 'Blau' },
                { value: '1', name: 'Grau' },
                { value: '2', name: 'Gelb' },
                { value: '3', name: 'Orange' },
                { value: '4', name: 'Pink' },
                { value: '5', name: 'Violet' },
                { value: '6', name: 'Rot' },
                { value: '7', name: 'Braun' },
                { value: '8', name: 'Ocker' },
                { value: '9', name: 'Dunkelblau' },
                { value: '10', name: 'Hellblau' },
                { value: '11', name: 'Türkis' },
                { value: '12', name: 'Dunkelgrün' },
                { value: '13', name: 'Blassgrün' },
                { value: '14', name: 'Hellgrün' },
            ];
        }

        function getCountryzoneDE() {
            return [
                { value: '-1', name: 'Keine Auswahl' },
                { value: '0', name: 'Baden-Württemberg' },
                { value: '1', name: 'Bayern' },
                { value: '2', name: 'Berlin' },
                { value: '3', name: 'Brandenburg' },
                { value: '4', name: 'Bremen' },
                { value: '5', name: 'Hamburg' },
                { value: '6', name: 'Hessen' },
                { value: '7', name: 'Mecklenburg-Vorpommern' },
                { value: '8', name: 'Niedersachsen' },
                { value: '9', name: 'Nordrhein-Westfalen' },
                { value: '10', name: 'Rheinland-Pfalz' },
                { value: '11', name: 'Saarland' },
                { value: '12', name: 'Sachsen' },
                { value: '13', name: 'Sachsen-Anhalt' },
                { value: '14', name: 'Schleswig-Holstein' },
                { value: '15', name: 'Thüringen' },
            ];
        }

        function getCountryzoneCH() {
            return [
                { value: '-1', name: 'Keine Auswahl' },
                { value: '0', name: 'Zürich' },
                { value: '1', name: 'Bern' },
                { value: '2', name: 'Luzern' },
                { value: '3', name: 'Uri' },
                { value: '4', name: 'Schwyz' },
                { value: '5', name: 'Obwalden' },
                { value: '6', name: 'Nidwalden' },
                { value: '7', name: 'Glarus' },
                { value: '8', name: 'Zug' },
                { value: '9', name: 'Freiburg' },
                { value: '10', name: 'Solothurn' },
                { value: '11', name: 'Basel-Stadt' },
                { value: '12', name: 'Basel-Landschaft' },
                { value: '13', name: 'Schaffhausen' },
                { value: '14', name: 'Appenzell Ausserrhoden' },
                { value: '15', name: 'Appenzell Innerrhoden' },
                { value: '16', name: 'St. Gallen' },
                { value: '17', name: 'Graubünden' },
                { value: '18', name: 'Aargau' },
                { value: '19', name: 'Thurgau' },
                { value: '20', name: 'Tessin' },
                { value: '21', name: 'Waadt' },
                { value: '22', name: 'Wallis' },
                { value: '23', name: 'Neuenburg' },
                { value: '24', name: 'Genf' },
                { value: '25', name: 'Jura' },
            ];
        }

        function getCountryzoneAUT() {
            return [
                { value: '-1', name: 'Keine Auswahl' },
                { value: '0', name: 'Burgenland' },
                { value: '1', name: 'Vorarlberg' },
                { value: '2', name: 'Kärnten' },
                { value: '3', name: 'Nieder­österreich' },
                { value: '4', name: 'Ober­österreich' },
                { value: '5', name: 'Salzburg' },
                { value: '6', name: 'Steiermark' },
                { value: '7', name: 'Tirol' },
                { value: '8', name: 'Wien' },
            ];
        }

        function getCountry() {
            return [
                { value: '-1', name: 'Keine Auswahl' },
                { value: '0', name: 'Deutschland' },
                { value: '1', name: 'Österreich' },
                { value: '2', name: 'Schweiz' },
                { value: '3', name: 'Andere' },
            ];
        }

        function getRole() {
            return [
                { value: '0', name: 'Eltern' },
                { value: '1', name: 'Kinder' },
                { value: '2', name: 'Andere' },
            ];
        }

        function getPermission() {
            return [
                { value: '0', name: 'Nur Leserechte' },
                { value: '1', name: 'Lese- und Schreibrechte' },
            ];
        }

        function getFamily(familyId) {
            return $http({ method: 'GET', url: `${baseUrl}/api/frontend/families/${familyId}` }).then(
                ({ data }) => data
            );
        }

        function createFamilyProfile(processedProfile) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createFamilyProfile',
                data: {
                    processedProfile: processedProfile,
                },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function updateFamilyProfile(family, file) {
            return Upload.upload({
                method: 'PUT',
                url: `${baseUrl}/api/frontend/families/${family._id}`,
                file,
                data: { family },
            }).then(({ data }) => data);
        }

        function updateFamilyUserProfile(processedProfile) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/updateFamilyUserProfile',
                data: { processedProfile: processedProfile },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function deleteFamilyProfile(profileId) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/deleteFamilyProfile',
                data: { profileId: profileId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function CreateInvitationData(familyAddress, invitationForm) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createInvitationData',
                data: { familyAddress: familyAddress, invitationForm: invitationForm },
            }).then(
                function (response) {
                    if (response.data.success) {
                        deferred.resolve();
                    } else {
                        deferred.reject(response.data.message);
                    }
                },
                function () {
                    deferred.reject('invitation_error_server');
                }
            );
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfFamilyService', ['mobileAppService']).factory('cfFamilyService', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getFamilies: getFamilies,
            getFamilyById: getFamilyById,
            createFamily: createFamily,
            updateFamily: updateFamily,
            deleteFamily: deleteFamily,
        };

        function getFamilies() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getFamilies',
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function getFamilyById(familyId) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getFamilyById',
                params: { familyId: familyId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function createFamily(processedFamily) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createFamily',
                data: { processedFamily: processedFamily },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function updateFamily(processedFamily) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/updateFamily',
                data: { processedFamily: processedFamily },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function deleteFamily(familyId) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/deleteFamily',
                data: { familyId: familyId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfFaqService', ['mobileAppService']).factory('cfFaqService', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getFaqKategorie: getFaqKategorie,
        };

        function getFaqKategorie(faq) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getFaqKategorie',
                params: { faq: faq },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        /*
    function createAddressGroup(processedAddressGroup)
    {
        var deferred = $q.defer();
        $http({
            method: 'POST',
            url: '/api/frontend/createAddressGroup',
            data: {
                processedAddressGroup:processedAddressGroup
            }
        }).success(function (_data) {
            deferred.resolve(_data);
        }).error(function (data, status) {
            deferred.reject(status);
        });
        return deferred.promise;
    }
*/
    },
]);
;
 
angular.module('cfHomeService', ['mobileAppService']).factory('cfHomeService', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getListsForPinnwand: getListsForPinnwand,
            getNotesForPinnwand: getNotesForPinnwand,
            getChecklistsForPinnwand: getChecklistsForPinnwand,
        };

        function getListsForPinnwand() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getListsForPinnwand',
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function getNotesForPinnwand() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getNotesForPinnwand',
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function getChecklistsForPinnwand() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getChecklistsForPinnwand',
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfListService', ['mobileAppService']).factory('cfListService', [
    '$q',
    '$http',
    'cfUserService',
    'mobileAppService',
    function ($q, $http, cfUserService, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getLists: getLists,
            cfUpdateList: cfUpdateList,
            cfUpdateTask: cfUpdateTask,
            cfRemoveList: cfRemoveList,
            getListById: getListById,
        };

        /***************************************************************
         * Lade Listen
         ***************************************************************/
        function getLists(listType) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getLists',
                params: { listType: listType, family: cfUserService.getCurrentFamilyId() },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        /***************************************************************
         * Erstelle / Bearbeiten Liste
         ***************************************************************/
        function cfUpdateList(processedList) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/updateList',
                data: { processedList: processedList, family: cfUserService.getCurrentFamilyId() },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        /***************************************************************
         * Erstelle / Bearbeite / Lösche Task in Liste
         ***************************************************************/
        function cfUpdateTask(list) {
            var deferred = $q.defer();

            if (list) {
                if (list.newTaskName && list.newTaskName.length > 0) {
                    var task = {};
                    task.taskStatus = false;
                    task.taskName = list.newTaskName;

                    if (!list.tasks) list.tasks = [];
                    list.tasks.push(task);
                }

                $http({
                    method: 'POST',
                    url: baseUrl + '/api/frontend/updateList',
                    data: { processedList: list, family: cfUserService.getCurrentFamilyId() },
                })
                    .success(function (_data) {
                        deferred.resolve(_data);
                    })
                    .error(function (data, status) {
                        deferred.reject(status + data);
                    });
            } else {
                deferred.reject('Keine Liste übergeben.');
            }
            return deferred.promise;
        }

        /***************************************************************
         * Lösche Liste
         ***************************************************************/
        function cfRemoveList(list) {
            var deferred = $q.defer();

            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/removeList',
                data: { listId: list._id },
            }).then(
                function (response) {
                    if (response.data.success) {
                        deferred.resolve();
                    } else {
                        deferred.reject();
                    }
                },
                function (response) {
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        function getListById(listId) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getListById',
                params: { listId: listId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfNewDistributorService', ['mobileAppService']).factory('cfNewDistributorService', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            createDistributor: createDistributor,
            createDistributorMail: createDistributorMail,
            getDistributorById: getDistributorById,
        };

        function createDistributor(processedDistributor) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createDistributor',
                data: { processedDistributor: processedDistributor },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function createDistributorMail(processedId, processedMail) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createDistributorMail',
                data: {
                    processedId: processedId,
                    processedMail: processedMail,
                },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function getDistributorById(processedId) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getDistributorById',
                param: {
                    processedId: processedId,
                },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfNewWishlistService', ['mobileAppService']).factory('cfNewWishlistService', [
    '$q',
    '$http',
    'Upload',
    'mobileAppService',
    function ($q, $http, Upload, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getNewWishlistById: getNewWishlistById,
            createNewWishlist: createNewWishlist,
            updateNewWishlist: updateNewWishlist,
            deleteNewWishlist: deleteNewWishlist,
            createNewWish: createNewWish,
            getReasons: getReasons,
        };

        function getReasons() {
            return [
                { value: 0, name: 'Geburtstag' },
                { value: 1, name: 'Weihnanchten' },
                { value: 2, name: 'Ostern' },
                { value: 3, name: 'Konfirmation' },
                { value: 4, name: 'Hochzeit' },
                { value: 5, name: 'Namenstag' },
                { value: 6, name: 'Jubiläum' },
            ];
        }

        function getNewWishlistById(wishlistId) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getWishlistById',
                params: { wishlistId: wishlistId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function createNewWishlist(processedWishlist, wishlistImage) {
            var deferred = $q.defer();
            Upload.upload({
                method: 'POST',
                url: baseUrl + '/api/frontend/createNewWishlist',
                file: wishlistImage,
                data: { processedWishlist: processedWishlist },
            })
                .progress(function (evt) {})
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function updateNewWishlist(processedWishlist) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/updateNewWishlist',
                data: { processedWishlist: processedWishlist },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function deleteNewWishlist(wishlistId) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/deleteNewWishlist',
                data: { wishlistId: wishlistId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function createNewWish(wishlistId, wishName) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createNewWish',
                data: { wishlistId: wishlistId, wishName: wishName },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
angular.module('cfNoteService', ['mobileAppService']).factory('cfNoteService', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getNotes: getNotes,
            getNoteById: getNoteById,
            createNote: createNote,
            updateNote: updateNote,
            deleteNote: deleteNote,
        };

        function getNotes(familyId) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getNotes',
                params: { familyId: familyId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function getNoteById(noteId) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getNoteById',
                params: { noteId: noteId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function createNote(processedNote) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createNote',
                data: { processedNote: processedNote },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function updateNote(processedNote) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/updateNote',
                data: { processedNote: processedNote },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function deleteNote(noteId) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/deleteNote',
                data: { noteId: noteId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
// NotificationService.js
angular.module('NotificationService', []).factory('NotificationService', [
    'Notification',
    'errorReportingService',
    function (Notification, errorReportingService) {
        var messageTranslationTabel = {
            //generally

            //invitation
            invitation_success:
                'Das neue Familienmitglied wurde erfolgreich eingeladen und erhält jetzt eine E-Mail mit Bestätigungslink.',
            invitation_success_no_mail:
                'Deine Einladung war erfolgreich. Über das Familienprofil kannst du nun die Einstellungen für das neue Familienmitglied vornehmen.',
            invitation_error_duplicate_mail:
                'Diese E-Mailadresse ist bereits vorhanden und kann nicht noch einmal eingeladen werden.',
            invitation_error_server:
                'Die Einladung konnte aufgrund eines Serverfehlers nicht erstellt werden. Bitte probiere es noch einmal oder wende dich an unseren Support.',
            //event
            event_success_update: 'Der Termin wurde gespeichert.',
            event_error_update_server:
                'Der Termin konnte aufgrund eines Serverfehlers nicht gespeichert werden. Bitte probiere es noch einmal oder wende dich an unseren Support.',
            event_success_delete: 'Der Termin wurde gelöscht.',
            event_error_delete_server:
                'Der Termin konnte aufgrund eines Serverfehlers nicht gelöscht werden. Bitte probiere es noch einmal oder wende dich an unseren Support.',
            events_notify: 'Der Termin wurde versandt.',
            //registration
            registration_success:
                'Deine Registrierung war erfolgreich. Du erhältst in Kürze eine E-Mail mit einem Bestätigungslink, um die Registrierung abzuschließen. ',
            registration_error_duplicate_mail:
                'Diese E-Mailadresse ist bereits vorhanden und kann nicht noch einmal zur Registrierung verwendet werden.',
            registration_error_user: 'Deine Registrierung konnte nicht abgeschlossen werden.',
            registration_error_family: 'Deine Registrierung konnte nicht abgeschlossen werden.',
            registration_error_emails_not_equal: 'Deine E-Mail-Adressen stimmen nicht überein.',
            registration_error_passwords_not_equal: 'Deine Passwörter stimmen nicht überein.',
            registration_error_mail:
                'Deine Registrierung wurde nicht abgeschlossen, da der Bestätigungslink nicht per E-Mail verschickt werden konnte.',
            registration_error_server:
                'Deine Registrierung konnte aufgrund eines Serverfehlers nicht abgeschlossen werden. Bitte probiere es noch einmal oder wende dich an den Support.',
            //passwort
            password_success:
                'Es wurde eine E-Mail an dich verschickt. Damit kannst du dir ein neues Passwort erstellen.',
            password_error: 'Das Passwort konnte aufgrund eines unerwarteten Fehlers nicht zurückgesetzt werden.',
            password_error_no_mail: 'Bitte überprüfe deine E-Mailadresse.',
            //passwort
            addressgroup_error_server:
                'Die Adress Gruppe konnte aufgrund eines unerwarteten Fehlers nicht gespeichert werden.',
            addressgroup_error_no_data:
                'Die Adress Gruppe konnte aufgrund eines unerwarteten Fehlers nicht gespeichert werden.',
            //profil
            profile_delete_success: 'Das Profil und alle dazugehörigen Daten wurden vollständig gelöscht.',
            profile_delete_error_server: 'Das Profil konnte aufgrund eines unerwarteten Fehlers nicht gelöscht werden.',
            double_exception: 'Ausfalltermin bereits angelegt.',
            profile_save_success: 'Die Änderungen wurden erfolgreich gespeichert.',
            profile_password_false: 'Bitte überprüfe die Eingabe deines Passworts.',
            change_email_warning: 'Zum Ändern oder Hinzufügen einer E-Mailadresse wende dich bitte an den Support.',
            profile_mail_change:
                'Du erhältst eine E-Mail mit einem Bestätigungslink. Bitte bestätige diesen innerhalb von 7 Tagen, damit die Änderung aktiv wird.',
            //address
            address_delete_error_server: 'Der Kontakt konnte nicht aus deinem Adressbuch gelöscht werden.',
            invalid_mail: 'Die gewählte E-Mailadresse ist nicht korrekt. Bitte überprüfe die Schreibweise.',
            existing_mail: 'Die gewählte E-Mailadresse ist bereits vorhanden. Bitte wähle eine andere E-Mailadresse.',

            //calendar
            calendar_import: 'Der Kalender wurde erfolgreich importiert.',
            calendar_update: 'Der Kalender wurde erfolgreich bearbeitet.',
            calendar_delete: 'Der Kalender wurde erfolgreich gelöscht.',

            'calendar_import-err':
                'Beim Kalenderimport gab es einen Fehler. Bitte versuche es nochmal oder wende dich an den Support.',
            'calendar_update-err':
                'Beim aktualisieren des Kalenders gab es einen Fehler. Bitte versuche es nochmal oder ',
            'calendar_delete-err':
                'Beim Löschen des Kalenders ist ein Fehler aufgetreten. Bitte versuche es nochmal oder wende dich an den Support',
        };

        return {
            displayNotification: displayNotification,
        };

        function displayNotification(type, key, message, delay) {
            var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            var rand = Math.max(width - 1190, 0);
            var _message = '';
            var _delay = delay || 5000;
            if (key) _message = convertMessage(key);
            if (message) _message = messageTranslationTabel[key];

            if (_message && _message.length > 0) {
                if (type == 'success') {
                    Notification.success({ message: convertMessage(key), startRight: rand / 2, delay: _delay });
                } else if (type == 'error') {
                    const errorMessage = convertMessage(key);
                    Notification.error({ message: errorMessage, startRight: rand / 2, delay: _delay });
                    errorReportingService.report(`Error notification: ${errorMessage}`);
                } else if (type == 'warning') {
                    Notification.warning({ message: convertMessage(key), startRight: rand / 2, delay: _delay });
                } else if (type == 'info') {
                    Notification.info({ message: convertMessage(key), startRight: rand / 2, delay: _delay });
                } else if (type == 'push') {
                    Notification({ title: key, message: message, startRight: rand / 2, delay: 10000 });
                } else {
                    Notification({ message: convertMessage(key), startRight: rand / 2, delay: _delay });
                }
            }
        }

        function convertMessage(key) {
            if (messageTranslationTabel[key]) return messageTranslationTabel[key];
            else return key;
        }
    },
]);
;
 
angular
    .module('cfNursingDiaryDocumentationService', ['mobileAppService'])
    .factory('cfNursingDiaryDocumentationService', [
        '$http',
        'mobileAppService',
        function ($http, mobileAppService) {
            const baseUrl = mobileAppService.staticLoc;

            function getNursingDiaryDocumentationEntries(params) {
                return $http({
                    method: 'GET',
                    url: `${baseUrl}/api/frontend/nursing/entries/documentation`,
                    params: params,
                }).then(({ data }) => data);
            }

            function createDocumentationShare(documentationShareObject) {
                return $http({
                    method: 'POST',
                    url: `${baseUrl}/api/frontend/nursing/entries/documentation/share`,
                    data: documentationShareObject,
                }).then(({ data }) => data);
            }

            return {
                getNursingDiaryDocumentationEntries: getNursingDiaryDocumentationEntries,
                createDocumentationShare: createDocumentationShare,
            };
        },
    ]);
;
 
angular.module('cfNursingDiaryEditorService', ['mobileAppService']).factory('cfNursingDiaryEditorService', [
    '$http',
    'mobileAppService',
    function ($http, mobileAppService) {
        const baseUrl = mobileAppService.staticLoc;

        function getNursingDiary(nursingDiaryId) {
            return $http({
                method: 'GET',
                url: `${baseUrl}/api/frontend/nursing/getNursingDiary/${nursingDiaryId}`,
            }).then(({ data }) => data);
        }

        function createNursingDiary(nursingEditorFormData) {
            return $http({
                method: 'POST',
                url: `${baseUrl}/api/frontend/nursing/createNursingDiary`,
                data: nursingEditorFormData,
            }).then(({ data }) => data);
        }

        function updateNursingDiary(nursingEditorFormData) {
            return $http({
                method: 'PUT',
                url: `${baseUrl}/api/frontend/nursing/updateNursingDiary/${nursingEditorFormData._id}`,
                data: nursingEditorFormData,
            }).then(({ data }) => data);
        }

        function removeNursingDiary(nursingDiaryId) {
            return $http({ method: 'DELETE', url: `${baseUrl}/api/frontend/nursing/${nursingDiaryId}` }).then(
                ({ data }) => data
            );
        }

        return {
            getNursingDiary: getNursingDiary,
            createNursingDiary: createNursingDiary,
            updateNursingDiary: updateNursingDiary,
            removeNursingDiary: removeNursingDiary,
        };
    },
]);
;
 
angular.module('cfNursingDiaryEntryService', ['mobileAppService']).factory('cfNursingDiaryEntryService', [
    '$http',
    'Upload',
    'mobileAppService',
    function ($http, Upload, mobileAppService) {
        const baseUrl = mobileAppService.staticLoc;
        function getNursingDiary(nursingDiaryId) {
            return $http({
                method: 'GET',
                url: `${baseUrl}/api/frontend/nursing/getNursingDiary/${nursingDiaryId}`,
            }).then(({ data }) => data);
        }
        function createDiaryEntry(diaryEntrySubmitData, file) {
            return Upload.upload({
                method: 'POST',
                url: `${baseUrl}/api/frontend/nursing/createDiaryEntry`,
                data: diaryEntrySubmitData,
                file,
            });
        }

        function updateDiaryEntry(diaryEntrySubmitData, file) {
            return Upload.upload({
                method: 'PUT',
                url: `${baseUrl}/api/frontend/nursing/updateDiaryEntry/${diaryEntrySubmitData._id}`,
                data: diaryEntrySubmitData,
                file,
            });
        }

        function getDiaryEntryByDate(date, userId) {
            return $http({
                method: 'GET',
                url: `${baseUrl}/api/frontend/nursing/getDiaryEntryByDate`,
                params: { date, userId },
            }).then(({ data }) => data);
        }

        return {
            getNursingDiary: getNursingDiary,
            createDiaryEntry: createDiaryEntry,
            getDiaryEntryByDate: getDiaryEntryByDate,
            updateDiaryEntry: updateDiaryEntry,
        };
    },
]);
;
 
angular.module('cfNursingDiaryService', ['mobileAppService']).factory('cfNursingDiaryService', [
    '$http',
    'mobileAppService',
    function ($http, mobileAppService) {
        const baseUrl = mobileAppService.staticLoc;

        function getNursingDiaries() {
            return $http({
                method: 'GET',
                url: `${baseUrl}/api/frontend/nursing/getNursingDiaries`,
            }).then(({ data }) => data);
        }
        return {
            getNursingDiaries: getNursingDiaries,
        };
    },
]);
;
 
angular.module('cfPasswordService', ['mobileAppService']).factory('cfPasswordService', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        const baseUrl = mobileAppService.staticLoc;

        return {
            initPasswordReset: initPasswordReset,
            validateResetPasswordHash: validatePasswordResetHash,
            completePasswordReset: completePasswordReset,
        };

        function initPasswordReset(email) {
            const deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/cfInitPasswordReset',
                data: { email: email },
            }).then(
                response => {
                    if (response && response.data && response.data.success) deferred.resolve();
                    else deferred.reject(response.data.message);
                },
                response => {
                    deferred.reject(response.data.statusText);
                }
            );
            return deferred.promise;
        }

        function validatePasswordResetHash(passwordResetHash) {
            return $http({
                method: 'POST',
                url: `${baseUrl}/api/frontend/validatePasswordResetHash`,
                data: { passwordResetHash },
            });
        }
        function completePasswordReset({ password, passwordResetHash }) {
            return $http({
                method: 'POST',
                url: `${baseUrl}/api/frontend/completePasswordReset`,
                data: { password, passwordResetHash },
            }).then(({ data }) => data);
        }
    },
]);
;
 
angular.module('cfProfileService', ['mobileAppService']).factory('cfProfileService', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getProfile: getProfile,
            createProfile: createProfile,
            updateProfile: updateProfile,
            cfDeleteProfile: cfDeleteProfile,
            getIcons: getIcons,
            getColors: getColors,
            getCountryzone: getCountryzone,
            cfChangePassword: cfChangePassword,
            getStates: getStates,
        };

        function getIcons() {
            return [
                { value: 0, name: 'Weiblich groß' },
                { value: 1, name: 'Männlich groß' },
                { value: 2, name: 'Weiblich klein' },
                { value: 3, name: 'Männlich klein' },
            ];
        }

        function getColors() {
            return [
                { value: 0, name: 'Blau' },
                { value: 1, name: 'Grau' },
                { value: 2, name: 'Gelb' },
                { value: 3, name: 'Orange' },
                { value: 4, name: 'Pink' },
                { value: 5, name: 'Violet' },
                { value: 6, name: 'Rot' },
                { value: 7, name: 'Braun' },
                { value: 8, name: 'Ocker' },
                { value: 9, name: 'Dunkelblau' },
                { value: 10, name: 'Hellblau' },
                { value: 11, name: 'Türkis' },
                { value: 12, name: 'Dunkelgrün' },
                { value: 13, name: 'Blassgrün' },
                { value: 14, name: 'Hellgrün' },
            ];
        }

        function getCountryzone() {
            return [
                { value: '-1', name: 'Keine Auswahl' },
                { value: 0, name: 'Baden-Württemberg' },
                { value: 1, name: 'Bayern' },
                { value: 2, name: 'Berlin' },
                { value: 3, name: 'Brandenburg' },
                { value: 4, name: 'Bremen' },
                { value: 5, name: 'Hamburg' },
                { value: 6, name: 'Hessen' },
                { value: 7, name: 'Mecklenburg-Vorpommern' },
                { value: 8, name: 'Niedersachsen' },
                { value: 9, name: 'Nordrhein-Westfalen' },
                { value: 10, name: 'Rheinland-Pfalz' },
                { value: 11, name: 'Saarland' },
                { value: 12, name: 'Sachsen' },
                { value: 13, name: 'Sachsen-Anhalt' },
                { value: 14, name: 'Schleswig-Holstein' },
                { value: 15, name: 'Thüringen' },
            ];
        }

        function getProfile(profileId) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getProfile',
                params: {
                    profile: profileId,
                },
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (response) {
                    deferred.reject({
                        message: response.data?.message ?? 'Es ist ein unbekannter Fehler aufgetreten.',
                        status: response.status,
                    });
                }
            );

            return deferred.promise;
        }

        function createProfile(processedProfile) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createProfile',
                data: {
                    processedProfile: processedProfile,
                },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function updateProfile(processedProfile) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/updateProfile',
                data: { processedProfile: processedProfile },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function cfDeleteProfile(profileId) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/deleteProfile',
                data: { profileId: profileId },
            }).then(
                function (response) {
                    if (response.data.success) {
                        deferred.resolve(response.data.message);
                    } else {
                        deferred.reject(response.data.message);
                    }
                },
                function (response) {
                    deferred.reject(response.statusText);
                }
            );
            return deferred.promise;
        }

        function cfChangePassword(changePasswordData) {
            return $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/cfChangePassword',
                data: changePasswordData,
            }).then(({ data }) => data);
        }

        function getStates() {
            return $http({ method: 'GET', url: `${baseUrl}/api/frontend/states` }).then(({ data }) => data);
        }
    },
]);
;
 
angular.module('cfRegistrationService', ['mobileAppService']).factory('cfRegistrationService', [
    '$q',
    '$http',
    'Upload',
    'mobileAppService',
    function ($q, $http, Upload, mobileAppService) {
        const baseUrl = mobileAppService.staticLoc;

        return {
            submitRegistrationFormData: submitRegistrationFormData,
            completeInvitation: completeInvitation,
            validateInvitationHash: validateInvitationHash,
        };

        function submitRegistrationFormData(registerFormData) {
            return $http({
                method: 'POST',
                url: `${baseUrl}/api/frontend/createRegistrationData`,
                data: { registerFormData },
            }).then(({ data }) => data);
        }

        function completeInvitation({ password, invitationHash }) {
            return $http({
                method: 'POST',
                url: `${baseUrl}/api/frontend/completeInvitation`,
                data: { password, invitationHash },
            }).then(({ data }) => data);
        }
        function validateInvitationHash(invitationHash) {
            return $http({
                method: 'POST',
                url: `${baseUrl}/api/frontend/validateInvitationHash`,
                data: { invitationHash },
            });
        }
    },
]);
;
 
angular.module('cfSelectedTimeService', ['DateConstructor']).factory('cfSelectedTimeService', [
    'DateConstructor',
    function (DateConstructor) {
        var selectedDate = new DateConstructor();

        return {
            getSelectedDate: getSelectedDate,
            selectedDate: selectedDate,
        };

        function getSelectedDate() {
            return selectedDate;
        }
    },
]);
;
 
angular.module('cfShareService', ['mobileAppService']).factory('cfShareService', [
    '$http',
    'mobileAppService',
    function ($http, mobileAppService) {
        const baseUrl = mobileAppService.staticLoc;

        function getShare(shareId) {
            return $http({
                method: 'GET',
                url: `${baseUrl}/api/frontend/nursing/shares/${shareId}`,
            }).then(({ data }) => data);
        }

        return {
            getShare: getShare,
        };
    },
]);
;
 
angular.module('cfUserService', ['mobileAppService']).factory('cfUserService', [
    '$q',
    '$http',
    '$location',
    'mobileAppService',
    function ($q, $http, $location, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;
        var user = {};
        var pending = false;

        return {
            confirmLastSeenChangelogs: confirmLastSeenChangelogs,
            authorizeMobileUser: authorizeMobileUser,
            logoutUser: logoutUser,
            loadCurrentUser: loadCurrentUser,
            skipLogin: skipLogin,
            protect: protect,
            getCurrentUser: getCurrentUser,
            getCurrentFamily: getCurrentFamily,
            getFamilyMembers: getFamilyMembers,
            getCurrentFamilyId: getCurrentFamilyId,
            reloadUser: reloadUser,
            getCurrentUserFromDB: getCurrentUserFromDB,
        };

        function confirmLastSeenChangelogs() {
            return $http({
                method: 'PATCH',
                url: `${baseUrl}/api/frontend/confirmLastSeenChangelogs`,
                data: {},
            });
        }

        /***************************************************************
         * Login
         ***************************************************************/
        function authorizeMobileUser(credentials) {
            var deferred = $q.defer();

            if (credentials && credentials.username && credentials.password) {
                mobileAppService
                    .getPushToken()
                    .then(function (token) {
                        $http({
                            method: 'POST',
                            url: baseUrl + '/api/signin',
                            data: {
                                ...credentials,
                                token: token,
                            },
                        }).then(
                            function (response) {
                                if (response.data.success) {
                                    persistLogin({ jwtToken: response.data.jwtToken, credentials });
                                    deferred.resolve();
                                } else {
                                    deferred.reject(response.data.errorMessage ?? response);
                                }
                            },
                            function (response) {
                                if (response?.isOfflineError) {
                                    // TODO: message?
                                    deferred.reject('Du bist offline.');
                                } else {
                                    deferred.reject(response?.data?.message ?? response);
                                }
                            }
                        );
                    })
                    .catch(error => {
                        deferred.reject(error);
                    });
            } else {
                deferred.reject('E-Mail oder Passwort nicht korrekt. Bitte probiere es noch einmal.'); //;scope.familyMembers
            }
            return deferred.promise;
        }

        function persistLogin({ jwtToken, credentials }) {
            localStorage.setItem('fc-jwt-token', jwtToken);
            if (credentials.remember) {
                localStorage.setItem('fc-password', credentials.password);
                localStorage.setItem('fc-username', credentials.username);
                localStorage.setItem('fc-remember', credentials.remember);
            } else {
                localStorage.setItem('fc-password', '');
                localStorage.setItem('fc-username', '');
                localStorage.setItem('fc-remember', 'false');
            }
        }
        /***************************************************************
         * Besteht bereits eine Session, wird die LoginRoute übersprungen
         ***************************************************************/
        function skipLogin() {
            var deferred = $q.defer();

            loadCurrentUser().then(
                function (loadedUser) {
                    if (loadedUser && loadedUser._id) $location.path('/home');
                    deferred.resolve();
                },
                function () {
                    deferred.resolve();
                }
            );
            return deferred.promise;
        }

        /***************************************************************
         * Routen können durch "protect" resolve geschützt werden.
         * Besteht keine Session, wird zurück auf Login "/" geroutet.
         ***************************************************************/
        function protect() {
            var deferred = $q.defer();

            loadCurrentUser().then(function (loadedUser) {
                if (!loadedUser || !loadedUser._id) {
                    $location.path('/');
                    deferred.resolve(loadedUser);
                } else deferred.resolve(loadedUser);
            });

            return deferred.promise;
        }

        /***************************************************************
         * Logout
         ***************************************************************/
        function logoutUser() {
            var deferred = $q.defer();

            mobileAppService.getPushToken().then(function (token) {
                $http({
                    method: 'POST',
                    url: baseUrl + '/api/signout',
                    data: { token: token },
                }).then(
                    function (response) {
                        if (response && response.data && response.data.success) {
                            Object.keys(user).forEach(function (key) {
                                delete user[key];
                            });
                            localStorage.removeItem('fc-jwt-token');
                            deferred.resolve();
                        } else {
                            deferred.reject();
                        }
                    },
                    function () {
                        deferred.reject();
                    }
                );
            });
            return deferred.promise;
        }

        /***************************************************************
         * Prüft, ob ein User in der Session vorhanden ist.
         * Wurde der User bereits geladen, wird die lokale Kopie verwendet.
         * Wird der User bereits geladen ("pending") wird gewartet, bis ein Ergebniss zurück kommt
         ***************************************************************/
        function loadCurrentUser() {
            var deferred = $q.defer();
            /** User schon da **/
            if (user && Object.keys(user).length > 0) {
                deferred.resolve(user);
                /** User wird gerade geladen**/
            } else if (pending) {
                wait();

                function wait() {
                    setTimeout(function () {
                        if (pending) wait();
                        else {
                            deferred.resolve(user);
                        }
                    }, 500);
                }

                /** Lade User von DB**/
            } else {
                pending = true;
                $http({
                    method: 'GET',
                    url: baseUrl + '/api/frontend/getCurrentUser',
                }).then(
                    function (response) {
                        if (response && response.data && response.data.success) {
                            var responseUser = response.data.user;
                            /*************************
                             * Jedes Element des übergebenen Obj. wird in User kopiert, damit
                             * referenzierungen auf den "user" nicht verloren gehen.
                             */
                            Object.keys(user).forEach(function (key) {
                                delete user[key];
                            });
                            Object.keys(responseUser).forEach(function (key) {
                                user[key] = responseUser[key];
                            });
                            deferred.resolve(responseUser);
                        } else {
                            deferred.reject();
                        }
                        pending = false;
                    },
                    function (err) {
                        deferred.reject(err);
                        pending = false;
                    }
                );
            }
            return deferred.promise;
        }

        function getCurrentUserFromDB() {
            return $http({ method: 'GET', url: `${baseUrl}/api/frontend/getCurrentUser` }).then(({ data }) => {
                if (data.success && data.user) {
                    // TODO: fix user variable references logic
                    Object.keys(user).forEach(key => delete user[key]);
                    Object.keys(data.user).forEach(key => (user[key] = data.user[key]));
                    return data.user;
                }

                throw new Error('Unexpected Error in cfUserService.reloadUser().');
            });
        }

        function reloadUser() {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getCurrentUser',
            }).then(
                function (response) {
                    if (response && response.data && response.data.success) {
                        var responseUser = response.data.user;
                        /*************************
                         * Jedes Element des übergebenen Obj. wird in User kopiert, damit
                         * referenzierungen auf den "user" nicht verloren gehen.
                         */
                        Object.keys(user).forEach(function (key) {
                            delete user[key];
                        });
                        Object.keys(responseUser).forEach(function (key) {
                            user[key] = responseUser[key];
                        });
                        deferred.resolve(responseUser);
                    } else {
                        deferred.reject();
                    }
                },
                function (err) {
                    deferred.reject(err);
                }
            );
            return deferred.promise;
        }

        function getCurrentUser() {
            if (user) {
                return user;
            } else {
                return 'undefined';
            }
        }

        function getCurrentFamily() {
            if (user && user.family) {
                return user.family;
            } else {
                return 'undefined';
            }
        }

        function getCurrentFamilyId() {
            if (user && user.family && user.family._id) {
                return user.family._id;
            } else {
                return 'undefined';
            }
        }

        function getFamilyMembers() {
            if (user && user.family && user.family.familyMembers) {
                return user.family.familyMembers;
            } else {
                return [];
            }
        }
    },
]);
;
 
angular.module('cfWishlistService', ['mobileAppService']).factory('cfWishlistService', [
    '$q',
    '$http',
    'mobileAppService',
    function ($q, $http, mobileAppService) {
        var baseUrl = mobileAppService.staticLoc;

        return {
            getWishlists: getWishlists,
            getWishlistById: getWishlistById,
            createWishlist: createWishlist,
            updateWishlist: updateWishlist,
            deleteWishlist: deleteWishlist,
            createWish: createWish,
            getDistributors: getDistributors,
            getEmailList: getEmailList,
        };

        function getWishlists() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getWishlists',
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function getDistributors() {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getDistributors',
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function getEmailList(distributorIds) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getEmailList',
                params: { distributorIds: distributorIds },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function getWishlistById(wishlistId) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: baseUrl + '/api/frontend/getWishlistById',
                params: { wishlistId: wishlistId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function createWishlist(processedWishlist) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createWishlist',
                data: { processedWishlist: processedWishlist },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function updateWishlist(processedWishlist) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/updateWishlist',
                data: { processedWishlist: processedWishlist },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function deleteWishlist(wishlistId) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/deleteWishlist',
                data: { wishlistId: wishlistId },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }

        function createWish(wishlistId, wishName) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: baseUrl + '/api/frontend/createWish',
                data: { wishlistId: wishlistId, wishName: wishName },
            })
                .success(function (_data) {
                    deferred.resolve(_data);
                })
                .error(function (data, status) {
                    deferred.reject(status);
                });
            return deferred.promise;
        }
    },
]);
;
 
angular.module('family_cockpit').factory('errorReportingService', [
    function () {
        function report(error) {
            if (window._sendErrorToFirebase) {
                window._sendErrorToFirebase(error);
            } else {
                console.error('window._sendErrorToFirebase not defined');
            }
        }

        return {
            report: report,
        };
    },
]);
;
 
//mobileAppService.js
angular.module('mobileAppService', []).factory('mobileAppService', [
    '$q',
    '$location',
    function ($q, $location) {
        var hashLoc = '#/';
        var htmlLoc = '#/';
        var pushToken;

        var staticLoc = '';
        if (window.cordova) staticLoc = 'https://510.famco-prod.konzepthaus-ws.de';
        // Reference: https://developer.android.com/studio/run/emulator-networking#networkaddresses
        // if (window.cordova) staticLoc = "https://10.0.2.2:3443";

        var dynamicLoc = staticLoc;
        if (!window.cordova) dynamicLoc = $location.protocol() + '://' + $location.host() + ':' + $location.port();

        function getPushToken() {
            var deferred = $q.defer();

            if (!pushToken && window.cordova) {
                window.FirebasePlugin.getToken(
                    function (token) {
                        pushToken = token;
                        deferred.resolve(pushToken);
                    },
                    function (error) {
                        console.error('getToken error', error);
                        deferred.reject(error);
                    }
                );
            } else deferred.resolve(pushToken);
            return deferred.promise;
        }

        return {
            staticLoc: staticLoc,
            dynamicLoc: dynamicLoc,
            hashLoc: hashLoc,
            htmlLoc: htmlLoc,
            getPushToken: getPushToken,
        };
    },
]);
